<template>
    <div  class="w-full h-auto mb-5 bg-red-5 "  >
        <div class="w-full h-5 bg-red-20 text-sm mt-1" >
            <div v-if="currentStatus === 0"> 분양 새 글</div>
            <div v-else-if="currentStatus === 3"  @click="goToReptile"> {{ `종 > ${selectedSpecies} > 전체보기`  }} </div>
            <div v-else-if="selectedKeyword !== null"> 검색결과</div>
            <div v-else-if="selectedSpecies !== null"> {{ `종 > ${findCategory(selectedSpecies)} > ${selectedSpecies}` }}</div>
            <div v-else>분양 새 글</div>
        </div>
        

        <div class="my-4 font-bold text-lg justify-between flex bg-yellow-0 ">
            <div v-if="currentStatus === 0"> 새로 올라온 친구들</div>
            <div v-else-if="currentStatus === 3"> {{ selectedSpecies }} </div>
            <div v-else-if="selectedKeyword !== null"> {{ `'${selectedKeyword}'(으)로 검색한 결과` }}</div>
            <div v-else-if="selectedSpecies !== null"> 모프</div>
            <div v-else>새로 올라온 친구들</div>

            <!-- <div v-if="selectedKeyword !== null"> {{ `'${selectedKeyword}'(으)로 검색한 결과` }}</div>
            <div v-else >{{ !selectedSpecies ? `새로 올라온 친구들` : `모프` }}</div>     -->
        </div>
        <div v-if="selectedSpecies && morphList.length !== 0">
            <div  class=" w-full h-auto flex flex-wrap ">
                <div v-for="(item, index) in slicingMorphList" :key="index"  >
                    <button class="py-1 px-2 rounded-full bg-[#f2f4f5] mr-3 mb-3 text-sm text-def" @click="handleToggle(index)"  
                    :class="morphToggle[index] ? 'isActive' : null"
                    >{{ item }}</button> 
                </div>
            </div>
            <div v-if="slicingMorphList.length !== morphList.length" class="w-full flex justify-center mt-2"> 
                <button class="px-4 py-[2px] bg-white text-[13px] font-medium rounded-full ring-1 ring-zinc-200" @click="handleMorphList">전체보기</button>
            </div>

        </div>
        
        <div v-if="selectedKeyword !== null || currentStatus === 3" class="hidden">
            <!-- 필터 숨김 -->
        </div>
        <div v-else class="overflow-hidden ">
            <div class="my-4 font-bold text-lg flex text-def"> 필터 </div>
            <AttributeFilter />
        </div>

    </div>

</template>

<script lang="ts">
import { defineComponent, ref,  computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import data from '@/assets/data/category.json';
import AttributeFilter from './AttributeFilter.vue';
import { initFilter } from '@/utils/findCategory';
const store = useStore();
const router = useRouter();

export default defineComponent({
    name: 'ShowMorph',
    setup() {
        const store = useStore();
        const router = useRouter();
        const morphToggle = ref([]);
        const morphList = ref([]);

        const tempFilter = computed(()=> store.state.temporaryFilter);
        const currentStatus = computed(()=> store.state.currentStatus);

        const isLoading = computed(() => store.state.isLoading);
        const selectedSpecies = computed(() => store.state.selectedSpecies);
        const selectedKeyword = computed(() => store.state.selectedKeyword)

        watch(selectedSpecies, (newVal) => {
            findMorph(newVal);
        });
        const findCategory = (selectedSpecies) => {
            for (let category of data) {
                if (category.species.includes(selectedSpecies)) {
                    return category.category;
                }
            }
            return null;
        };
        const findMorph = (selectedSpecies) => {
            const speciesData = data.find(item => item.morph && item.morph[selectedSpecies]);
            if (speciesData && Array.isArray(speciesData.morph[selectedSpecies])) {
                morphList.value = speciesData.morph[selectedSpecies];
                morphToggle.value = new Array(speciesData.morph[selectedSpecies].length).fill(false);
                // console.log(morphList.value)
                if(morphList.value.length > 10 ){
                    slicingMorphList.value = morphList.value.slice(0, 10);
                    // console.log(morphList.value)
                }else {
                    slicingMorphList.value = morphList.value
                }
            }
            else {
                morphList.value = [];
                morphToggle.value = [];
            }
        };
        const slicingMorphList = ref([]);
        const handleMorphList = () => {
            slicingMorphList.value = morphList.value; 
        };
        const goToReptile = () =>{
            store.commit('SET_CURRENT_STATUS', 0)
            store.commit('SET_CURRENT_LIST', store.state.reptileList)
            initFilter(store);
            router.push('/reptile');
        }
        onMounted(() => {
            // console.log('onMounted', selectedSpecies.value)
            findMorph(selectedSpecies.value);
        });
        const callApiWithSelectedMorphs = () => {
            const selectedMorphs = morphList.value.filter((_, index) => morphToggle.value[index]);
            store.commit('SET_MORPH_STATUS', selectedMorphs);
        };
        const handleToggle = (index) => {
            morphToggle.value[index] = !morphToggle.value[index];
            callApiWithSelectedMorphs();
        };
        const handleApply = async () => {
            if (isLoading.value)
                return;
            store.commit('SET_STATUS_FILTER', tempFilter.value);
            store.commit('SET_NO_RESULT', false)
            await store.dispatch('fetchFilterData');
        };
        const resetMorph = () => {
            morphToggle.value = [];
        };

        return {
            morphToggle,
            morphList,
            selectedSpecies,
            selectedKeyword,
            currentStatus,
            handleApply,
            findCategory,
            handleToggle,
            slicingMorphList,
            handleMorphList,
            goToReptile,
        };
    },
    components: { AttributeFilter  }
});

</script>


<style scoped>
.isActive{
    background-color: #E0F9F1;
    color: #00D191;
}


</style>

<!-- <template>
    <div v-if="currentStatus !== null" class="w-full h-auto mb-5 bg-red-5 "  >
        <div class="w-full h-5 bg-red-20 text-sm" >
            <div v-if="selectedKeyword !== null"> 검색결과</div>
            <div v-else-if="currentStatus === 3"> {{ selectedSpecies }} 전체보기</div>
            <div v-else >
                {{ selectedSpecies ? `종 > ${findCategory(selectedSpecies)} > ${selectedSpecies}` : '분양 새 글' }}
            </div>
        </div>
        

        <div class="my-4 font-bold text-lg justify-between flex bg-yellow-0 ">
            <div v-if="selectedKeyword !== null"> {{ `'${selectedKeyword}'(으)로 검색한 결과` }}</div>
            <div v-else >{{ !selectedSpecies ? `새로 올라온 친구들` : `모프` }}</div>    
        </div>

        <div v-if="selectedSpecies">
            <div  class=" w-full h-auto flex flex-wrap ">
                <div v-for="(item, index) in morphList" :key="index"  >
                    <button class="py-1 px-2 rounded-full bg-[#f2f4f5] mr-3 mb-3 text-sm text-def" @click="handleToggle(index)"  
                    :class="morphToggle[index] ? 'isActive' : null"
                    >{{ item }}</button> 
                </div>
            </div>

        </div>
        <div v-if="selectedKeyword === null " class="overflow-hidden ">
            <div class="my-4 font-bold text-lg flex text-def">
            필터 </div>
            <AttributeFilter  />
        </div>
    </div>
    <div v-else class="overflow-hidden bg-green-00 mb-2 "  >
        <div class="mb-6 font-bold text-lg flex text-def">
            새로운 친구를 만나보세요!
        </div>
        <AttributeFilter  />
    </div>
</template> -->
<template>
    <div class="flex flex-col w-full px-4 sm:px-0 ">
        <div v-if="!isFlutter()" class="mb-4 font-bold text-lg justify-between sm:mt-0 mt-4">
            <h1 class="pl-2">칼럼/뉴스</h1>    
        </div>
        <div v-else class="sm:mt-0 mt-4"></div>
        <div class="grid grid-cols-2 sm:gap-10 sm:w-[768px] w-full gap-2 gap-y-6 sm:gap-y-2">
            <ul v-for="(post) in column" :key="post.id" class="">
                <li class="cursor-pointer" @click="handlePost(post)">
                    <div class="sm:h-48 h-32">
                        <img :src="post.imgURL1" alt="" class="w-full h-full object-cover rounded-lg">
                    </div>
                    <div class=" px-1 sm:py-4 py-2 space-y-0 sm:space-y-2 flex flex-col">


                        <div class="text-[#A4A4A4] font-medium text-base flex ">
                            <!-- <div class="block sm:leading-8 leading-0"> -->
                                <div class="w-full bg-yellow-0">
                                    <div class="float-left h-[22px]  sm:h-8 mr-2 sm:mr-2 flex items-end justify-center bg-red-0"> 
                                        <div class=" text-pada px-[2px] h-[16px] sm:h-7 sm:px-2 sm:py-1  ring-2 ring-pada border-solid flex justify-center items-center rounded-sm sm:text-sm text-xs font-bold">{{ post.tagString }}</div>
                                    </div>
                                    <div class="text-[#17181A] sm:font-bold sm:text-xl bg-red-0 flex-grow leading-[1.7] sm:leading-[1.8] tracking-tight ">
                                        {{ post.title1 }}
                                    </div>
                                    <!-- <span class="text-pada px-2 py-[2px] sm:px-2 sm:py-1  border-pada border-2 border-solid flex justify-center items-center rounded-md sm:text-sm text-[13px] font-bold ">{{ post.tagString }}</span>
                                    <span class="text-[#17181A] sm:font-bold sm:text-xl flex-grow  ">{{ post.title1 }}</span> -->
                                </div>
                            <!-- </div> -->
                         </div>
                        <!-- <div class="text-[#A4A4A4] font-medium text-base flex">
                            <div class="block sm:leading-8 leading-0">
                                <div class="text-pada font-medium text-base flex-shrink-0 mr-2 border border-solid border-pada rounded-md px-2 py-[3px]">
                                    {{ post.tagString }}
                                
                                </div>
                                <span class="text-[#17181A] sm:font-bold sm:text-xl flex-grow ">{{ post.title1 }}</span>
                            </div>
                         </div> -->
                        <p class="text-[#676767] font-normal bg-blue-0 text-sm ">{{ post.title2 }}</p>
                    </div>
                </li>
            </ul>

        </div>
        <div class="w-full h-40"></div>

    </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import axios from 'axios';
import data from '@/assets/data/column.json'
import ColumnModal from './ColumnModal.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
const router = useRouter();
const store = useStore();
const route = useRoute();

const handlePost = (post) => {
    if(route.path.includes('/mobile')){
        router.push({ path: `/mobile/column/${post.id}` });
    } else {
        router.push({ path: `/news/column/${post.id}` });
    }
}


const column = computed(() => {
    const allData = store.getters.allChannelData;
    const filteredData = allData.filter(data => data.category === 3);
    filteredData.sort((a, b) => b.id - a.id);
    return filteredData;
});
const isFlutter = () => {
    if(route.path.includes('/mobile')){
        return true;
    } else {
        return false;
    }
}

</script>

<style>

</style>

<!-- <p>
    <span class="text-pada w-12 h-7 border-pada border-2 border-solid flex justify-center items-center rounded-md text-sm font-bold ">
    {{ post.tag }}
</span> <span>{{ post.date }}</span>
</p> -->

<template>
    <div class="w-screen h-screen  overflow-hidden flex justify-center items-center">
        <div class="flex flex-col text-def space-y-8 items-center">
            <img src="@/assets/img/logo/errorRep.svg" alt="" class="w-60">
            <h1 class="text-4xl font-medium text-center">페이지를 찾을 수 없습니다</h1>
            <p class="leading-relaxed text-center">원하시는 결과를 찾을 수 없습니다. <br>
            올바른 URL을 입력하셨는지 확인해주세요. <br>
            </p>
            <button class="w-40 rounded-full text-pada  border-pada border border-solid py-3" @click="backToMain"> 메인으로 돌아가기</button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';

const router = useRouter();
const backToMain = () => {
router.push('/')
}

</script>
<style>


</style>

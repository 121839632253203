<template>
    <div class="w-full h-auto bg-red-5">
        <div v-if="selectedSpecies === '볼파이톤'" class="-mt-6 flex justify-end h-6">
            <input type="text" class="w-[230px] text-xs pl-2 mb-1 placeholder:text-[11px] rounded-sm placeholder:pl-1 ring-1 ring-zinc-400" placeholder="모프를 검색해주세요" v-model="inputPython"                    
            @input="handleSearch(($event.target).value)">
        </div> 
        <div class="flex items-start font-medium justify-start ">
            <div class="w-20 text-lg tracking-tighter">카테고리<span class="text-pada ">*</span></div>
            <div class="w-11/12 h-full ml-4 border  text-[rgb(106,109,113)] border-solid border-zinc-200 rounded-md text-sm font-normal cursor-pointer flex">
                <ul class="w-1/3 h-44 bg-yellow-20 overflow-y-scroll flex flex-col ">
                    <li v-for="(item, index) in category" :key="index" @click="clickCategory(item)" class="p-2"
                    :class="{'font-medium text-def': selectedCategory === item.category}">
                    {{ item.category }}
                    </li>
                </ul>
                <ul class="w-1/3 h-44 bg-purple-20 overflow-y-scroll overflow-x-hidden" v-if="selectedSpeciesList">
                    <li v-for="(species, idx) in selectedSpeciesList" :key="idx" @click="clickSpecie(species)" class="p-2"
                    :class="{'text-def font-medium': selectedSpecies === species}">
                    {{ species }}
                    </li>
                </ul>
                <ul class="w-1/3 h-44 bg-blue-20 overflow-y-scroll overflow-x-hidden" v-if="selectedMorphList && selectedSpecies !== '볼파이톤'">
                    <li v-for="(morph, idx) in selectedMorphList" :key="idx" class="p-2" 
                    :class="{'text-def font-medium': selectedMorph.includes(morph)}"
                    @click="clickMorph(morph)">
                    {{ morph }}
                    </li>
                </ul>
                <ul class="w-1/3 h-44 bg-blue-20 overflow-y-scroll overflow-x-hidden" v-if="selectedMorphList && selectedSpecies === '볼파이톤'">
                    <li v-for="(morph, idx) in autoCompleteArr" :key="idx" class="p-2" 
                    :class="{'text-def font-medium': selectedMorph.includes(morph)}"
                    @click="clickMorph(morph)">
                    {{ morph }}
                    </li>
                </ul>
            </div> 
        </div> 
        <div class="pl-24 my-4 text-sm font-medium">선택한 종 : 
            <span class="text-pada"> {{ selectedCategory }} {{selectedSpecies ? ` > ${selectedSpecies} ` : '' }} 
            {{selectedMorph.length > 0 ? ` > ${selectedMorph}` : ''  }} </span></div>
    </div>
</template>
<script setup>
import { ref, watch, defineEmits, defineProps, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { findCategory } from '@/utils/findCategory';
import data from  '@/assets/data/category.json'
import { useRoute } from 'vue-router';
import debounce from 'lodash/debounce';
const route = useRoute();
const isEditMode = computed(() => route.path.includes('/edit'))
const store = useStore();
const inputPython = ref('');
const autoCompleteArr = ref(data[2].morph['볼파이톤']);
const handleSearch = debounce((value) => {
    const lowerCaseValue = value.toLowerCase();
    const result = selectedMorphList.value.filter((c) => c.toLowerCase().includes(lowerCaseValue));
    autoCompleteArr.value = result;
}, 50); 
const editData = computed(() => store.state.clickedAdopt)

const category = data;
const selectedCategory = ref(''); // 게코/거북이/뱀... 카테고리 선택 (item 담음)
const selectedSpecies = ref(''); // 크레스티드게코
const selectedMorph = ref([]); // , , , 으로 받음 
const selectedSpeciesList = ref([]); // 크레스티드게코/치화/펫테일....  
const selectedMorphList = ref([]); // 노말, 릴리, 화이틍 ...
const selectedItem = ref([]);
const emit = defineEmits(['update:jong', 'update:detail']);


onMounted(() => {
    if(isEditMode.value){
        // console.log('onMounted', editData.value);
        selectedCategory.value = findCategory(editData.value.jong);
        selectedItem.value = category.find(item => item.category === selectedCategory.value);
        selectedSpecies.value = editData.value.jong;
        selectedSpeciesList.value = selectedItem.value.species;
        if(editData.value.detail){
            selectedMorph.value = editData.value.detail.split(',');
        }
        selectedMorphList.value = selectedItem.value.morph[editData.value.jong]
    }
});


const clickCategory = (item) => {
    selectedSpecies.value = '';
    selectedMorph.value = [];
    selectedMorphList.value = [];
    selectedCategory.value = item.category;
    selectedItem.value = category.find(item => item.category === selectedCategory.value)
    selectedSpeciesList.value = selectedItem.value.species;

}

const clickSpecie = (species) => {
    selectedSpecies.value = species;
    selectedMorphList.value = selectedItem.value.morph[species];
    selectedMorph.value = [];
}

const clickMorph = (morph) => {
    if (!selectedMorph.value.includes(morph)) {
        selectedMorph.value.push(morph);
    } else {
        selectedMorph.value = selectedMorph.value.filter(m => morph !== m);
    }
    // console.log(selectedMorph.value);
};


// 선택된 값이 변경될 때 부모 컴포넌트에 알림
const emitToParent = () => {
  emit('update:jong', selectedSpecies.value);
  emit('update:detail', selectedMorph.value);
};

// 선택된 값이 변경될 때마다 emitToParent 함수를 호출
watch([selectedSpecies, selectedMorph], emitToParent);
</script>

<style>

</style>

<template>
    <div class="w-[768px] h-full min-h-screen bg-red-5">
        <div class="my-4 font-bold text-lg justify-between flex">
            <h1 class="">자유게시판 글쓰기</h1>
            <!-- <select class="text-xs font-normal p-1 border rounded-md" >
                        <option value="latest">최신순</option>
                    </select> -->
        </div>
        <div class="w-full flex-wrap flex">
            <div class="flex flex-col">
                <label for="file">
                    <div class="btn-upload mr-4 mb-4">
                    <img src="@/assets/img/logo/camera.svg" alt="" class="w-6 h-6" />
                    <p class="text-xs mt-1 text-[#898989]">({{ thumbnail.length }}/10)</p>
                    </div>
                </label>
                <input type="file" name="file" id="file" @change="upload" multiple />

            </div>

            <div
                v-for="item in thumbnail"
                :key="item.id"
                class="previewImg relative mr-4 mb-4"
            >
                <img :src="item" alt="" class="w-full h-full object-cover rounded-xl" />
            <div
            class="bg-[#17181a] w-6 h-6 rounded-full absolute -top-2 -right-2 flex justify-center items-center font-light cursor-pointer text-white"
            @click="deletePhoto(item)"
            >
                <span class="mt-[2px]">&times;</span>
            </div>
        </div>
    </div>


    <div class="w-full border-solid border-b border-zinc-100 my-8"></div>

    <div class="flex flex-col space-y-4">

        <div class="flex items-center font-medium">
            <div class="w-20  text-lg">제목<span class="text-pada ">*</span></div>
            <input
            class="w-11/12 h-10 pl-4 border border-solid border-[#D6D6D6] rounded-md text-sm font-normal placeholder:text-sm placeholder:text-zinc-300 placeholder:font-light
            focus:border-[#1B1B1D]"
            placeholder="제목을 입력해주세요"
            v-model="formData.title"
            />
        </div>
        <div class="flex items-start font-medium">
            <div class="w-20  mt-2 text-lg">내용<span class="text-pada">*</span></div>
            <textarea
            class="w-11/12 p-4 border border-solid border-[#D6D6D6] rounded-md resize-none text-sm font-normal placeholder:text-sm placeholder:text-zinc-300 placeholder:font-light            focus:border-[#1B1B1D]"
            cols="30"
            rows="10"
            placeholder="상세 내용을 입력해주세요"
            v-model="formData.content"
            ></textarea>
        </div>
    </div>

    <div class="w-full flex justify-end mt-8">
        <button class="w-28 h-10 bg-pada text-white text-sm rounded-lg" @click="submitData"> 등록 </button>
    </div>
    <div class="w-full h-40"></div>

</div>
</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue';
import imageCompression from "browser-image-compression";
import { BoardApi } from '@/api/api';
import { useRouter } from 'vue-router';
const router = useRouter();
const thumbnail = ref([ ]);

const formData = reactive({
    user_id : 'padamasterc',
    title :'',
    content : '',
    category : 1,
    images : []

});

// 이미지 압축하기 
const compressImage = async (file) => {
    const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
    };
    return await imageCompression(file, options);
};
// 이미지 업로드 
const upload = async (e: Event) => {
    const input = e.target as HTMLInputElement;
    if (input.files) {
        const files = Array.from(input.files).slice(0, 10); // 최대 10개의 파일만 선택
        for (let file of files) {
            const url = URL.createObjectURL(file);
            thumbnail.value.push(url); // 미리보기용 URL 추가
            formData.images.push(file); // 실제 파일을 formData에 추가
        }
    }
};

// 이미지 삭제
const deletePhoto = (item :string) => {
    thumbnail.value = thumbnail.value.filter(img => img !== item)
};

const submitData = async () => {
    if(formData.images.length > 0) {
       await submitIncludeImage();
    }else {
       await submitOnlyText();
    }
    router.push('/community')
};

// 게시글 only 텍스트
const submitOnlyText = async () => {
    console.log('날라가는거 확인', formData)
    //서버에 FormData 전송
    try {
        const response = await BoardApi.addPostText(
            formData.user_id, 
            formData.title, 
            formData.content, 
            formData.category, 
        );
        // console.log(response)
    } catch (error) {
        console.log(error)
    }
};
// 게시글 이미지 포함
// 데이터 제출 함수
const submitIncludeImage = async () => {
    const form = new FormData();

    // 기본 필드 추가
    form.append('user_id', formData.user_id);
    form.append('title', formData.title);
    form.append('content', formData.content);
    form.append('category', formData.category.toString());

    // 이미지 파일 추가
    for (const [index, image] of formData.images.entries()) {
        // 0번 인덱스 이미지 파일, 압축하고 썸네일로 복사하기
        if (index === 0) {
            try {
                const compressedFile = await compressImage(image);
                // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
                form.append('uploadfiles', compressedFile, `thumbnail.jpg`);
            } catch (error) {
                console.log(error);
            }
        }
        form.append('uploadfiles', image, `imgURL${index}.jpg`);
    }

    // 서버에 FormData 전송
    try {
        const response = await BoardApi.addPostImages(form);
        // console.log(response);
    } catch (error) {
        console.error(error);
    }
};


// watch(setFilter,submit);

// const checkContNull = () => {
//     if(selectJong.value === null){

//     } else {
//         isNull.value = false
//     }

// }


</script>

<style scoped>
.btn-upload {
    width: 6rem;
    height: 6rem;
    background: #fff;
    border: 1px solid #898989;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.previewImg {
    width: 6rem;
    height: 6rem;
}
#file {
    display: none;
}
input:focus, textarea:focus{
    outline: none;

}
</style>

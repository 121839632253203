<template>
    <div class="max-w-[1024px] mx-auto px-2 my-40 lg:px-0 h-auto flex  flex-col items-center">
        <BoardTitle
            title="정보"
            :iconSrc="require('@/assets/img/logo/information.svg')"
            subtitle="파충류 정보"
        />
        <div class="md:px-28  px-12 rounded-[10px] w-full relative pt-10 pb-20  ">
            <InfoAnnounce/>
        </div>
        <div class="md:px-28  px-12 rounded-[10px] w-full relative pt-10 pb-20  ">
            <ColumnAnnounce/>
        </div>
        <div class="md:px-28  px-12 rounded-[10px] w-full relative pt-10 pb-20  ">
            <EventAnnounce/>
        </div>

     
    
  </div>
</template>

<script>

import BoardTitle from './BoardTitle.vue';
import EventAnnounce from './EventAnnounce.vue';
import InfoAnnounce from './InfoAnnounce.vue';
import ColumnAnnounce from './ColumnAnnounce.vue'

import {defineComponent} from 'vue';
export default defineComponent({
    name : 'InformationSlide',
    components: {
    BoardTitle,
    InfoAnnounce,
    EventAnnounce,
    ColumnAnnounce
},
    data(){
        return{

        }
    },
    setup() {
        return{
        }
        
    }

});
</script>
<style scoped>
/* Swiper 네비게이션 버튼 스타일 */
/* .swiper-button-next-custom,
.swiper-button-prev-custom {
    position: absolute;
    top: calc(50% - 2rem);
    background-color: white;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: #838383;
    box-shadow: 0px 0px 20px rgba(8, 50, 25, 0.12);
    cursor: pointer;;
}
.swiper-button-next-custom{
    right: 3%;
}
.swiper-button-prev-custom{
    left: 3%;
} */

</style>
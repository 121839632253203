<template>
    <div v-if="isLoading">
        <div class="w-full pt-40 justify-center items-start flex">
            <LoadingCircle />
        </div>
    </div>
    <div v-else class="md:w-[768px] min-h-screen w-full mt-0 text-def">
        <div class="w-full h-120 ">
            <img :src="eventInfo.imgURL1" alt="" class="w-full h-full object-cover">
        </div>
        <div class="px-4 py-4">
            <div class="flex flex-col">
                <div class="text-[15px] mt-2 flex justify-between bg-red-0 relative">
                    <span>{{ eventInfo.title3 }} </span>
                    <div class="md:hidden flex">
                        <div class="w-6">
                            <img src="@/assets/img/logo/share.svg" alt="" class="w-full " @click="handleShareBtn">
                        </div>
                        <div v-if="shareBtn">
                            <ShareWays @close="handleShareBtn" :title="eventInfo.title1" :loca="eventInfo.title2"
                                :date="eventInfo.title3" :img="eventInfo.imgURL1"
                                :link="`news/event/${route.params.id}`" />
                        </div>

                    </div>
                    <!-- 나는 웹 -->
                    <div class="hidden md:flex absolute top-0 right-0">
                        <div class="w-10 cursor-pointer ">
                            <KakaoShare :title="eventInfo.title1" :loca="eventInfo.title2" :date="eventInfo.title3"
                                :img="eventInfo.imgURL1" :link="`news/event/${route.params.id}`" />
                        </div>
                    </div>


                </div>
                <div class="flex my-2">
                    <div
                        class="px-2 mr-2 text-pada h-8 whitespace-nowrap bg-[#E0F9F1] font-bold rounded-[5px] text-sm flex justify-center items-center">
                        {{ diffDay(eventInfo.title3) }}</div>
                    <span class="font-bold text-[20px]">{{ eventInfo.title1 }}</span>
                </div>
            </div>



            <div class="border border-solid border-[#D9DCE0] w-full  h-auto p-4 flex items-center mt-2 rounded-[10px]">
                <div class=" space-y-2">
                    <div class="flex items-center ">
                        <div>
                            <img src="@/assets/img/logo/pocket.svg" alt="" class="pt-[1px] w-4 mr-2">
                        </div>
                        {{ ticketPrice(eventInfo.price) }}
                    </div>


                    <div class="flex items-start "><img src="@/assets/img/logo/location.svg" alt=""
                            class="pt-1 w-4 mr-2">{{ eventInfo.title2 }}</div>
                    <div class="flex items-start">
                        <img src="@/assets/img/logo/parking.svg" alt="" class="pt-1 w-4 mr-2">
                        <div class="flex flex-col">{{ park }}
                            <div class="text-sub text-sm font-medium">{{ parkDesc }}</div>
                        </div>
                    </div>
                    <!-- 예매하기 링크가 없을떄?? -->
                    <div class="flex items-start" v-if="eventInfo.link1 === ''">

                        <img src="@/assets/img/logo/clock.svg" alt="" class="pt-1 w-4 mr-2">
                        <div class="flex flex-col">
                            <div v-if="eventInfo.date1" class="whitespace-pre-wrap ">{{ eventInfo.date1 }}</div>
                            <div v-if="eventInfo.date2">{{ eventInfo.date2 }}</div>
                            <div v-if="eventInfo.date3">{{ eventInfo.date3 }}</div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="border border-solid border-[#D9DCE0] w-full  h-auto p-4  mt-5 rounded-[10px] flex flex-col "
                v-if="eventInfo.link1 !== ''">
                <div class="flex flex-col ">
                    <label v-if="eventInfo.link1">
                        <input type="radio" name="date" value="link1" v-model="selectedBookLink" class="radio-input"
                            checked>
                        <span class="whitespace-pre-wrap ">{{ eventInfo.date1 }}</span>
                    </label>
                    <!-- {{ eventInfo }} -->
                    <label v-if="eventInfo.link2">
                        <input type="radio" name="date" value="link2" v-model="selectedBookLink" class="radio-input">
                        <span>{{ eventInfo.date2 }}</span>
                    </label>
                    <label v-if="eventInfo.link3">
                        <input type="radio" name="date" value="link3" v-model="selectedBookLink" class="radio-input">
                        <span>{{ eventInfo.date3 }}</span>
                    </label>
                    <button v-if="diffDay(eventInfo.title3) === '종료'"
                        class="w-full h-10 rounded-[5px] mt-4 text-white font-bold text-[15px] bg-zinc-300" disabled>
                        종료
                    </button>
                    <button v-else class="bg-pada w-full h-10 rounded-[5px] mt-4 text-white font-bold text-[15px]"
                        @click="handleBookBtn(selectedBookLink)">예매하기
                    </button>
                </div>
            </div>
        </div>
        <div class="bg-[#F5F7F9] h-2 "></div>

        <div class="px-4 ">
            <div class="my-4">
                <h2 class="font-bold mb-4">👉 소개 </h2>
                <div class="bg-[#F5F7F9] w-full h-auto p-4 whitespace-pre-wrap rounded-[5px] text-[15px] text-def">
                    {{ eventInfo.introduce }}
                </div>
            </div>
            <div class="my-4" v-if="eventInfo.event">
                <h2 class="font-bold mb-4">🎉 이벤트 </h2>
                <div class="bg-[#F5F7F9] w-full h-auto p-4 whitespace-pre-wrap rounded-[5px] text-[15px] text-def">
                    {{ eventInfo.event }}
                </div>
            </div>
        </div>
        <div v-if="Number(id) === 108">
            <div class="bg-[#F5F7F9] h-2 "></div>
            <div class="px-4 pb-4">
                <h2 class="font-bold my-4">🪧 전시장 배치도</h2>
                <div>
                    <img :src="imageSrc" @click="showModal = true" alt="Click to enlarge"
                        class="w-full h-full object-cover cursor-pointer" />
                    <div v-if="showModal"
                        class="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
                        <div @click.stop class="image-container relative">
                            <img :src="imageSrc" alt="Enlarged view" class="enlarged-image" />
                            <button @click="closeModal"
                                class="absolute top-4 right-4 text-white bg-gray-800 rounded-full p-2 hover:bg-gray-700 focus:outline-none">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-[#F5F7F9] h-2 "></div>
        <div class="px-4">
            <h2 class="font-bold my-4">📍 전시 위치</h2>
            <div class="bg-[#F5F7F9] w-full h-auto p-4 whitespace-pre-wrap rounded-[5px] text-[15px] text-def" id="map">


            </div>
            <p ref="addressText" class="text-[15px] my-2">{{ eventInfo.title2 }}</p>
            <button class="flex">
                <img src="@/assets/img/logo/copy.svg" alt="" class="w-4 mr-1">
                <span class="underline text-xs text-sub" @click="handlCopy">주소복사</span>
            </button>
        </div>
    </div>
    <div class="w-full h-10"></div>
</template>

<script setup>
// <script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, toRef } from 'vue';
import { useStore } from 'vuex';
import { diffDay } from '@/utils/timeForToday';
import { ticketPrice } from '@/utils/formattedUnit';
import LoadingCircle from '../UI/LoadingCircle.vue';
import { useRoute } from 'vue-router';
import { useMeta } from 'vue-meta';
import KakaoShare from '../UI/KakaoShare.vue';
import ShareWays from '../UI/ShareWays.vue';
import useBodyScrollLock from '@/hooks/useBodyScrollLock'


const imageSrc = ref('https://pada.s3.ap-northeast-2.amazonaws.com/paper2/july2024/placePic.png')
const showModal = ref(false)

const closeModal = () => {
    showModal.value = false
}

useBodyScrollLock(toRef(showModal))

const kakaoApi = process.env.VUE_APP_KAKAO_API

// declare global {
//     interface Window {
//       kakao : any;
//     }
// }
const store = useStore();
const route = useRoute();
const isLoading = computed(() => store.state.isLoading);
const eventInfo = computed(() => store.state.clickedEvent);
const selectedBookLink = ref('link1');
const lati = ref(0);
const long = ref(0);
const park = ref('');
const parkDesc = ref('');
const shareBtn = ref(false);
const handleShareBtn = () => {
    shareBtn.value = !shareBtn.value
};

const id = route.params.id
console.log(typeof id)
// const eventInfo = ref({
//     link1 : '',
//     date1 : `5월 18일 토요일~26일 일요일 
// 10:30~20:30 (금,토 10:00~21:00)`,
//     imgURL1 : 'https://pada.s3.ap-northeast-2.amazonaws.com/paper/images/junbro.jpg',
//     title1 : '정브르 팝업스토어',
//     title2 : '서울 용산구 아이파크몰 용산점 리빙파크 3F 이벤트홀 A',
//     title3 : '2024.05.18(토)~26(일)',
//     parking : '주차 가능 / 10분당 1,300원, 무료 (회차) 없음, 최대 5시간 할인',
//     latitude : '23.124215',
//     longitude : '124.12412',
//     price : '3000',
//     event : `hihi
//     너무좋아요 ㅎㅎ`,
//     introduce : `hhh`
// })
let map;

useMeta({
    title: eventInfo.value.title1,
    meta: [
        { vmid: 'description', property: 'description', content: eventInfo.value.introduce },
        // { vmid: 'keywords', property: 'keywords', content: eventInfo.value.detail },
        { vmid: 'og:title', property: 'og:title', content: eventInfo.value.title1 },
        { vmid: 'og:image', property: 'og:image', content: eventInfo.value.imgURL1 },
    ]
})
// 플러터 이벤트 리스너 해제를 위한 빈 함수 
const handlePlatformReady = () => {
};
onMounted(async () => {
    lati.value = eventInfo.value.latitude.replace(/,/g, "");
    long.value = eventInfo.value.longitude;

    if (eventInfo.value.parking) {
        if (eventInfo.value.parking.includes('/')) {
            const parkArr = eventInfo.value.parking.split('/');
            park.value = parkArr[0];
            parkDesc.value = parkArr.length > 1 ? parkArr[1] : '';
        } else {
            park.value = eventInfo.value.parking;
            parkDesc.value = '';
        }
    } else {
        park.value = '';
        parkDesc.value = '';
    }

    // 카카오맵 스크립트 호출
    await loadScript();

    window.addEventListener("flutterInAppWebViewPlatformReady", handlePlatformReady);

});
onUnmounted(() => {
    window.removeEventListener("flutterInAppWebViewPlatformReady", handlePlatformReady);
});

// 주소 복사하기 버튼
const addressText = ref();
const handlCopy = () => {
    const textToCopy = addressText.value.innerText;
    navigator.clipboard.writeText(textToCopy).then(() => {
        alert('주소가 복사되었습니다.')
    })
}



// 현재 링크 공유하기
const shareLink = async () => {
    if (navigator.share) {
        try {
            await navigator.share({
                text: `${eventInfo.value.title1}\n${eventInfo.value.title3}\nhttps://padaapp.com/news/event/${route.params.id}`,
            });
            // console.log("성공적으로 공유되었습니다.");
        } catch (error) {
            console.error("공유하기 실패:", error);
        }
    }
};

// // 현재 링크 공유하기
// const shareLink = () => {
//     const link = `https://padaapp.com/news/event/${route.params.id}`
//     navigator.clipboard.writeText(link).then(() => {
//         alert('링크가 복사되었습니다.')
//     })
// }
// onMounted(()=> {
//     window.addEventListener("flutterInAppWebViewPlatformReady", function() {
//         // console.log('start')
//         // test.value = 'flutter Start'
//         // if (window.flutter_inappwebview.callHandler){ 
//         //         window.flutter_inappwebview.callHandler('linkClick')
//         //             .then(function(result) {
//         //             console.log(JSON.stringify(result));
//         //         });
//         // }else{
//         //     window.flutter_inappwebview._callHandler('linkClick')
//         //         .then(function(result) {
//         //         console.log(JSON.stringify(result));
//         //     });
//         // }
//     });
// })
const handleBookBtn = async (linkKey) => {
    const link = eventInfo.value[linkKey];
    // console.log(link)
    if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler('linkClick', link)
            .then(response => {
                // console.log(response)
            })
            .catch(error => {
                console.log(error)
            });
    } else {
        window.open(link, '_blank'); // 링크 열기
    }
};


const loadScript = () => {
    const script = document.createElement("script");
    script.src =
        `https:///dapi.kakao.com/v2/maps/sdk.js?appkey=${kakaoApi}&autoload=false`;
    script.onload = () => window.kakao.maps.load(loadMap);

    document.head.appendChild(script);
}

const loadMap = () => {
    const container = document.getElementById("map");
    const options = {
        center: new window.kakao.maps.LatLng(lati.value, long.value),
        // center: new window.kakao.maps.LatLng(lati.value, long.value),
        level: 7,
    };

    // 여기서 map 변수를 사용
    map = new window.kakao.maps.Map(container, options);
    loadMaker();
}
const loadMaker = () => {
    // 마커가 표시 위치
    const markerPosition = new window.kakao.maps.LatLng(lati.value, long.value);

    // 마커 생성
    const marker = new window.kakao.maps.Marker({
        position: markerPosition,
    })

    // 마커가 지도 위에 표시되도록 설정
    marker.setMap(map)
}
</script>

<style scoped>
.boxShadow {
    box-shadow: 0px 0px 8px rgba(8, 50, 25, 0.13);
    border-radius: 5px;
}

#map {
    width: 100%;
    height: 380px;

}

@media (max-width: 640px) {
    #map {
        width: 100%;
        height: 180px;
    }
}

/* 라디오 버튼 숨기기 */
.radio-input {
    visibility: hidden;
    position: absolute;
}

.radio-input+span {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    display: inline-block;
    /* 커스텀 라디오 버튼 스타일 */
}

.radio-input+span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 1rem;
    height: 1rem;
    border: 1px solid #00D191;
    border-radius: 50%;
    background-color: #fff;
}

.radio-input:checked+span:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 8px;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: #00D191;
}

/* Adjust close button position for mobile */
.image-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.enlarged-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

@media (max-width: 768px) {
    .image-container {
        width: 100vh;
        height: 100vw;
        transform: rotate(90deg);
    }

    .enlarged-image {
        max-width: 100vh;
        max-height: 100vw;
        width: auto;
        height: auto;
    }
}
</style>

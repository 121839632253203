<template>
    <div class="fixed top-0 left-0 w-full h-full flex justify-center items-center" style="z-index: 990;">
        <!-- 딤드 배경 -->
        <div class="absolute top-0 left-0 w-full h-full bg-black opacity-40"></div>
        
        <div class="relative bg-white w-80 h-80 rounded-2xl flex justify-center items-center flex-col" style="z-index: 999;">
            <div class="cursor-pointer absolute top-2 right-7 text-3xl" @click="logout"> 
                &times;
            </div>
            <button >
                <img src="@/assets/img/logo/welcome.png" alt="">
            </button>
            <div class="w-10/12 text-center mt-4 font-semibold">
                <p>파충류 다 있다에 오신것을 환영합니다!</p>  
                <p>서비스를 이용하시려면</p>
                <p>이용약관에 동의해주세요</p>
            </div>
            <button @click="openWindow" class="text-[#838383] underline my-2">이용약관 보기</button>
            
            <button 
            class="bg-pada w-10/12 h-10 mt-1 rounded-lg text-white font-bold"
            @click="emitToParnet">동의합니다</button>
        </div>
    </div>
  </template>
  

<script lang="ts" setup>
import { onMounted, onUnmounted, defineEmits } from 'vue';
import { accessToken } from '@/utils/Functions';
import { useRouter } from 'vue-router';
const router = useRouter();
const emit = defineEmits(['closePopup'])
const emitToParnet = () => {
    emit('closePopup')
}
const openWindow = () => {
  window.open(
    "https://pada.s3.ap-northeast-2.amazonaws.com/paper/html/modi_agree1.html",
    "_blank",
    "width=800,height=600"
  );
};

const logout = () => {
    accessToken.value = null;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userInfo')
    emit('closePopup')
    router.push('/')
}
onMounted(() => {
      document.body.style.overflow = 'hidden';
    });

onUnmounted(()=> {
    document.body.style.overflow = 'auto';

})
</script>

<style>

</style>
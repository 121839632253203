<template>
    <div class="max-w-5xl h-auto flex my-20 mx-auto flex-col items-center px-2 lg:px-0">

        <BoardTitle
            title=""
            :iconSrc="require('@/assets/img/logo/chat.svg')"
            subtitle="지금 인기있는 글"
        />
        <div class="grid gap-5 w-full h-full md:grid-cols-2 grid-cols-1 ">
            <div v-for="(item) in adoptedData" :key="item.id" class="w-full h-48 py-5 px-4 flex boxShadow rounded-[10px] relative
                 ">

                <div class="w-full bg-red-5 h-full flex flex-col text-xs lg:text-base ">
                    <div class="flex flex-row w-full bg-green-5">
                        <div class="tag" style="background-color: #E0F9F1;">{{ categoryToText(item.category) }}</div>
                        <div class="justify-center items-center box-border flex text-[15px] text-[#676767]">{{ item.name }}</div>
                    </div>

                    <div class="w-11/12  bg-blue-0 mt-3 space-y-1">
                        <p class="line-clamp-1 font-medium">{{ item.title }}</p>
                        <p class="line-clamp-2 leading-snug text-def text-[15px] ">{{ item.content }}</p>
                        
                    </div>
                    <div class="bg-red-5 w-11/12 h-full flex mt-4">
                        <div class="relative flex">
                            <div 
                                v-for="(liker, index) in item.likerList.slice(0,3)" 
                                :key="index" 
                                :class="`w-7 h-7 absolute bottom-0 hidden sm:flex`" 
                                :style="{ left: `${index * 20}px` }"
                            >
                                <img 
                                :src="liker.img !== 'b' ? liker.img : require('@/assets/img/logo/defaultProfile.svg')" 
                                alt="" 
                                class="w-full h-full rounded-full object-cover"
                                > 
                            </div>
                            </div>
                            <p class="text-sm text-sub sm:ml-[76px] ml-0 absolute  items-center bottom-4">
                               <span class="font-medium hidden sm:block">{{ item.likerList[0].name }}님 외</span> {{ item.like_cnt }}명이 좋아요를 눌렀어요
                            </p>
                        </div>

                                    

                </div>
                <div class="ml-auto  rounded-md aspect-square bg-center bg-cover"
                :style="{
                    backgroundImage: 'url(' + item.imgURL1 + ')',
                }"
                >
                </div>
                
                
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import BoardTitle from '@/components/Main/BoardTitle.vue'
import { useStore } from 'vuex';
import axios from 'axios';
const store = useStore();
// const apiURL = 'http://172.16.128.26:8081';
const apiURL = process.env.VUE_APP_API_URL;


// const adoptedData = computed(() => store.state.community[0].data )
// const adoptedData = computed(() => store.getters.getCommMainList )

const categoryToText = (number) => {
    switch(number){
        case 1 : return '자유';
        case 2 : return '질문&답변';
        case 3 : return '갤러리';
    }
}

const adoptedData = ref([]);
onMounted(async () => {
    // if(adoptedData.value.length === 0){
    //     await store.dispatch('fetchCommData', 0);
    // }
    homeboardPost()
})
const homeboardPost = async () => {
    try {
        const viewRsp = await axios.get(`${apiURL}/zpw_bbs/homeboard`
        );
        // return viewRsp;
        // console.log(viewRsp)
        adoptedData.value=(viewRsp.data.slice(0, 4))
    } catch (e) {
        console.error("error : ", e);
        // return undefined;
    }
}

</script>
<style scoped>
.tag{
    /* border: 1px solid #00d191; */
    border-radius: 5px;
    background-color: white;
    /* width: 4rem; */
    height: 1.5rem;
    font-weight: bold;
    padding: .1rem 0.5rem;
    margin-right: .4rem;
    color: #00d191;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.boxShadow {
    box-shadow: 0px 0px 10px rgba(8, 50, 25, 0.06);
}

</style>
<template>
    <div class="relative top-0 right-0 mr-2">
        <div id="googleButton" class="g_id_signin" data-type="icon" ></div>
    </div>
    <div v-if="isLoading">
        <DimmedLoadingV2 />
    </div>
    <!-- <div v-if="!welcome"> -->
        <!-- <agreeBox @closePopup="hadleWelcome"/> -->
    <!-- </div> -->
            <!-- <button @click="sendInfo">send</button>
            <button @click="test">test </button> -->
</template>

<script setup>
import { onMounted, reactive, computed } from 'vue'
import axios from 'axios';
import DimmedLoadingV2 from '../UI/DimmedLoadingV2.vue';
import { generateNickname } from '@/utils/generateNickname';
import { parseJwt } from '@/utils/parseJwt';
import { accessToken } from '@/utils/Functions'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const apiURL = process.env.VUE_APP_API_URL;
// const apiURL = 'http://172.16.128.26:8080'
const store = useStore();
const GOOGLE_KEY = process.env.VUE_APP_GOOGLE_API;
const isLoading = computed(() => store.state.isLoading)

/* global google */
  // https://developers.google.com/identity/gsi/web/reference/js-reference?hl=ko
onMounted(() => {


const script = document.createElement('script');
script.src = 'https://accounts.google.com/gsi/client';
script.async = true;
script.defer = true;
document.body.appendChild(script);

script.onload = () => {
    google.accounts.id.initialize({
        client_id: GOOGLE_KEY, 
        callback: handleCredentialResponse,
    });
    google.accounts.id.renderButton(
        document.getElementById('googleButton'),
        { 
            theme: 'outline', 
            size: 'large',
            type : 'standard',
            // type : 'icon',
            shape : 'circle',
            text: "signin",
        }
    );

};
})


const googleUser = reactive({
    email : '',
    name : '',
    img : 'b',
    token : '',
    type : 'google_web',
    fcmtoken : '',
})
const router = useRouter();

const handleCredentialResponse = async (response) => {
    store.commit('SET_LOADING', true)
    await new Promise(resolve => setTimeout(resolve, 1000));
    // console.log(response)
    const responsePayload  = parseJwt(response.credential);
    googleUser.email = responsePayload.email;
    googleUser.token = response.credential;
    googleUser.name = generateNickname();
    // console.log(googleUser)
    await sendInfo();
    store.commit('SET_LOADING', false)
    router.push('/')
    }

const sendInfo = async ( ) => {
    try {
        const params = new URLSearchParams();
        params.append('id', googleUser.email)
        params.append('name', googleUser.name)
        params.append('img', googleUser.img)
        params.append('token', googleUser.token)
        params.append('type', googleUser.type)
        params.append('fcmtoken', googleUser.fcmtoken)
        // for (const [key, value] of params) {
        //     console.log(`param ${key}: ${value}`);
        // }


        const rsp = await axios.post(`${apiURL}/zpw_users/login3`, params, { withCredentials:true },  {
        });
        setUserInfo(rsp);

        return rsp

    } catch(error) {
        console.log(error)
    }
}
const setUserInfo = ( rsp ) => {
    // console.log(rsp)
    // console.log(rsp.data.new)
    accessToken.value = rsp.data.accessToken
    const userInfo = {
        user_id : rsp.data.user.id,
        nickname : rsp.data.user.nickname || googleUser.name,
        img : rsp.data.user.img || googleUser.img,
    }
    const userInfoString = JSON.stringify(userInfo);
    const encodedData = btoa(encodeURIComponent(userInfoString));
    localStorage.setItem('userInfo', encodedData);
    if(rsp.data.new){
        store.commit('IS_WELCOME', true)
    }

}


  
</script>

import { ref, watchEffect } from 'vue';

const useIntersectionObserver = (options) => {
  const isVisible = ref(false);
  const elementRef = ref(null);

  watchEffect((onInvalidate) => {
    if (elementRef.value instanceof Element) {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          isVisible.value = true;
          observer.unobserve(entry.target); // 요소가 보이면 관찰 중단
        }
      }, options);

      observer.observe(elementRef.value);

      onInvalidate(() => {
        if (elementRef.value) {
          observer.unobserve(elementRef.value);
        }
      });
    }
  });

  return { elementRef, isVisible };
};

export default useIntersectionObserver;

// import { ref, onMounted, onBeforeUnmount, watchEffect } from 'vue';

// const useIntersectionObserver = (options) => {
//     const isVisible = ref(false);
//     const elementRef = ref<Element | null>(null);

//   watchEffect((onInvalidate) => {
//     if (elementRef.value instanceof Element) {
//       const observer = new IntersectionObserver(([entry]) => {
//         isVisible.value = entry.isIntersecting;
//       }, options);

//       observer.observe(elementRef.value);

//       onInvalidate(() => {
//         observer.unobserve(elementRef.value);
//       });
//     }
//   });

//   return { elementRef, isVisible };
// };

// export default useIntersectionObserver;

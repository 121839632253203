import data from '@/assets/data/category.json';
import { useStore } from 'vuex';

export const findCategory = (selectedSpecies) => {
    for (const category of data) {
        if (category.species.includes(selectedSpecies)) {
            return category.category;
        }
    }
    return null;
};


export const initFilter = (store) => {
    const resetValues = {
        price: '',
        location: '',
        gender: '',
        size: ''
    };
    store.commit('SET_SPECIES_STATUS', null);
    store.commit('SET_KEYWORD_STATUS', null);
    store.commit('SET_NO_RESULT', false);
    store.commit('SET_MORPH_STATUS', []);
    store.commit('SET_STATUS_FILTER', resetValues);
    store.commit('SET_TEMPORARY_FILTER', resetValues);

}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NaviHeight = _resolveComponent("NaviHeight")!
  const _component_CarouselSlide = _resolveComponent("CarouselSlide")!
  const _component_NewAdopted = _resolveComponent("NewAdopted")!
  const _component_CommunityPost = _resolveComponent("CommunityPost")!
  const _component_InformationSlide = _resolveComponent("InformationSlide")!
  const _component_PhoneMockup = _resolveComponent("PhoneMockup")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_NaviHeight),
    _createVNode(_component_CarouselSlide),
    _createVNode(_component_NewAdopted),
    _createVNode(_component_CommunityPost),
    _createVNode(_component_InformationSlide),
    _createVNode(_component_PhoneMockup)
  ]))
}
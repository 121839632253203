<template>
    <div class="max-w-4xl mx-auto  flex flex-col sm:flex-row w-[896px]:px-0 px-10 ">
        <div class="w-full sm:w-1/2 transition-all duration-1000  opacity-0"
        ref="elRef" :class="elClass">

            <TitleBox
            title="커뮤니티"
            content1="파충류에 관심있는 누구나"
            content2="다른 집사님들과 이야기해보세요."
            class="tracking-tighter"
        />
            <div class="lg:mt-40 mt-0 ">
                <p class="font-medium lg:my-20 my-10 mt-20 mb-10 leading-relaxed text-lg sm:text-xl sm:leading-relaxed
                text-center sm:text-left text-[#3E4044] ">나의 파충류 사육 경험을 나누고<br>
                    다른 집사님들과 이야기해보세요.</p>
                <img src="@/assets/img/download/sec7_card.png" alt="" class="sm:-translate-x-4 sm:h-120 ">
            </div>
        </div>
        <div class="w-full sm:w-1/2  flex flex-col justify-between transition-all duration-1000  opacity-0 mt-20 sm:mt-0 delay-300 " :class="elClass">
            <img src="@/assets/img/download/sec7_phone.png" alt="" >
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import useIntersectionObserver from '@/utils/v2IntersectionObserver';

import TitleBox from './TitleBox.vue'

export default defineComponent({
    name: 'SectionCommunity',
    components:{
        TitleBox
    },
    setup(){
        const elClass = ref('opacity-0 translate-y-10');
        const observerOptions = {
            root:null,
            rootMargin : '0px',
            threshold : 0.1
        };
        const {elementRef : elRef , isVisible : isVisible} = useIntersectionObserver(observerOptions);

        const updateVisibility = (isVisible , elClass)  =>{
            elClass.value = isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        };

        watch(isVisible, newVal => updateVisibility(newVal, elClass));

        return {
            elRef,
            elClass,
        }
    }

});
</script>

<style>

</style>
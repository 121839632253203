<template>
    <div class="bg-slate-50 w-full sm:h-100 h-160 flex  items-center " >
        <div class="w-[896px]  mx-auto lg:flex items-center justify-between space-y-20 sm:space-y-0 transition-all duration-1000  "
        ref="elRef"
        :class="elClass">
            <p class="sm:text-4xl text-3xl font-bold leading-relaxed flex mx-auto w-1/2 justify-center sm:justify-normal sm:leading-relaxed" :class="elClass"> 결제수단은 <br>원하는대로, </p>
            <div class="flex space-x-20 w-1/2 sm:w-full sm:ml-auto mx-auto justify-center sm:justify-end transition-all duration-1000  delay-300" :class="elClass">


                <div class="flex flex-col justify-center items-center  ">
                    <div class="bg-white rounded-2xl w-32 h-32 flex justify-center items-center">
                        <svg width="62" height="34" viewBox="0 0 62 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="0.25" width="60.75" height="33.75" rx="2" fill="url(#paint0_linear_106_1364)"/>
                            <rect x="5" y="7" width="15.75" height="6.75" rx="1" fill="#5F92F4"/>
                            <rect x="4" y="22.75" width="12.5" height="3.75" rx="1.875" fill="#5F92F4"/>
                            <rect x="17.75" y="22.75" width="12.5" height="3.75" rx="1.875" fill="#5F92F4"/>
                            <rect x="31.5" y="22.75" width="12.5" height="3.75" rx="1.875" fill="#5F92F4"/>
                            <rect x="45.25" y="22.75" width="12.5" height="3.75" rx="1.875" fill="#5F92F4"/>
                            <defs>
                            <linearGradient id="paint0_linear_106_1364" x1="60.6301" y1="34" x2="3.36089" y2="0.659863" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#5A92FF"/>
                            <stop offset="1" stop-color="#B4CEFF"/>
                            </linearGradient>
                            </defs>
                        </svg>
                    </div>
                   <p class="mt-6 text-[#3E4044]">신용/체크카드</p>
                </div>


                <div class="flex flex-col justify-center items-center">
                    <div class="bg-white rounded-2xl w-32 h-32 flex justify-center items-center">
                        <svg width="58" height="44" viewBox="0 0 58 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.29688 2.46973C3.29688 1.36516 4.19231 0.469727 5.29688 0.469727H52.7067C53.8112 0.469727 54.7067 1.36516 54.7067 2.46973V31.1811H3.29688V2.46973Z" fill="url(#paint0_linear_106_1373)"/>
                            <path d="M3.29706 24.0752H54.6842L57.3999 41.2164C57.5923 42.4306 56.6539 43.5293 55.4245 43.5293H2.57301C1.34437 43.5293 0.406226 42.4319 0.597357 41.2182L3.29706 24.0752Z" fill="url(#paint1_linear_106_1373)"/>
                            <rect x="36.501" y="4.33203" width="12.2845" height="12.2845" rx="1" fill="#96A2B9"/>
                            <rect x="8.24561" y="17.5293" width="22.1722" height="2.56415" rx="1" fill="#96A2B9"/>
                            <rect x="8.24561" y="5.87695" width="20.5911" height="2.56415" rx="1" fill="#96A2B9"/>
                            <rect x="8.24561" y="9.84277" width="11.9381" height="2.56415" rx="1" fill="#96A2B9"/>
                            <defs>
                            <linearGradient id="paint0_linear_106_1373" x1="54.1821" y1="31.1811" x2="4.00142" y2="4.01313" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#96ABD2"/>
                            <stop offset="1" stop-color="#D8E5FF"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_106_1373" x1="75.125" y1="50.1392" x2="23.3549" y2="2.94418" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#D8E6FF"/>
                            <stop offset="1" stop-color="#9DB1D7"/>
                            </linearGradient>
                            </defs>
                        </svg>

                    </div>
                   <p class="mt-6 text-[#3E4044]">직거래</p>
                </div>
            </div>

        </div>

    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import useIntersectionObserver from '@/utils/v2IntersectionObserver';

export default defineComponent({
    name:'SectionPayment',
    setup(){
        const elClass = ref('opacity-0 translate-y-10');
        const observerOptions = {
            root:null,
            rootMargin : '0px',
            threshold : 0.2
        };
        const {elementRef : elRef , isVisible : isVisible} = useIntersectionObserver(observerOptions);

        const updateVisibility = (isVisible , elClass)  =>{
            elClass.value = isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        };

        watch(isVisible, newVal => updateVisibility(newVal, elClass));

        return {
            elRef,
            elClass,
        }
    }

});
</script>

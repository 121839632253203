import { watch, onMounted, onUnmounted, Ref } from "vue";

const useBodyScrollLock = (isLocked: Ref<boolean>) => {
  const setBodyScroll = (isHidden: boolean) => {
    if (isHidden) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  };

  watch(isLocked, (newValue) => {
    setBodyScroll(newValue);
  });

  onMounted(() => {
    if (isLocked.value) {
      setBodyScroll(true);
    }
  });

  onUnmounted(() => {
    setBodyScroll(false);
  });
};

export default useBodyScrollLock;

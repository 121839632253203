<template>
  <div >
    <!-- 네비게이터 높이 만큼 줌. -->
    <NaviHeight/>
    <CarouselSlide/>
    <!-- <SearchBar/> -->

    <NewAdopted/>
    <!-- <PopularSpecies/> -->
    <CommunityPost/>
    <InformationSlide/>
    <PhoneMockup/>
  </div>
</template>

<script lang="ts">
import NaviHeight from '@/components/Navigator/NaviHeight.vue';
import CarouselSlide from '@/components/Main/CarouselSlide.vue'
import NewAdopted from '@/components/Main/NewAdopted.vue';
import CommunityPost from '@/components/Main/CommunityPost.vue';
import InformationSlide from '@/components/Main/InformationSlide.vue';
// import SearchBar from '@/components/Main/SearchBar.vue'
// import PopularSpecies from '@/components/Main/PopularSpecies.vue'
import PhoneMockup from '@/components/Main/PhoneMockup.vue'

export default {
  components: {
    NaviHeight,
    CarouselSlide,
    NewAdopted,
    CommunityPost,
    InformationSlide,
    // SearchBar,
    // PopularSpecies,
    PhoneMockup,
  },
  // created() {
  //   document.title = this.$route.name // 라우터에서 title 이미지 변경 가능
  // }
}
</script>
<style>


</style>
  
import { ref , watch} from "vue";

// const storedUserInfoString = localStorage.getItem('userInfo');
// // JSON 문자열을 객체로 변환
// const storedUserInfo = JSON.parse(storedUserInfoString);

// export const getUserId = () => {
//     const user_id =  storedUserInfo.user_id
//     // const user_id =  window.localStorage.getItem('user_id')
//     return user_id;
// }
// export const getUserNickname = () => {
//     const nickname =  storedUserInfo.nickname
//     // const nickname =  window.localStorage.getItem('nickname')
//     return nickname;
// }
const fetchStoredUserInfo = () => {
    const storedUserInfoString = localStorage.getItem('userInfo');
    if (!storedUserInfoString) {
        console.error('User info is not stored.');
        return null;
    }

    try {
        // Base64 디코딩과 URL 디코딩을 추가
        const decodedData = decodeURIComponent(atob(storedUserInfoString));
        return JSON.parse(decodedData);
    } catch (error) {
        console.error('Failed to parse user info:', error);
        return null; // 파싱 실패 시 null 반환
    }
};

const storedUserInfo = fetchStoredUserInfo();

// 사용자 ID와 닉네임을 가져오는 함수
export const getUserId = () => storedUserInfo?.user_id;
export const getUserNickname = () => storedUserInfo?.nickname;


export const accessToken = ref(window.localStorage.getItem('accessToken') || null);

watch(accessToken, (newToken) => {
    window.localStorage.setItem('accessToken', newToken);
});

export const isTokenExpired = (token) => {
    // 토큰을 점(.)을 기준으로 나눔
    const splittedToken = token.split('.');
    if (splittedToken.length !== 3) return true; // 유효하지 않은 토큰 형식

    // Payload 부분(Base64 인코딩된 JSON)을 디코딩
    const payload = JSON.parse(atob(splittedToken[1]));
    
    // 현재 시간과 토큰의 만료 시간을 비교
    const now = Math.floor(Date.now() / 1000); // 현재 시간을 Unix 타임스탬프로 변환
    return now >= payload.exp; // 만료 시간이 현재 시간보다 이전이면 true (만료됨)
}

export const isTokenValid = () => {
    const token =  accessToken.value
    if (!token) return false; // 토큰이 없으면 유효하지 않음

    return !isTokenExpired(token); // 토큰이 만료되지 않았으면 유효함
};

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "-mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopNavigator = _resolveComponent("TopNavigator")!
  const _component_NaviHeight = _resolveComponent("NaviHeight")!
  const _component_SectionHero = _resolveComponent("SectionHero")!
  const _component_MarginSpace = _resolveComponent("MarginSpace")!
  const _component_SectionAdopt = _resolveComponent("SectionAdopt")!
  const _component_SectionFilter = _resolveComponent("SectionFilter")!
  const _component_SectionPayment = _resolveComponent("SectionPayment")!
  const _component_SectionManage = _resolveComponent("SectionManage")!
  const _component_SectionChart = _resolveComponent("SectionChart")!
  const _component_SectionChat = _resolveComponent("SectionChat")!
  const _component_SectionCommunity = _resolveComponent("SectionCommunity")!
  const _component_SectionInfo = _resolveComponent("SectionInfo")!
  const _component_Section9Footer = _resolveComponent("Section9Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopNavigator),
    _createVNode(_component_NaviHeight),
    _createVNode(_component_SectionHero),
    _createVNode(_component_MarginSpace, { marginSpace: "10rem" }),
    _createVNode(_component_SectionAdopt),
    _createVNode(_component_SectionFilter),
    _createVNode(_component_MarginSpace, { marginSpace: "10rem" }),
    _createVNode(_component_SectionPayment),
    _createVNode(_component_MarginSpace, { marginSpace: "10rem" }),
    _createVNode(_component_SectionManage),
    _createVNode(_component_MarginSpace, { marginSpace: "0rem" }),
    _createVNode(_component_SectionChart),
    _createVNode(_component_MarginSpace, { marginSpace: "10rem" }),
    _createVNode(_component_SectionChat),
    _createVNode(_component_MarginSpace, { marginSpace: "10rem" }),
    _createVNode(_component_SectionCommunity),
    _createVNode(_component_MarginSpace, { marginSpace: "10rem" }),
    _createVNode(_component_SectionInfo),
    _createVNode(_component_MarginSpace, { marginSpace: "10rem" }),
    _createVNode(_component_Section9Footer)
  ]))
}
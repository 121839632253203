const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export function encodeBase62(numericId) {
    const base = characters.length;
    let encodedId = '';

    do {
        const remainder = numericId % base;
        encodedId = characters.charAt(remainder) + encodedId;
        numericId = Math.floor(numericId / base);
    } while (numericId > 0);
    return encodedId;
}

export function decodeBase62(encodedId) {
    const base = characters.length;
    let numericId = 0;

    for (let i = 0; i < encodedId.length; i++) {
        const char = encodedId.charAt(i);
        const position = characters.indexOf(char);
        if (position === -1) {
            console.error('Invalid character found in encoded ID.');
            return null;
        }
        numericId = numericId * base + position;
    }

    return numericId;
}

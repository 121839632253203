<template>
    <div class="w-full h-80  flex justify-center items-center">
      <div class="text-center space-y-1">
        <img src="@/assets/img/logo/errorRep.svg" alt="" class=" pl-10 mb-5">
        <p class="font-medium text-lg">검색결과가 없습니다.</p> 
        <p class="text-[#A4A4A4] text-sm">다른 키워드로 검색해보세요</p>

      </div>
    </div>
  </template>

<script lang="ts" setup>

</script>

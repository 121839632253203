<template>
    <div v-if="isVisible" class="h-10 w- sticky bottom-20 pr-4 justify-end flex items-center">
        <button 
            class="w-10 h-10 bg-zinc-50 rounded-full justify-center flex items-center shadow-lg"
            @click="handleBtn"

        >
        <img src="@/assets/img/logo/arrow.svg" alt="" class="rotate-90">
        </button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isVisible: false, // 처음에는 버튼이 보이지 않음
        };
    },
    methods: {
        handleBtn() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        checkScroll() {
            this.isVisible = window.scrollY > 2200; // 스크롤이 200px 이상 내려가면 버튼 표시
        }
    },
    mounted() {
        window.addEventListener('scroll', this.checkScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.checkScroll);
    }
}
</script>

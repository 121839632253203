<template >
    <div class=" mx-auto w-full  fixed top-0 left-0 right-0  justify-center items-center h-16 bg-white z-50 shadow-sm  " style="z-index: 500;"  >
        <div class="mx-auto h-16 flex items-center  lg:px-0 lg:w-[1024px] w-full px-4  " >
            <router-link class=" flex justify-center items-center w-16 h-9 text-xs  mr-8  " :to="'/'"> 
                <img src="@/assets/img/logo/logo.svg" alt="" class="w-full">
            </router-link>
            <router-link :to="'/reptile'" class=" ">
                <div class="justify-center items-center w-full pt-2 h-8 text-base  flex font-semibold text-[#3d3d3d] hover:text-zinc-400 mr-6 sm:mr-4 " 
                @click="goToReptile">
                    <span class="notHere" :class="{ 'here' : whereIam('/reptile') }">분양</span>
                </div>
            </router-link>
            <router-link :to="'/community'">
                <div class="justify-center items-center w-full pt-2 h-8 text-base  flex font-semibold text-[#3d3d3d] hover:text-zinc-400 mr-6 sm:mr-4">
                    <span class="notHere" :class="{ 'here' : whereIam('/community') || whereIam('/qna') || whereIam('/gallery')  }">커뮤니티</span>
                </div>
            </router-link>
            <router-link :to="'/news/event'">
                <div class="justify-center items-center w-full pt-2 h-8 text-base  flex font-semibold text-[#3d3d3d] hover:text-zinc-400 mr-6 sm:mr-4">
                    <span class="notHere" :class="{ 'here' : whereIam('/news') }">정보</span>
                </div>
            </router-link>



            <div class="relative hidden md:flex ml-auto flex-col ">
                <input 
                    class="searchInput  w-64 bg-[#F5F7F9] h-9 rounded-3xl ml-auto mr-4 px-4 placeholder:text-xs text-xs" 
                    placeholder="파충류 종을 검색해봐요!" 
                    @input="handleSearch(($event.target as HTMLInputElement).value)"
                    @focus="isFocused = true" 
                    @blur="isFocused = false"
                    v-model="searchTitle"
                    @keypress.enter="searchBtn" 
                />

                <button class="absolute right-7 bottom-[10px]  w-4 h-4 flex items-center " @click="searchBtn" :disabled="!selectedItem(searchTitle)">
                    <img class="w-full h-full object-cover" src="@/assets/img/logo/searchIcon.svg" alt="Search"/>
                </button>
                <ul class="absolute top-12 bg-zinc-50 w-56 max-h-28 left-3 overflow-y-scroll text-xs" v-if="isFocused">
                    <li v-for="item in autoCompleteArr" :key="item" @mousedown="clickCompleteList(item)"
                    class="px-2 py-2 cursor-pointer hover:bg-zinc-200">
                        {{ item }}
                    </li>

                </ul>
            </div>

            <!-- <router-link  :to="'/main'"> 
                <div class="w-28 border-pada border border-solid h-9 rounded-3xl flex justify-center items-center text-xs font-medium text-pada mr-4 hidden md:flex">APP 다운로드</div>
            </router-link> -->

            <div class="relative hidden md:flex">
                <div v-if="loggedIn" class="relative">
                    <!-- 토큰이 유효한 경우에 표시될 컨텐츠 -->
                    <ProfileDiv/>
                </div>
                <div v-else >
                <!-- <div v-else class="flex justify-center items-center  pr-4 rounded-full border border-solid border-zinc-100"> -->
                    <!-- 토큰이 만료되었거나 없는 경우 -->
                    <GoogleLogin/> 
                    <!-- <div >
                        로그인
                    </div> -->

                </div>
            </div>
        
        </div>
        <div v-if="welcome">
            <agreeBox @closePopup="handlePopup"/>
        </div>
    </div>
</template>
<script lang="ts" setup>
import {ref, computed,  onMounted} from 'vue';
import { useStore } from 'vuex';
import GoogleLogin from '../Main/GoogleLoginBtn.vue';
import ProfileDiv from '../UI/ProfileDiv.vue';
import { isTokenValid } from '@/utils/Functions';
import { useRoute, useRouter } from 'vue-router';
import { initFilter } from '@/utils/findCategory';
import { debounce } from 'lodash';
import agreeBox from '../UI/agreeBox.vue';


const store = useStore();
const router = useRouter();
const route = useRoute();
const searchTitle = ref('');
const compareList = ['크레스티드 게코', '가고일 게코', '리키에너스 게코', '차화 게코', '레오파드 게코', '납테일 게코', '펫테일 게코', '토케이 게코', 
'비어디 드래곤', '모니터/테구', '스킨크', '이구아나', '기타 도마뱀', '볼파이톤', '파이톤/보아', '킹/밀크스네이크', '호그노즈', '콘스네이크', '수생/반수생 거북', '습지 거북', '육지 거북', '테라핀',
'뉴트', '살라만다', '토드', '프록', '등각류', '타란튤라', '센티페드', '전갈', '밀리페드', '사육장', '먹이', '기타 용품', '도서' ];
const autoCompleteArr = ref(compareList);

const welcome = computed(()=> store.state.welcome)
const handlePopup = () => {
    store.commit('IS_WELCOME', false)
}
const loggedIn = computed(() => isTokenValid());
const isFocused = ref(false)
const whereIam = (path) => {
    return route.path.includes(path)
}
const handleSearch = debounce((value) => {
    const lowerCaseValue = value.toLowerCase();
    const result = compareList.filter((c) => c.toLowerCase().includes(lowerCaseValue));
    autoCompleteArr.value = result;
}, 150); 
const goToReptile = () =>{
    store.commit('SET_CURRENT_STATUS', 0)
    store.commit('SET_CURRENT_LIST', store.state.reptileList)
    initFilter(store);
    router.push('/reptile');
}
const selectedItem = (item) => {
    if(compareList.find((c) => c === item)){
        return true
    } else {
        return false;
    }
}
const clickCompleteList = (value) => {
    searchTitle.value = value;
}


const searchBtn = async () => {
    if(!searchTitle.value) {
        return ;
    }
    initFilter(store);
    store.commit('SET_SPECIES_STATUS', searchTitle.value)
    // store.commit('SET_SPECIES_STATUS', null)
    await store.dispatch('fetchFilterData');
    router.push('/reptile')
    searchTitle.value= null;
    // console.log(searchTitle.value)
}
// 검색창으로 title 검색하기 
// const searchBtn = async () => {
//     if(!searchTitle.value) {
//         return ;
//     }
//     initFilter(store);
//     store.commit('SET_KEYWORD_STATUS', searchTitle.value)
//     store.commit('SET_SPECIES_STATUS', null)
//     await store.dispatch('fetchFilterData');
//     router.push('/reptile')
//     searchTitle.value= null;
//     // console.log(searchTitle.value)
// }



const deleteBtn = () => {
    searchTitle.value = '';
}



</script>

<style scoped >

.btn {
    @apply  justify-center items-center w-full  h-8 text-base pt-2 z-0 font-semibold text-[#3d3d3d] hover:text-zinc-400
}
.btnv2 {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2rem;
    padding-top: 0.5rem;
    z-index: 0;
    font-weight: 600;
    color: #3d3d3d;
    margin-right: 1rem;

}

.searchInput:focus::placeholder{
    color:transparent;
    
}
.searchInput:focus{
    background-image: none;
    font-size: 0.8rem;

}
.here {
    /* border-bottom: 0.2rem; */
    /* border-color: #00D191; */
    color: #00d191;
    /* border-style: solid; */
    /* padding-bottom: 0.2rem; */
    padding: 0 0.5rem 0.3rem;

}
.notHere {
    padding: 0 0.5rem 0.3rem;
}
.naviCalc {
    /* width: calc(100% - 2rem); */
    width: 1024px;

}
@media (max-width : 768px) {
    .naviCalc{
        width: calc(100% - 4rem);
    }        
}
.naviCalc {
    /* width: calc(100% - 2rem); */
    width: 1024px;

}
@media (max-width : 768px) {
    .test{
        width: calc(100% - 2rem);
    }        
}
</style>
export function generateNickname(): string {
    const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    
    const randomLength = 7 ;
    const frontChar = '크레';
    let randomSuffix = '';
    for (let i = 0; i < randomLength; i++) {
      randomSuffix += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    
    const randomNickName = frontChar + "#" + randomSuffix
  
    return randomNickName;
  }
  
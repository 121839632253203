<template>
    <div class="fixed top-0 left-0 w-screen h-screen flex justify-center items-center" style="z-index: 990;">
        <!-- 딤드 배경 -->
        <div class="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>

        <div class="sm:w-[320px] w-8/12 sm:h-52 h-40 bg-white flex flex-col justify-center items-center text-lg rounded-[10px] mb-20 "
            style="z-index: 999; box-shadow: 0px 0px 20px rgba(8, 50, 25, 0.12);">
            <img src="@/assets/img/logo/alert.svg" alt="alert" class="sm:w-6 w-4 sm:mb-[10px] ">
            <div class="justify-center items-center text-center sm:mb-3 mb-1 h-14 flex">
                <p class="whitespace-pre-wrap text-[15px] sm:text-lg    leading-[22px]">{{ props.content1 }} </p>
            </div>

            <div class="flex  sm:w-11/12 sm:mt-[2px] mt-0 w-11/12 px-4">
                <button v-if="props.okayButton" class="bg-pada w-full  h-10 text-base font-medium rounded-md text-white mr-4" @click="updateOkay">
                    {{ props.okayButton }}
                </button>
                <button class="w-full h-10 font-medium rounded-md text-white sm:text-base text-sm" @click="emitToParent" :class="{'bg-pada': !props.okayButton, 'bg-zinc-100 text-zinc-700': props.okayButton}">
                    {{ props.closeButton }}
                </button>


            </div>

        </div>
    </div>
</template>
<script lang="ts" setup>
import { ref, defineEmits, defineProps } from 'vue';
const props = defineProps({
    content1: {
        type: String,
        required: true,
    },

    closeButton: {
        type: String,
        required: true,
    },
    okayButton: {
        type: String,
    }
})
const emit = defineEmits(['updateClose', 'updateOkay']);

// 선택된 값이 변경될 때 부모 컴포넌트에 알림
const emitToParent = () => {
    emit('updateClose');
};
const updateOkay = () => {
    emit('updateOkay')
}
</script>

<template>
        <div class="w-full h-32  mt-20  justify-between space-x-8 hidden sm:flex" >
            <div class="w-1/2 flex border border-solid border-[#D8D9DC]  items-center rounded-md px-8">
                <div class="">
                    <p class="  font-bold text-[15px]">휴대전화로 QR찍고<br> 파충류 분양해보세요📦</p>
                        <div class="flex items-center  mt-2">
                        <p class="text-sm font-medium text-sub mr-1">갤럭시 구글스토어  </p>
                        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.16602 4.99996H12.8327M12.8327 4.99996L9.49935 8.33329M12.8327 4.99996L9.49935 1.66663" stroke="#646669" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"
                            />
                        </svg>
                    </div>
                </div>

                <div class=" ml-auto ">
                    <img src="@/assets/img/logo/QR_google.png" alt="" class="" >
                </div>

            </div>
            <div class="w-1/2 flex border border-solid border-[#D8D9DC]  items-center rounded-md px-8">
                <div class="">
                    <p class="  font-bold text-[15px]">휴대전화로 QR찍고<br> 파충류 분양해보세요📦</p>
                    <div class="flex items-center  mt-2">
                        <p class="text-sm font-medium text-sub mr-1">아이폰 앱스토어  </p>
                        <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.16602 4.99996H12.8327M12.8327 4.99996L9.49935 8.33329M12.8327 4.99996L9.49935 1.66663" stroke="#646669" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"
                            />
                        </svg>
                    </div>

                </div>

                <div class=" ml-auto ">
                    <img src="@/assets/img/logo/QR_apple.png" alt="" class="" >
                </div>

            </div>


        </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({

});
</script>

<style>

</style>



<template >
    <div v-if="isLoading">
        <div class="w-[768px] min-h-screen pt-40 justify-center items-start flex"><LoadingCircle/></div>
    </div>
    <div v-else class="sm:w-[768px] w-full min-h-screen pt-4" >
        <!-- <div class="mb-4 font-bold text-lg justify-between flex">
            <div @click="backBtn" class="cursor-pointer  w-4"> <img src="@/assets/img/logo/backBtn.svg" alt=""> </div>    
        </div> -->

        <div class="flex justify-between w-full ">
            <!-- <h1 class="text-xl font-medium mb-6">{{ postItem.title }}</h1> -->
            <div class="flex items-center mb-4">
                <div class="w-10 h-10 mr-2 rounded-full">
                    <img :src="postItem.img !== 'b' ? postItem.img : require('@/assets/img/logo/defaultProfile.svg')" alt="" class="w-full h-full rounded-full object-cover">

                </div>
                {{ postItem.name }}
            </div>

        </div>
        <h1 class="text-xl font-medium my-4">{{ postItem.title }}</h1>
        <h2 class="text-lg font-semibold tracking-wide"></h2>
        
        <div class="border-zinc-100 border-t border-solid w-full h-1 my-8"></div>
        <p class="whitespace-pre-wrap text-sub text-sm my-6 w-full">{{ postItem.content }}</p>
        <p class="text-end text-sub text-sm">     {{ timeForToday(postItem.create_at) }}</p>
   
        <div class="w-full mt-4">
            <img :src="postItem.imgURL1" alt="" class="w-full rounded-lg">
            <div class="bg-zinc-50 w-full h-1 my-8"></div>
        </div>
        <p class="text-sm font-medium">댓글 <span class="text-sub font-normal">{{ postItem.reply_cnt }}개</span></p>
        <div class="mt-8 text-sm  text-sub">
            댓글을 보시려면 파충류 다 있다(파다) 앱을 다운로드해보세요!
        </div>
        <div class="border-zinc-100 border-solid w-full h-1 my-8"></div>
        
        <div class="border border-solid border-[#d8d9dc] text-sub w-20 h-8 text-xs justify-center flex items-center rounded-md mt-4 cursor-pointer"
        @click="backBtn">
        
           <span class="mr-1"> &lt;</span> 목록으로
        </div>
        <GoQRcode/>
    <div class="w-full h-20"> </div>
            


    </div>
</template>
<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { timeForToday } from '@/utils/timeForToday';
import { formattedPrice,  } from '@/utils/formattedUnit';
import { useRouter, useRoute } from 'vue-router';
import LoadingCircle from '../UI/LoadingCircle.vue';
import GoQRcode from '../UI/GoQRcode.vue';
const route = useRoute();

const store = useStore();
const router = useRouter();
const postId = route.params.id

const postItem = computed(() => store.state.clickedPost || []);
const isLoading = computed(() => store.state.isLoading);
const backBtn = () => {
    router.go(-1)
}


onMounted(async () => {
    await store.dispatch('viewPost', postId);

})

</script>
<style >
    
</style>
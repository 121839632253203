<template>
    <div class="w-full overflow-scroll lg:overflow-hidden h-auto overflow-x-hidden top-0 left-0 bg-white  lg:relative  "  
    >
        <div class="font-thin text-4xl w-full flex lg:hidden justify-end px-6 my-4" @click="closeBtn">&times;</div>
        <div class="border-[#A3A7B0] border border-solid cursor-pointer text-sm lg:flex justify-center items-center w-full h-11 font-bold rounded-md text-[#646669] hidden "  
        @click="goWrite">
        <img src="@/assets/img/logo/bucket.svg" alt="" class="w-5 mr-1">
        분양글 쓰기
        </div>
        <div class="w-full pb-4 border-solid border-t border-[#f2f4f5] lg:block hidden"></div>
        <h3 class="font-semibold text-heading text-[16px] p-3 rounded-xl bg-[#f9f9f9] w-11/12 mx-auto lg:w-full flex"> <span class="mr-2">🔍</span> 개체 종</h3>
        <ul class="flex flex-col mt-4 h-auto min-h-screen">

            <li v-for="(item, i) in category" :key="i" class=" flex flex-col space-y-5 cursor-pointer " >
                <div class="flex-row flex justify-between pl-8 pr-4 lg:pr-0  " :class="{ 'hover-item': !item.toggle }" @click="handleToggle(item)">
                    <div class=" text-def font-medium text-[16px] " >{{ item.category }}</div>

                    <button class="px-4">
                        <img src="@/assets/img/logo/arrow.svg" alt="arrowBtn"
                            :style="{ transform: item.toggle ? 'rotate(90deg)' : 'rotate(-90deg)', transition: 'all .3s' }">
                    </button>

                </div>
                <ul v-show="item.toggle" class="ps-8  text-[15px]" >
                    <li class="hover:translate-x-2 transition duration-200  py-2" 
                    :class="selectedJong === item.category ?  'text-[#17181A] font-semibold ' : 'text-[#84888C]' "
                        @click="setTotal(item.category)">전체보기 </li>

                    <li v-for="(specices, idx) in item.species" :key="idx"
                        class="hover:translate-x-2 transition duration-200  py-2" 
                        :class="selectedSpecies === specices ?  'text-[#17181A] font-semibold ' : 'text-[#84888C]' ">
                        <p @click="setSpecies(specices)"> {{ specices }}</p>
                    </li>
                </ul>
                <div class="w-full pb-4 border-solid border-t border-[#f2f4f5] "></div>
            </li>
        </ul>
    </div>
    <div v-if="isOpen" > 
        <ConfirmModal :content1="loginMsg" @updateClose="closeModal" closeButton="닫기" />
    </div>

</template>
<script lang="ts" setup>
import { ref, computed } from 'vue';
import categoryData from '@/assets/data/category.json';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { getUserId, isTokenExpired, accessToken } from '@/utils/Functions';
import ConfirmModal from '../UI/ConfirmModal.vue';

interface Item{
    toggle : boolean;
}

const store = useStore();
const category = ref(categoryData);
const router = useRouter();
const loginMsg = ref('');
const isOpen = ref(false);
const selectedJong = ref('');
const handleMobileSpecies = computed(() => store.state.handleMobileSpecies)

const handleToggle = (item :Item) => {
    item.toggle = !item.toggle;
}
  const resetValues = {
    price: '',
    location: '',
    gender: '',
    size: '',
    city : '',
    town : '',
    toggle : false,
  };
  store.commit('SET_STATUS_FILTER', resetValues);
const selectedSpecies = computed(()=> store.state.selectedSpecies);
// 종 선택하고, 리스트 변경하기
const setSpecies = async (specice:string) => {
    selectedJong.value = null
    store.commit('SET_SPECIES_STATUS', specice);
    store.commit('SET_MORPH_STATUS',[]);
    store.commit('SET_NO_RESULT', false);
    store.commit('SET_KEYWORD_STATUS', null);
    store.commit('SET_STATUS_FILTER', resetValues);
    store.commit('SET_TEMPORARY_FILTER', resetValues);

    if(!store.state.categories[specice]){
        await store.dispatch('fetchCategoryData', specice);
    }
    store.commit('SET_CURRENT_LIST', store.state.categories[specice]?.data || []);
    store.commit('SET_CURRENT_STATUS', 1);
    store.commit('SET_MOBILE_SPEICES_MODAL', false);
    router.push('/reptile')
}

const setTotal = async (jong) => {
    selectedJong.value = jong
    // console.log(jong)
    store.commit('SET_SPECIES_STATUS', jong);
    store.commit('SET_MORPH_STATUS',[]);
    store.commit('SET_NO_RESULT', false);
    store.commit('SET_KEYWORD_STATUS', null);
    store.commit('SET_STATUS_FILTER', resetValues);
    store.commit('SET_TEMPORARY_FILTER', resetValues);

    if(!store.state.categories[jong]){
        await store.dispatch('fetchHamburgerData', jong);
    }
    store.commit('SET_CURRENT_LIST', store.state.categories[jong]?.data || []);
    store.commit('SET_CURRENT_STATUS', 3);
    store.commit('SET_MOBILE_SPEICES_MODAL', false)
    router.push('/reptile')
}
const goWrite = () => {
    if (accessToken.value === null) {
        loginMsg.value = '로그인을 해주세요';
//         loginMsg.value = 
// `세션이 만료되었습니다. 
// 다시 로그인해주세요.`;
        isOpen.value = true;
        return;
    }
    if (isTokenExpired(accessToken.value)) {
        loginMsg.value = 
`세션이 만료되었습니다. 
다시 로그인해주세요.`;
        isOpen.value = true;
        return;
    }
    router.push('/reptile/write');
};
const closeModal = () => {
    // isOpen.value = !isOpen.value
    isOpen.value = false;
}   
const closeBtn = () => {
    store.commit('SET_MOBILE_SPEICES_MODAL', false)
}
</script>
<style scoped>

</style>



<template>
    <div class="w-full flex flex-col cursor-pointer " ref="popupRef">
        <div class="w-full h-full flex justify-end items-center  rounded-full" @click="handleOpen">
            <div class="w-12 h-12 flex justify-center items-center hover:bg-zinc-100 rounded-full transition-all duration-300">
                <img :src="img" :alt="nickname" class="w-8 h-8 rounded-full">
            </div>
            <div 
            class="w-72 h-36 px-6 py-4 flex-col bg-zinc-50 rounded-3xl absolute top-16 -right-12 flex text-xs transition-all duration-1000 glass"
            v-if="isOpen"

            >
            <div class=" flex w-full items-start p-2 rounded-full">
                <img :src="img" :alt="nickname" class="w-12 h-12 rounded-full">
                <div class="ml-4 ">
                    <div class="h-12 flex flex-col justify-center">
                        <p>{{ nickname }}</p> 
                    <p class="text-sm font-medium">{{ userEmail }}</p> 
                    </div>
                    {{ remainTime }}
                    <button class="flex w-20 justify-center text-xs hover:bg-zinc-300 rounded-full p-2  mt-1"
                    @click="logout">로그아웃</button>
                </div>
            </div>


            


            </div>
        </div>


    </div>
</template> 

<script setup>
import { onMounted,  ref, onUnmounted, } from 'vue';
import { accessToken, getUserId } from '@/utils/Functions';
import { useRouter } from 'vue-router';
import { getTokenRemainingTime } from '@/utils/parseJwt';
// 사용자 ID와 닉네임을 가져오는 함수

const img = ref('');
const nickname = ref('');
const userEmail = ref('');
const isOpen = ref(false);
const remainTime = ref(getTokenRemainingTime(accessToken.value));

const handleOpen = () =>{
    isOpen.value = !isOpen.value
}
const popupRef = ref(null);
const router = useRouter();
const handleClickOutside = (event) => {
    // 여기서 `refElement`는 팝업이나 모달 창을 가리키는 ref입니다.
    // 예: const refElement = ref(null);
    if (popupRef.value && !popupRef.value.contains(event.target)) {
        isOpen.value = false;
    }
};

onMounted(() => {
    // document에 클릭 이벤트 리스너 추가
    document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    document.removeEventListener('click', handleClickOutside);
});
// const getUserInfo = (  ) => {
//     nickname.value = window.localStorage.getItem('nickname');
//     img.value =  window.localStorage.getItem('img');
//     if(img.value === 'b'){
//         img.value = require("@/assets/img/logo/defaultProfile.svg")
//     }
//     userEmail.value = window.localStorage.getItem('user_id')
    
// }
const getUserInfo = () => {
    // 로컬 스토리지에서 userInfo 항목을 가져옴
    const userInfoString = window.localStorage.getItem('userInfo');
    // JSON 문자열을 객체로 파싱
    let userInfo = null;
    try {
        const decodedData = decodeURIComponent(atob(userInfoString));
        userInfo = JSON.parse(decodedData);
    } catch (error) {
        console.error('Failed to parse user info:', error);
        // 파싱에 실패한 경우, userInfo를 빈 객체로 초기화
        userInfo = {};
    }

    // userInfo 객체에서 필요한 값을 추출하고, 기본값 설정
    nickname.value = userInfo.nickname ;
    userEmail.value = userInfo.user_id ;
    // img 값에 따라 기본 이미지 또는 지정된 이미지를 설정
    img.value = userInfo.img !== 'b' ? userInfo.img : require("@/assets/img/logo/defaultProfile.svg");
    getUserId();
};
const logout = () => {
    accessToken.value = null;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userInfo')
    // localStorage.removeItem('user_id');
    // localStorage.removeItem('nickname');
    // localStorage.removeItem('img');
    router.push('/')
}

// function signOut() {
//           var auth2 = gapi.auth2.getAuthInstance();
//           auth2.signOut().then(function () {
//             console.log('User signed out.');
//           });
//         }
onMounted(() => {
    getUserInfo();
})



</script>

<style scoped>
/* .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8  {
    opacity: 0;
} */
.el_class{
    transform: translateY(2.5rem) ;
    transition: opacity .5s;
}
.slide-enter-active, .slide-leave-active {
    transition: top 1s;
}
.slide-enter, .slide-leave-to {
    top: -2.5rem; /* 시작 상태. 엘리먼트가 화면 밖에 위치하도록 설정 */
}
.glass{
    background: rgba( 255, 255, 255, 0.7 );
/* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
border-radius: 10px;
}

</style>
<template>
    <div class="flex flex-col w-full bg-blue-0">
        <div class="mb-4 font-bold text-lg justify-between md:mt-0 mt-4 bg-red-5">
            <h1 class="pl-2">{{ categoryNumber() ===1 ? '자유게시판' : '질문&답변' }}</h1>    

        </div>
        <!-- <div v-else class="sm:mt-0 mt-4"></div> -->

        <ul class="container  sm:w-[768px] w-full sm:px-8 sm:py-8 px-4 py-2 flex flex-col min-h-screen">
            <li v-for="item in rst" :key="item.id" class="relative  "  >
                <div class="w-full sm:h-40 h-auto cursor-pointer bg-red-0 py-4" @click="postDetail(item)">
                    <div class=" sm:w-9/12 w-8/12 h-full flex flex-col space-y-2  bg-red-0">
                        <p class="text-sub font-medium sm:text-sm text-xs">{{ item.name }} <span class="sm:ps-4 ps-2">{{ timeForToday(item.create_at) }}</span></p>
                        <p class="font-medium text-sm sm:text-base">{{ item.title }}</p>
                        <div class="sm:text-sm text-xs text-[#838383] h-auto sm:line-clamp-2 line-clamp-1 w-full sm:w-full bg-red-0">{{ item.content }}</div>

                        <div class=" text-xs text-sub flex space-x-2  pt-0">
                            <img src="@/assets/img/logo/like.svg" alt="" class="mr-1" >  {{ item.like_cnt }}
                        </div>
                    </div>

                    <div v-if="item.imgURL1" class=" sm:flex">
                        <div class="w-24 h-24 sm:w-36 sm:h-36 absolute top-4 sm:top-0 right-0 ">
                            <img :src="item.imgURL1" alt="" class="w-full h-full object-cover rounded-xl bg-zinc-100">
                        </div>
                    </div>

                </div>

                <div class="w-full border-solid border-b border-zinc-100 sm:my-4 my-1"></div>

            </li>
            <ScrollTopBtn/>
        </ul>
        <div v-if="isBoardLoading" class="w-full justify-center items-center flex my-10"><LoadingCircle/></div>

        <div v-else class="w-full h-32 flex justify-center items-center">
            <button @click="loadMore" class="w-40 h-10 rounded-full border-zinc-500 border border-solid">더보기</button>
        </div>

    </div>
</template>
<script lang="ts" setup>
import {  ref, watch, onMounted, computed} from 'vue';
import LoadingCircle from '@/components/UI/LoadingCircle.vue'
import { timeForToday } from '@/utils/timeForToday';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import ScrollTopBtn from '@/components/UI/ScrollTop.vue'

interface Item {
    id: number;
    title: string;
    name: string;
    imgURL1: string;
    content: string;
    price: number;
    create_at: string;
    location: string;
    like_cnt: number;
}
const clickedItem = ref<Item | null>(null); 
const store = useStore();
const router = useRouter();
const route = useRoute();
const isBoardLoading = ref(false);
const categoryNumber = () => {
    if(route.path.includes('community')) return 1;
    if(route.path.includes('qna')) return 2 ;
    if(route.path.includes('gallery')) return 3;

}



const rst = computed(()=> store.state.currentCommunityList)


const loadMore = async () => {
    isBoardLoading.value = true;
    await new Promise<void>(resolve => {
            setTimeout(() => {
                store.dispatch('fetchCommData', categoryNumber());
                resolve();
            }, 2100);
        });
      isBoardLoading.value =false;

};


onMounted(() => {
    store.dispatch('fetchCommData', categoryNumber());
})

// 상세보기 
const postDetail = (item :Item) => {
    clickedItem.value = item;
    router.push({ path: `community/${item.id}` });
};


</script>


<style scoped>
.container {
    box-shadow: 0px 0px 10px rgba(8, 50, 25, 0.06);
    border-radius: 10px;

}



</style>
<template>
    <div class="fixed top-0 left-0 w-screen h-screen flex justify-center items-center" style="z-index: 990;">
      <!-- 딤드 배경 -->
      <div class="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
      
      <!-- 모달-->
      <div class="relative min-w-72 md:w-9/12 h-72 bg-white rounded-lg" style="z-index: 999;">
        <div class="w-full mt-7 bg-blue-00 flex justify-center text-lg font-bold"> <p>이벤트 공유하기</p> </div>
        <div class="w-full bg-red-00 h-6/12 flex justify-center  items-center space-x-10 mt">
            <div>
                <div class="w-16 h-16 bg-[#FEE500] rounded-full flex justify-center items-center">
                    <KakaoShare :title="props.title" :loca="props.loca" :date="props.date" :img="props.img"  :link="props.link"/>
                </div>
                <p class="w-full justify-center flex mt-2 text-sm font-medium">카카오톡</p>
            </div>

            <div @click="handlCopy">
                <div class="w-16 h-16 bg-[#E0F9F1] rounded-full flex justify-center items-center">
                    <img src="@/assets/img/logo/clip.svg" alt="" class="w-7 object-cover">
                </div>
                <p class="w-full justify-center flex mt-2 text-sm font-medium">링크공유</p>

            </div>

        </div>
        <div class="w-full bg-green-00 mt-2 justify-center  flex h-4/12">
            <button class="bg-pada w-10/12  h-10 rounded-md text-white font-bold" @click="closeByEmit">닫기</button>
        </div>
      </div>
    </div>
  </template>
<script setup lang="ts">
import { defineEmits , defineProps, ref} from 'vue';
import KakaoShare from './KakaoShare.vue';
const props = defineProps({
  title : {type : String, required : true},
  date : {type : String, required : true},
  loca : {type : String, required : true},
  img : {type : String, required : true},
  link : {type : String, required : true},
});
const emits = defineEmits(['close']);
const closeByEmit = () =>{
    emits('close')
}
// 주소 복사하기 버튼
const linkText = ref(`https://padaapp.com/${props.link}`);
const handlCopy = () => {
    const textToCopy = linkText.value;
    navigator.clipboard.writeText(textToCopy).then(() => {
        alert('링크가 복사되었습니다.')
    })
}

</script>

<style>

</style>
<template>
    <div class="flex flex-col" :class="align">
        <p class="sm:text-2xl text-xl text-pada font-bold">{{ title  }}</p>
        <p class="mt-4 text-def sm:text-3xl text-2xl leading-relaxed font-bold sm:leading-relaxed"> {{ content1 }} <br>
        {{ content2 }}</p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
    name:'titleBox',
    props : {
        title : {
            type: String,
            requried : true,
        },
        content1: {
            type: String,
            requried : true,
        },
        content2: {
            type: String,
            requried : true,
        },
        align :{
            type :String,
            default: '' 
        }
    }

});
</script>

<style>

</style>
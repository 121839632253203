import { createStore } from "vuex";
import { BoardApi, SearchApi } from "./api/api";
import { ReptileList, State, SearchParams } from "./types/type";
import { formattedPriceRange, genderToText } from "./utils/formattedUnit";
import { encodeBase62 } from "./utils/idConverter";


const store = createStore<State>({
    state() : State {
        return{
            clickedAdopt : null,
            clickedPost : null,
            clickedEvent : null,

            // 분양글 전체 보기 리스트/커서
            reptileList : [],
            allCursor : 0,

            isLoading : false,

            // 카테고리 조회 리스트/커서
            categories : {},
            currentList : [],
            currentStatus : null, // 0 : 전체, 1 : 카테고리, 2 : 키워드검색
            // categoryCursor : {},

            // 분양글 필터 상태
            selectedSpecies : null,
            selectedGender : null,
            selectedSize : null,
            selectedLocation : null,
            selectedPrice : null,
            selectedMorph : null,
            selectedKeyword : null,

            filterResult : {
                filterCondition: null,
                data: [],
                cursor: 0,
                hasMoreData: true
            },
            noResult : false,

            temporaryFilter : {
                gender : '',
                size : null,
                location : null,
                price : null,
                city : null,
                town : '',
                toggle : false,
            },
            columnNewsUrl : null,


            // 커뮤니티 글 조회
            community : {
                // 전체리스트
                0 :{ data : [], cursor : 0 },
                // 자유게시판
                1 : { data : [], cursor : 0 },
                // 질문게시판
                2 : { data : [], cursor : 0 },
                // 갤러리
                3 : { data : [], cursor : 0 }
            },            
            currentCommunityList : [],
            channelData : [],

            welcome : false,
            mobileSpeicesModal : false,

        }
    },
    mutations : {

        // 분양글 전체 조회
        SET_REPTILE_LIST(state :State , data: ReptileList[]){
            state.reptileList = [...state.reptileList, ...data];
        },
        SET_ALL_CURSOR(state, newCursor){
            state.allCursor = newCursor;
        },

        // 현재 리스트가 전체/카테고리/필터 검색인지 구분
        SET_CURRENT_STATUS(state, newValue){
            state.currentStatus = newValue;
        },
        SET_CURRENT_LIST(state, newList) {
            state.currentList = newList;
        },
        SET_MOBILE_SPEICES_MODAL(state, value) {
            state.mobileSpeicesModal = value;
        },



        // 카테고리 초기화 뮤테이션
        INIT_CATEGORY_DATA(state,  categoryName ) {
            state.categories[categoryName] = { data: [], cursor: 0, hasMoreData: true }
        },
        // 카테고리 값 저장하기
        SET_CATEGORY_DATA(state, { categoryName, data, cursor, hasMoreData }) {
            const category = state.categories[categoryName];
            category.data = [...category.data, ...data];
            category.cursor = cursor;
            category.hasMoreData = hasMoreData;
        },



        // 필터값 초기화하기 
        INIT_FILTER_DATA(state) {
            state.filterResult = { filterCondition :null, data: [], cursor: 0, hasMoreData: true }
        },
        // 필터 검색 값 업데이트하기
        UPDATE_FILTER_DATA(state, { filterCondition, data, cursor, hasMoreData }) {
            state.filterResult.filterCondition = filterCondition
            state.filterResult.data = [...state.filterResult.data, ...data];
            state.filterResult.cursor = cursor;
            state.filterResult.hasMoreData = hasMoreData;
        },
        // 필터 UI 변경
        SET_TEMPORARY_FILTER(state, newFilters) {
            state.temporaryFilter = { ...state.temporaryFilter, ...newFilters };
            },
        SET_NO_RESULT(state, isData){
            state.noResult = isData;
        },

        SET_LOADING(state, isLoading) {
            state.isLoading = isLoading;
        },

        SET_CLICKED_ADOPT(state : State, post : object) {
            state.clickedAdopt = post;
        },
        SET_CLICKED_POST(state : State, post : object) {
            state.clickedPost = post;
        },
        SET_CLICKED_EVENT(state : State, post : object) {
            state.clickedEvent = post;
        },


        SET_COMMUNITY_LIST(state :State, {category,  data, cursor}){
            state.community[category].data = [...state.community[category].data, ...data];
            state.community[category].cursor = cursor;
        },
        SET_CURRENT_COMMUNITY(state, newList) {
            state.currentCommunityList = newList;
        },
        
        SET_STATUS_FILTER(state, value){
            state.selectedGender = value.gender;
            state.selectedSize = value.size;
            state.selectedLocation = value.location;
            state.selectedPrice = value.price;
        },


        SET_COLUMN_URL(state, value){
            state.columnNewsUrl = value
        },

        SET_SPECIES_STATUS(state, value){
            state.selectedSpecies = value;
        },
        SET_GENDER_STATUS(state, value){
            state.selectedGender = value;
        },
        SET_SIZE_STATUS(state, value){
            state.selectedSize = value;
        },
        SET_LOCATION_STATUS(state, value){
            state.selectedLocation = value;
        },
        SET_PRICE_STATUS(state, value){
            state.selectedPrice = value;
        },
        SET_MORPH_STATUS(state, value){
            state.selectedMorph = value;
        },
        SET_KEYWORD_STATUS(state, value){
            state.selectedKeyword = value;
        },

        UPDATE_CHANNEL_DATA(state, value){
            state.channelData = value;
            // console.log('value', value)
            // console.log('state.channelData', state.channelData)
        },

        IS_WELCOME(state, value){
            state.welcome = value
        }


    },
    getters : {
        getSelectedSpecies(state){
            return state.selectedSpecies
        },
        getClickedPost (state) {
            return state.clickedAdopt;
        },
        getCategories(state) {
            return state.categories
        },

        getReptileMainList(state){
            return state.reptileList.slice(0, 12);
        },

        getCommMainList(state) {
            const raw = state.community[0].data;
            const filteredData = [];
        
            for (let i = 0; i < raw.length; i++) {
                if (filteredData.length === 4) {
                    break; // 필터링된 데이터가 4개이면 루프 종료
                }
                if (raw[i].imgURL1 !== "") {
                    filteredData.push(raw[i]); // 이미지 URL이 있는 데이터 추가
                }
            }
            return filteredData; 
        },
        // getColumnListbyCategory(state, category){
        //     if (state.channelData.length > 0) {
        //         const raw = state.channelData;
        //         const filteredData = raw.filter(data => data.category === category);
        //         return filteredData;
        //     } else {
        //         return;
        //     }
        // }
        allChannelData(state){
            return state.channelData
        }
        
    },
    
    actions: {
        // 분양글 전체 리스트 조회
        async getReptileList({commit, state}) {
            commit('SET_LOADING', true);
            commit('SET_CURRENT_STATUS', 0)
            // 4초 지연을 위한 Promise 생성 
            // await new Promise(resolve => setTimeout(resolve, 500));
            const encodedCursor =  encodeBase62(state.allCursor)
            // console.log(encodedCursor)
            // console.log(encodeBase62(0))
            try{
                const rsp = await SearchApi.adoptLatest(encodedCursor);
                if (rsp?.data) {
                    commit('SET_REPTILE_LIST', rsp.data);
                    commit('SET_CURRENT_LIST', state.reptileList);
                    // const CALL_AMT = 20 - 1; // 20개 호출
                    const newCursor = rsp.data[rsp.data.length - 1].id;
                    commit('SET_ALL_CURSOR', newCursor);
                    // commit('SET_LOADING', false);
                }
            }catch(error) {
                console.log(error)
            }
            finally {
                commit('SET_LOADING', false);
            }
        },
        // 분양글 새로고침 리셋
        async resetReptileList({commit, state}) {
            console.log('검색초기화')
            commit('SET_LOADING', true);
            commit('SET_CURRENT_STATUS', 0)
            const encodedInit = encodeBase62(0)
            try{
                const rsp = await SearchApi.adoptLatest(encodedInit);
                if (rsp?.data) {
                    commit('SET_REPTILE_LIST', rsp.data);
                    commit('SET_CURRENT_LIST', rsp.data);
                    // const CALL_AMT = 20 - 1; // 20개 호출
                    const newCursor = rsp.data[rsp.data.length - 1].id;
                    commit('SET_ALL_CURSOR', newCursor);
                }
            } catch(error) {
                console.log(error)
            }
            finally {
                commit('SET_LOADING', false);
            }
        },
        

        // 분양글 상세보기
        async viewAdopt({commit} , id){
            commit('SET_LOADING', true);
            try {
                const rsp = await SearchApi.viewAdopt(id);
                commit('SET_CLICKED_ADOPT', rsp.data);
                // console.log(rsp.data)
            } catch (error){
                console.log(error)
            }
            finally{
                commit('SET_LOADING', false);
            } 
        },

        // 커뮤니티 글 상세보기
        async viewPost({commit} , id){
            commit('SET_LOADING', true);
            try {
                const rsp = await BoardApi.viewPost(id);
                commit('SET_CLICKED_POST', rsp.data);
                // console.log(rsp.data)
            } catch (error){
                console.log(error)
            }
            finally{
                commit('SET_LOADING', false);
            }
        },

        // 박람회 상세보기
        async viewEvent({commit} , id){
            commit('SET_LOADING', true);
            try {
                const rsp = await BoardApi.fetchEventData(id);
                commit('SET_CLICKED_EVENT', rsp.data[0]);
                // console.log(rsp.data)
            } catch (error){
                console.log(error)
            }
            finally{
                commit('SET_LOADING', false);
            }
        },

        // 카테고리 리스트 조회
        async fetchCategoryData({ commit, state },  categoryName ) {
            // 카테고리 데이터가 없거나 더 로드할 데이터가 있을 경우에만 API 요청을 진행
            commit('SET_LOADING', true);
            commit('SET_CURRENT_STATUS', 1)
            try {
                if(!state.categories[categoryName]){
                    commit('INIT_CATEGORY_DATA', categoryName)
                } 
                store.commit('SET_NO_RESULT', false)
                const currentCursor = state.categories[categoryName]?.cursor;
                const encodedCursor = encodeBase62(currentCursor);
                // console.log('number :', currentCursor)
                // console.log('encode : ' ,encodedCursor)
                const rsp = await SearchApi.searchKeyword({ categoryName, cursor: `${encodedCursor}` });
                
                // console.log(rsp)
                if(rsp.data.length > 0){
                    const hasMoreData = rsp.data.length === 24; // 30이면 true : 데이터 더 있음.
                    const newCursor = rsp.data[rsp.data.length - 1].id;
                    // console.log(newCursor)
                    commit('SET_CATEGORY_DATA', { categoryName, data: rsp.data, cursor: newCursor, hasMoreData });
                    commit('SET_CURRENT_LIST', state.categories[categoryName]?.data || []);
                } else {
                    commit('SET_CATEGORY_DATA', { categoryName, data: [], hasMoreData : false });
                    commit('SET_CURRENT_LIST', state.categories[categoryName]?.data || []);
                    if(state.currentList.length === 0){
                        store.commit('SET_NO_RESULT', true)

                    }
                }
            } catch (error) {
                console.error(error);
            } 
            finally{
                commit('SET_LOADING', false);
            }
        },

        // 전체보기 데이터 호출
        async fetchHamburgerData({commit, state}, categoryName ) {
            // 카테고리 데이터가 없거나 더 로드할 데이터가 있을 경우에만 API 요청을 진행
            commit('SET_LOADING', true);
            commit('SET_CURRENT_STATUS', 3)
            try {
                if(!state.categories[categoryName]){
                    commit('INIT_CATEGORY_DATA', categoryName)
                } 
                store.commit('SET_NO_RESULT', false)
                const currentCursor = state.categories[categoryName]?.cursor;
                // const encodedCursor = encodeBase62(currentCursor);
                // console.log('number :', currentCursor)
                // console.log('encode : ' ,encodedCursor)
                const rsp = await SearchApi.searchHamburger({ categoryName, cursor: `${currentCursor}` });
                
                // console.log(rsp)
                if(rsp.data.length > 0){
                    const hasMoreData = rsp.data.length === 24; // 30이면 true : 데이터 더 있음.
                    const newCursor = rsp.data[rsp.data.length - 1].id;
                    commit('SET_CATEGORY_DATA', { categoryName, data: rsp.data, cursor: newCursor, hasMoreData });
                    commit('SET_CURRENT_LIST', state.categories[categoryName]?.data || []);
                } else {
                    commit('SET_CATEGORY_DATA', { categoryName, data: [], hasMoreData : false });
                    commit('SET_CURRENT_LIST', state.categories[categoryName]?.data || []);
                    if(state.currentList.length === 0){
                        store.commit('SET_NO_RESULT', true)

                    }
                }
            } catch (error) {
                console.error(error);
            } 
            finally{
                commit('SET_LOADING', false);
            }
        },
        
        async fetchFilterData({commit, state}) {
            commit('SET_LOADING', true);
            const params : SearchParams = {
                jong : state.selectedSpecies,
                size: state.selectedSize,
                rsex : state.selectedGender === '' ? '' : genderToText(state.selectedGender),
                location : state.selectedLocation,
                price : formattedPriceRange(state.selectedPrice),
                detailkeyword: state.selectedMorph ? state.selectedMorph.join() : null,
                title : state.selectedKeyword,
            }
            commit('SET_CURRENT_STATUS', 2)
            const originFilterCon = store.state.filterResult.filterCondition;
            const searchFilterCon = JSON.stringify(params);
            if(originFilterCon !== searchFilterCon){
                commit('INIT_FILTER_DATA');
            } 
            // else {
            //     commit('SET_LOADING', false);
            //     // console.log('init 됐음')
            //     // return;
            // }
            params.cursor = encodeBase62(state.filterResult.cursor);
            store.commit('SET_NO_RESULT', false)
                // console.log('number :', params.cursor)
                // console.log('encode : ' ,encodeBase62(1526))
            try {
                const rsp = await SearchApi.searchFilter(params);
                if(rsp.data.length > 0){
                    const hasMoreData = rsp.data.length === 24; // 30이면 true : 데이터 더 있음. . 
                    const newCursor = rsp.data[rsp.data.length - 1].id

                    commit('UPDATE_FILTER_DATA', {filterCondition : searchFilterCon, data : rsp.data, cursor : newCursor, hasMoreData})
                    commit('SET_CURRENT_LIST', state.filterResult.data)
                } else {
                    commit('UPDATE_FILTER_DATA', {filterCondition : searchFilterCon, data : [], hasMoreData : false})
                    commit('SET_CURRENT_LIST', state.filterResult.data)

                    if(state.currentList.length === 0){
                        store.commit('SET_NO_RESULT', true)

                    }
                }

            }catch (error) {
                console.log(error)
            }

            finally {
                commit('SET_LOADING', false);
            }
        },



        async fetchCommData({commit, state}, category : number){
            // const currentBoard = category;
            const currentBoard = state.community[category];

                try {
                    const rsp = await BoardApi.BoardList(category, currentBoard.cursor)
                    const newCursor = rsp.data[rsp.data.length - 1].id ;
                    

                    commit('SET_COMMUNITY_LIST', { category, data: rsp.data, cursor: newCursor })
                    commit('SET_CURRENT_COMMUNITY', state.community[category].data)

                } catch(error){
                    console.log(error)
            }
        },

        async fetchChannleData({commit, state}){
            if(state.channelData.length !== 0) {
                return;
            }
            try {
                const rsp = await BoardApi.callChannelData();
                commit('UPDATE_CHANNEL_DATA', rsp.data)
                // console.log(rsp.data)
            } catch (error) {
                console.log(error)
            }
        },





        // async FILTER_SEARCH({ commit }, 
        // { species, gender, size, location, price }:FilterSearchPayload ) {
        //     try {
        //         // 필터링 옵션을 기반으로 쿼리 매개변수 객체 생성
        //         const queryParams : Record <string, string> = {};
        //         if (species) queryParams.species = species;
        //         if (gender) queryParams.gender = gender;
        //         if (size) queryParams.size = size;
        //         if (location) queryParams.location = location;
        //         if (price) queryParams.price = price;
        
        //         // 쿼리 매개변수 객체를 사용하여 API 호출
        //         const response = await axios.get('/api/your-endpoint', {
        //         params: queryParams
        //         });
        
        //         // 결과 처리
        //         commit('SET_RESULTS', response.data);
        //     } catch (error) {
        //         console.error(error);
        //     }
        // }
    }
      
})

export default store;
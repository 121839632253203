<template>
    <div class="w-full h-full flex flex-col   relative text-sub text-sm ">


        <div class="flex  w-full  text-xs sm:text-sm flex-wrap ">

            <div class="mr-[10px] px-3 min-w-[84px] h-10 flex mb-2 justify-between items-center border border-solid border-[#cccdde] rounded-[5px]  cursor-pointer lg:hidden "
            @click="handleSpeices()" :class="selectedSpecies ? 'text-def font-bold' : '' ">{{ selectedSpecies !== null  ? selectedSpecies : '개체 종' }}</div>
            <div class="mr-[10px] px-3 min-w-[84px] h-10 flex justify-between items-center border border-solid border-[#cccdde] rounded-[5px]  cursor-pointer"
            @click="handlePopup('gender')">
            <p :class="tempFilter.gender ? 'text-def font-bold' : '' ">{{ tempFilter.gender ?  tempFilter.gender  : '성별'}}</p>
            <div class=" sm:w-6 w-4 text-end">{{ tempFilter.toggle  && popupFilter ==='gender' ? `-` : '+'}} </div>
            </div>
            <div class="mr-[10px] px-3 min-w-[84px] h-10 flex justify-between items-center border border-solid border-[#cccdde] rounded-[5px]  cursor-pointer"
            @click="handlePopup('size')">
            <p :class="tempFilter.size ? 'text-def font-bold' : '' ">{{ tempFilter.size ?  tempFilter.size  : '크기'}}</p>
            <div class=" sm:w-6 w-4 text-end">{{ tempFilter.toggle  && popupFilter ==='size' ? `-` : '+'}} </div>
            </div>
            <div class="mr-[10px] px-3 min-w-[84px] h-10 flex justify-between items-center border border-solid border-[#cccdde] rounded-[5px]  cursor-pointer"
            @click="handlePopup('location')">
            <p :class="tempFilter.city ? 'text-def font-bold' : '' ">
               <span>{{ tempFilter.city ?  `${tempFilter.city} ${tempFilter.town}`  : '지역'}}</span> 
            </p>
            <div class=" sm:w-6 w-4  text-end">{{ tempFilter.toggle  && popupFilter ==='location' ? `-` : '+'}} </div>
            </div>
            <div class="mr-[10px] px-3 min-w-[84px] h-10 flex  mb-2  justify-between items-center border border-solid border-[#cccdde] rounded-[5px]  cursor-pointer"
            @click="handlePopup('price')">
            <p :class="tempFilter.price ? 'text-def font-bold' : '' ">{{ tempFilter.price ?  tempFilter.price  : '가격'}}</p>
                <div class=" sm:w-6 w-4  text-end"> {{ tempFilter.toggle  && popupFilter ==='price' ? `-` : '+'}} </div>
            </div>
                <button class="w-10 h-10  rounded-md border border-solid border-[#cccdde] flex justify-center items-center sm:mr-auto mr-2" @click="resetFilter">
                    <img src="@/assets/img/logo/reset.svg" alt="" class="w-5 font-normal">
                </button>
                <button class="w-28 h-10 rounded-md bg-pada text-white flex justify-center items-center font-medium text-sm " 
                @click="handleApply">적용하기</button>


        </div>



        <div  class=" bg-green-00 transition-all duration-300 h-0 flex flex-col mt-5 mb-5"
        :class="{
        'slide': tempFilter.toggle,
        'height-9rem': tempFilter.toggle && popupFilter === 'location'
      }">
                <div v-if="popupFilter==='gender' && tempFilter.toggle" class="translate-y-0">
                    <button 
                        v-for="(btn, i) in genderArr" 
                        :key="i" 
                        @click="setGender(btn)"
                        class="toggle"
                        :class="{ 'selected': tempFilter.gender === btn }"
                    >{{ btn }}</button>
                </div>
           
            <div v-if="popupFilter==='size' && tempFilter.toggle" class="translate-y-0" >
                <button 
                    v-for="(btn, i) in sizeArr" 
                    :key="i" 
                    @click="setSize(btn)"
                    class=" toggle"
                    :class="{ 'selected': tempFilter.size === btn }"
                >{{ btn }}</button>
            </div>
            <div v-if="popupFilter==='price' && tempFilter.toggle" class="translate-y-0">
                <button 
                    v-for="(btn, i) in priceArr" 
                    :key="i" 
                    @click="setPrice(btn)"
                    class=" toggle"
                    :class="{ 'selected': tempFilter.price === btn }"
                >{{ btn }}</button>
            </div>
            <div v-if="popupFilter==='location' && tempFilter.toggle" class="translate-y-0">
                <button 
                    v-for="(btn, i) in locationArr" 
                    :key="i" 
                    @click="setCity(btn)"
                    class=" toggle "
                    :class="{ 'selected': tempFilter.city === btn }"
                >{{ btn }}</button>
            </div> 
            
            
        </div>
        
        <div class="bg-red-00 w-full h-full block transition-all duration-300 translate-y-0 "
        :class=" tempFilter.city && popupFilter==='location' ? 'block' : 'hidden' "
        >
            <button 
                v-for="(btn, i) in locationDetailArr" 
                :key="i" 
                @click="setTown(btn)"
                class="mr-3 mb-3 translate-y-2 p-1"
                :class="{ 'text-def font-medium': tempFilter.town === btn }"
                >{{ btn }}
            </button>
        

            </div>


       
    </div>
    <div>
    <div class="box w-full h-auto fixed top-0 left-0"  
    :class="{ 'off-screen': !handleMobileSpecies, 'on-screen': handleMobileSpecies }"   
  >
        <SpeciesFilter/>
    </div>
    <!-- <div class="bg-lime-50 w-full h-40 lg:hidden bg-white flex absolute left-0 top-0 box"  
    :class="{ 'off-screen': !handleMobileSpecies, 'on-screen': handleMobileSpecies }">
        <SpeciesFilter/>
    </div> -->
  </div>
    <div class="w-full border-[#E9E9E9] border-solid border-b h-1 bg-blue-00" > </div> 

</template>

<script lang="ts" setup>
import router from '@/router';
import { ref, computed, defineEmits, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import locations from '@/assets/data/location.json'
import MobileFilter from '@/components/Serach/MobileFilter.vue'
import { splitLocation } from '@/utils/formattedUnit';
import SpeciesFilter from './SpeciesFilter.vue';
const closeBtn = () => {
    store.commit('SET_MOBILE_SPEICES_MODAL', false)
}
const store = useStore();
const genderArr = ['미구분', '암컷', '수컷', '한쌍', '그룹']
const sizeArr = ['베이비','아성체','준성체','성체']
const locationArr = ['서울','경기','인천','강원','충북','충남','세종','대전','경북','경남','대구','울산','부산','전북','전남','광주','제주'];
const priceArr = ['~10만원','10~50만원','50~100만원','100~500만원','500~1,000만원','1,000만원 이상']

const tempFilter = computed(()=> store.state.temporaryFilter);
const isLoading = computed(()=> store.state.isLoading);
const handleMobileSpecies = computed( () => store.state.mobileSpeicesModal);
const selectedSpecies = computed(() => store.state.selectedSpecies)
const popupOpen = ref(false)
const popupFilter = ref(null)
const locationDetailArr = ref([]); // "강남구","강동구","강북구","강서구","관악구",

onMounted(() => {
    // resetFilter();
    tempFilter.value.toggle = false
})
onUnmounted(() => {
    const resetValues = {
    price: '',
    location: '',
    gender: '',
    size: '',
    city : '',
    town : '',
    toggle : false,
  };
    store.commit('SET_TEMPORARY_FILTER', resetValues);
});
// watch(handleMobileSpecies, (newVal) => {
//   if (newVal) {
//     // `on-screen` 상태일 때 body 스크롤 비활성화
//     console.log('hidden wathcing')
//     document.body.style.overflow = 'hidden';
//   } else {
//     // `off-screen` 상태일 때 body 스크롤 활성화
//     document.body.style.overflow = '';
//   }
// });
const handleSpeices = () => {
    // store.commit('SET_MOBILE_SPEICES_MODAL', true)
    store.commit('SET_MOBILE_SPEICES_MODAL', !handleMobileSpecies.value)
    // console.log(handleMobileSpecies.value)
}


const handlePopup = (type:string) => {
    if(popupFilter.value !== type){
        popupFilter.value = type
        tempFilter.value.toggle = true
        // console.log(tempFilter.value)
        // console.log(popupFilter.value)
    } else if (type === 'location'){
        tempFilter.value.toggle = false
        popupFilter.value = null;

    } else {
        tempFilter.value.toggle = false
        popupFilter.value = null;

    }
    // popupOpen.value = !popupOpen.value
    popupOpen.value = false;

}


const setGender = ( btn :string) => {
    
    const value = { gender : btn}
    if(tempFilter.value.gender === btn){
        store.commit('SET_TEMPORARY_FILTER', {gender : ''});
    } else {
        store.commit('SET_TEMPORARY_FILTER', value);
    }
}
const setSize = (btn:string) => {
    const value = { size : btn}
    if(tempFilter.value.size === btn){
        store.commit('SET_TEMPORARY_FILTER', {size : ''});
    } else {
        store.commit('SET_TEMPORARY_FILTER', value);
    }
}


const setCity = (btn:string) => {
    const value = {
        city : btn,
        town : '',
    }
    store.commit('SET_TEMPORARY_FILTER', value);
    locationDetailArr.value = locations[tempFilter.value.city]
    // console.log(tempFilter.value.location)
    // console.log(locations[tempFilter.value.location])
}
const setTown = (btn:string) => {
    const value = {town : btn}
    store.commit('SET_TEMPORARY_FILTER', value);
}

const setPrice = (btn:string) => {
    const value = { price : btn}
    if(tempFilter.value.price === btn){
        store.commit('SET_TEMPORARY_FILTER', {price : ''});
    } else {
        store.commit('SET_TEMPORARY_FILTER', value);
    }
}

const resetFilter = () => {
    const resetValues = {
        price: '',
        location: '',
        city : '',
        town : '',
        gender: '',
        size: '',
    };
  store.commit('SET_TEMPORARY_FILTER', resetValues);
  emitToParnet();
};

const handleApply = async () => {
    if(isLoading.value) return;
    tempFilter.value.location = tempFilter.value.town ?  `${tempFilter.value.city}/${tempFilter.value.town}` : tempFilter.value.city



    store.commit('SET_STATUS_FILTER', tempFilter.value);
    store.commit('SET_KEYWORD_STATUS', null);
    await store.dispatch('fetchFilterData')
    // resetFilter();
    router.push('/reptile')
}

const emit = defineEmits(['resetToggle'])
const emitToParnet = () => {
    emit('resetToggle')
}
</script>

<style scoped>
.toggle{
    box-sizing: border-box;
    background-color: #f8f9f9;
    padding: 0.4rem 0.75rem;
    font-size: small;
    font-weight: 400;
    border: 1.3px solid #D8D9DC;
    border-radius: 5px;
    color: #BABDBF;
    margin-right: 10px;
    margin-bottom: 0.8rem;
}
.selected{
    background: #E0F9F1;
    border: 1px solid #00D191;
    color: #00D191;
}
.slide {
    height: 2.0rem;
    /* background-color: aqua; */
    /* border-bottom: 4px solid black; */
    @media (max-width:768px) {
        height: 4rem;
    }
}

.height-9rem {
    height: 4.3rem;

}
@media (max-width:630px) {
    .height-9rem {
    height: 10rem;

}
    }
.height-0 {
  height: 0rem;
}
.off-screen {
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}


.on-screen {
  transform: translateX(0%);
  transition: transform 0.3s ease;

}
/* .box{
    position: sticky; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  overflow-y: auto; 
  z-index: 1000; 
} */
.box{
    width: 100%;
    z-index: 50; 
    height: 100%; 
    overflow: scroll;
    overscroll-behavior: none;
}
@media (max-width: 1024px) {
    .box{
        z-index: 980;
    }
}

</style>

<template>
    <div class="flex flex-col w-full px-4 sm:px-0 ">
        <div v-if="!isFlutter()" class="mb-4 font-bold text-lg justify-between flex sm:mt-0 mt-4">
            <h1 class="pl-2">박람회/이벤트 소식</h1>    
        </div>
        <div v-else class="sm:mt-0 mt-4"></div>

        <div class="grid grid-cols-2 sm:gap-10 md:w-[768px] w-full gap-2 gap-y-7">
            <ul v-for="expo in exhibition" :key="expo.id" class="">
                <li @click="goToEvent(expo.id)" class="cursor-pointer">
                    <div class="sm:h-96 h-52 ">
                        <img :src="expo.imgURL1" alt="" class="w-full h-full object-cover sm:rounded-lg rounded-md">
                    </div>
                    <div class=" px-1 sm:py-4 py-2 sm:space-y-1 space-y-[2px]">
                        <!-- sm:w-12 sm:h-7 w-11 h-[25px] {{ diffDay(expo.title3) }}<-->
                        <div class="w-full flex">
                            <div class="text-pada px-2 py-[2px] sm:px-2 sm:py-1  border-pada border-2 border-solid flex justify-center items-center rounded-md sm:text-sm text-[13px] font-bold ">
                                {{ diffDay(expo.title3) }}
                            </div>
                        </div>

                        <p class="text-[#A4A4A4]font-medium sm:text-base text-[13px]">{{ expo.title3 }}</p>
                        <p class="text-[#17181A] font-bold sm:text-xl text-base">{{ expo.title1 }}</p>
                        <p class="text-[#676767] font-normal sm:text-sm text-xs">{{ expo.title2 }}</p>
                    </div>
                </li>
            </ul>

        </div>
        <div v-if="isFinished">
<ConfirmModal 
content1=
"종료된 전시회입니다
다른 전시회를 확인해주세요" 
     closeButton="닫기" @updateClose="handleBtn"/>
</div>

        <div class="w-full h-40"></div>

    </div>
</template>
<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { diffDay } from '@/utils/timeForToday';
import { useRoute, useRouter } from 'vue-router';
import ConfirmModal from '../UI/ConfirmModal.vue';

const router = useRouter();
const route = useRoute();
const store = useStore();
const isFinished = ref(false);
const exhibition = computed(() => {
    const allData = store.getters.allChannelData;
    const filteredData = allData.filter(data => data.category === 4);

    // 날짜 문자열을 Date 객체로 변환하여 비교
    filteredData.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
    });

    return filteredData;
});

const handleBtn = () =>{
    isFinished.value = !isFinished.value;
}
// const isFlutter = ref(false);
const isFlutter = () => {
    if(route.path.includes('/mobile')){
        return true;
    } else {
        return false;
    }
}
const goToEvent = (id) => {
    if(id < 52){
        handleBtn();
    } 
    else {
        if(route.path.includes('/mobile')){
            router.push({ path: `/mobile/event/${id}` });
        } else {
            router.push({ path: `/news/event/${id}` });
        }
    }
}



</script>

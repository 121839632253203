<template>
<div class="flex flex-col space-y-8 mt-6">
        <div v-if="!isProducts" class="flex items-center font-medium text-lg">
            <div class="w-20 ">성별<span class="text-pada">*</span></div>
            <button
            v-for="(btn, i) in genderArr"
            :key="i"
            class="h-8 px-4 rounded-full bg-[#f2f4f5] mr-3 text-sm text-def font-normal"
            @click="() => setSelection('gender', btn)"
            :class="{'isSelected' : selectGender === btn}"
            >
            {{ btn }}
            </button>
        </div>

        <div v-if="!isProducts" class="flex items-center font-medium  text-lg">
            <div class="w-20 ">크기<span class="text-pada">*</span></div>
            <button
            v-for="(btn, i) in sizeArr"
            :key="i"
            class="h-8 px-4 rounded-full bg-[#f2f4f5] mr-3 text-sm text-def font-normal"
            @click="() => setSelection('size', btn)"
            :class="{'isSelected' : selectSize === btn}">
            {{ btn }}
            </button>
        </div>

        <div class="flex flex-col items-start font-medium text-lg ">
            <div class="flex-row flex">
                <div class="w-20 ">지역<span class="text-pada">*</span></div>
                <div class="w-10/12 h-full flex flex-wrap ">
                    <button
                        v-for="(btn, i) in locationArr"
                        :key="i"
                        class="h-8 px-4 rounded-full bg-[#f2f4f5] mr-3 text-sm mb-3 text-def font-normal"
                        @click="() => setSelection('location', btn)"
                        :class="{'isSelected' : selectLocation === btn}">
                        {{ btn }}
                    </button>
                </div>
            </div>
            <div v-if="selectLocation !== null" class="ml-20 border px-4 py-2 border-zinc-200 rounded-md border-solid w-[80%] h-full flex items-center flex-wrap">
                <button
                    v-for="(btn, i) in locationDetailArr"
                    :key="i"
                    class="h-6 mr-3 text-sm my-2 bg-red50 text-sub  flex justify-start"
                    @click="handleDetail(btn)"
                    :class="{ 'isDetail': selctedDetail === btn }">
                    <span class="flex w-full">{{ btn }}</span>
                </button>
            </div>
            <div class="pl-24 mt-4 text-sm font-medium">지역 : <span class="text-pada"> {{ selectedLocation}} {{ selctedDetail }}</span></div>

        </div>

       
    </div>
</template>

<script lang="ts" setup>
import { ref, defineEmits, watch, computed, defineProps } from 'vue';
import locations  from '@/assets/data/location.json'
import store from '@/store';
const genderArr :String[]= ['미구분', '암컷', '수컷', '한쌍', '그룹'];
const sizeArr:String[]= ['베이비','아성체','준성체','성체'];
const locationArr = ['서울','경기','인천','강원','충북','충남','세종','대전','경북','경남','대구','울산','부산','전북','전남','광주','제주'];
const props = defineProps({
    selectedGender : String,
    selectedSize : String,
    selectedLocation : String,
    selectedLocationDetail : String,
    selectedSpecies : String
});


const selectedSpecies = ref(props.selectedSpecies);
const selectGender = ref(props.selectedGender);
const selectSize = ref(props.selectedSize);
const selectLocation = ref(props.selectedLocation);
const selctedDetail = ref(props.selectedLocationDetail);
const locationDetailArr = ref(locations[selectLocation.value]); // "강남구","강동구","강북구","강서구","관악구",
const isProducts = ref(false);

const emit = defineEmits(['update:gender', 'update:size', 'update:location', 'update:locationDetail']);

const handleDetail = (btn) => {
  selctedDetail.value = btn
}

// 선택된 값이 변경될 때 부모 컴포넌트에 알림
const emitToParent = () => {
    emit('update:gender', selectGender.value);
    emit('update:size', selectSize.value);
    emit('update:location', selectLocation.value);
    emit('update:locationDetail', selctedDetail.value);
};

// props 값이 변경될 때 내부 상태를 업데이트
watch(() => props.selectedGender, (newValue) => {
  selectGender.value = newValue;
});
watch(() => props.selectedSize, (newValue) => {
  selectSize.value = newValue;
});
watch(() => props.selectedLocation, (newValue) => {
    selectLocation.value = newValue;
    locationDetailArr.value = locations[newValue]
});
watch(() => props.selectedLocationDetail, (newValue) => {
    selctedDetail.value = newValue;
});
watch(() => props.selectedSpecies, (newValue) => {
    selectedSpecies.value = newValue;
    if(newValue === '사육장' || newValue === '먹이' || newValue === '기타 용품' || newValue === '도서'){
        selectGender.value = '물건';
        selectSize.value = '';
        isProducts.value = true;
    } else {
        isProducts.value = false;
    }
});

const setSelection = (type, value) => {
  if (type === 'gender') {
        selectGender.value = value;
    } else if (type === 'size') {
        selectSize.value = value;
    } else if (type === 'location') {
        selctedDetail.value = null;
        selectLocation.value = value;
        locationDetailArr.value = locations[selectLocation.value];
  }
  emitToParent();
};


// 선택된 값이 변경될 때마다 emitToParent 함수를 호출
watch([selectGender, selectSize, selectLocation, selctedDetail ], emitToParent);
</script>

<style scoped>
.isSelected {
    background-color: #e0f9f1;
    color: #00d191;
}
.isDetail {
    @apply text-def font-medium
}

</style>



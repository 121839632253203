<template>
    <div :style="{ height : marginSpace }"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'MarginSpace',
    props : {
        marginSpace : {
            type : String,
            required : true,
        }
    }
});
</script>

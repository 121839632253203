<template>
    <div class="max-w-4xl  mx-auto  w-[896px]:px-0 px-10 ">
        <div class="container transition-all duration-1000 w-full opacity-0 " ref="elRef" :class="elClass">
            <div class="group1 w-full  transition-all duration-1000 px-0" :class="elClass">
                <img src="@/assets/img/download/sec3_filter1-m.png"  alt="" class="block lg:hidden">
                <img src="@/assets/img/download/sec3_filter1.png"  alt="" class="lg:block hidden">
            </div>
            <div class="col1 delay-500 transition-all duration-1000  mx-auto" :class="elClass">
                <img src="@/assets/img/download/sec3_filter2.png" alt="">
            </div>
            <div class="col2 delay-700 transition-all duration-1000  sm:w-full bg-pada rounded-3xl flex justify-center items-center md:text-4xl text-white font-bold 
            text-2xl w-full sm:py-0 py-20 mx-auto
            " :class="elClass">
                <p class="leading-relaxed">전국 어디서나, <br> 집에서 하는 <br> 파충류 거래</p>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import useIntersectionObserver from '@/utils/v2IntersectionObserver';

export default defineComponent({
    name: 'SectionFilter',
    setup(){
        const elClass = ref('opacity-0 translate-y-10');
        const observerOptions = {
            root:null,
            rootMargin : '0px',
            threshold : 0.2
        };
        const {elementRef : elRef , isVisible : isVisible} = useIntersectionObserver(observerOptions);

        const updateVisibility = (isVisible , elClass)  =>{
            elClass.value = isVisible ? 'opacity-100 -translate-y-0' : 'opacity-0 translate-y-10'
        };

        watch(isVisible, newVal => updateVisibility(newVal, elClass));

        return {
            elRef,
            elClass,
        }
    }

});
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2개의 열 */
  grid-template-rows: repeat(2, auto); /* 5개의 행 */
  grid-template-areas: 
    "group1 group1"
    "col1 col2";
    gap: 1rem;
}
@media (max-width: 640px) {
  .container {
    grid-template-columns: 1fr; /* 모바일 화면에서는 한 컬럼으로 */
    grid-template-rows: auto; /* 필요한 만큼의 행 */
    grid-template-areas: 
      "group1"
      "col1"
      "col2";
      gap: 2rem;
  }
}
.group1 {
    grid-area: group1;
}

.col1 {
  grid-area: col1;
}

.col2 {
  grid-area: col2;
}

</style>
<template >
    <div class="h-16 sm:mb-4 mb-2 ">
        
    </div>
</template>
<script>
export default {
    name : 'NaviHeight'
}
</script>
<style>  
</style>
<template>
    <div class="max-w-3xl h-full mx-auto relative">
        <img :src=promotion alt="" class="w-full h-full">
        <button @click="handleBtn" class="w-7/12 h-20 absolute bottom-[7.5%]  left-[20%]"></button>
    </div>
</template>
<script setup>
import { ref } from 'vue';
const promotion = 'https://pada.s3.ap-northeast-2.amazonaws.com/paper2/july2024/%E1%84%8F%E1%85%A1%E1%84%8F%E1%85%A1%E1%84%8B%E1%85%A9+%E1%84%91%E1%85%B3%E1%84%85%E1%85%A9%E1%84%86%E1%85%A9%E1%84%89%E1%85%A7%E1%86%AB.png'
const device = ref({
    os: '',
    link: '',
});

const checkDevice = () => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
        device.value.os = 'android';
        device.value.link = 'https://play.google.com/store/apps/details?id=com.piot.pada2023&pcampaignid=web_share';
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
        device.value.os = 'ios';
        device.value.link = 'https://apps.apple.com/kr/app/파충류-다-있다/id6465895467';
    }
    else {
        device.value.os = 'pc';
        alert('모바일에서 이용해주세요');
    }
};

const handleBtn = () => {
    checkDevice();
    window.location.href = device.value.link
};
</script>

<template>
        <div class="w-full flex flex-row md:flex-col sm:px-0 px-4 bg-red-0 ">
        <router-link to="/community" >
            <h3 class="btn  " :class=" activeMenu === 'free' ? 'bg-[#f9f9f9] ' : ''" @click="handleBtn('free')" >💬&nbsp;자유게시판</h3>
        </router-link>

        <router-link to="/qna" >
        <h3 class="btn " :class=" activeMenu === 'qa' ? 'bg-[#f9f9f9] ' : ''" @click="handleBtn('qa')">✋🏻&nbsp;질문&답변</h3>
        </router-link>

        <router-link to="/gallery">
            <h3 class="btn " :class=" activeMenu === 'photo' ? 'bg-[#f9f9f9] ' : ''" @click="handleBtn('photo')">📷 &nbsp;갤러리</h3>
        </router-link>

    </div>
</template>

<script lang="ts" setup>
import { ref, Ref, computed } from 'vue';
import { useStore } from 'vuex';
const store = useStore();
const activeMenu :Ref<String> = ref('free') 

const communityList = computed(()=> store.state.community)


const handleBtn = async (menuName :string) => {
    if (menuName === 'free') {
        activeMenu.value = menuName;
        if(communityList.value[1].data.length === 0){
            await store.dispatch('fetchCommData', 1);
        }
        store.commit('SET_CURRENT_COMMUNITY', store.state.community[1].data)
        // console.log('여펭 버튼 클릭',store.state.currentCommunityList)
    } 
    if( menuName === 'qa'){
        activeMenu.value = menuName;
        if(communityList.value[2].data.length === 0){
            await store.dispatch('fetchCommData', 2);
        }
        store.commit('SET_CURRENT_COMMUNITY', store.state.community[2].data)
    }
    if(menuName === 'photo') {
        activeMenu.value = menuName;
    }
};
</script>

<style scoped>
.btn{
    cursor: pointer;
    @apply  text-sm md:text-base font-medium md:p-3 px-3 py-[9px] md:rounded-xl rounded-full md:mb-4 mb-0 whitespace-nowrap sm:ring-0 ring-1 ring-zinc-200 mr-2 
}

</style>
<!-- hover:translate-x-2 transition duration-200  -->
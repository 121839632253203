<template>
    <div class="w-full min-h-160 bg-[#F4FFF8] flex">
        <div class="w-[668px] mx-auto flex items-center flex-col md:flex-row ">
            <div class="w-60 h-120 flex justify-center items-center " >
                <img src="@/assets/img/logo/iPhone13.png" alt="" class="w-full h-full object-cover object-center">
            </div>
            <div class=" flex flex-col sm:ml-auto h-120 justify-center  mx-auto mx-0 text-center sm:text-left">
                <div class="font-bold text-2xl mb-4 text-[#171819]">파충류 다 있다</div>
                <div class="text-[#676767] mb-6 text-sm sm:text-base">세상의 모든 파충류는 여기에! <br>
                    #분양 #관리 #커뮤니티 #정보 <br>
                    한 번에 가능한 파충류 종합 어플리케이션입니다.<br>
                </div>
                <div class="flex space-x-4 sm:justify-between  justify-center">
                    <a href="https://play.google.com/store/apps/details?id=com.piot.pada2023&pcampaignid=web_share" target="_blank">
                        <button class="google downBtn">
                            <p class="ml-4 font-bold text-sm hidden sm:block">Google play</p> 
                        </button>
                    </a>

                    <a href="https://apps.apple.com/kr/app/파충류-다-있다/id6465895467" target="_blank">
                        <button class="apple downBtn">
                            <p class="ml-4 font-bold text-sm hidden sm:block ">APP store</p> 
                        </button>
                    </a>
                </div>
            </div> 

        </div>
    </div>
</template>
<script lang="ts">
export default {
    name:'PhoneMockup'
    
}
</script>
<style scoped>
.downBtn{
    width: 10rem;
    height: 3.0rem;
    background-color: white;
    box-sizing: border-box;
    /* width: 217px;
    height: 85px; */
    border: 1.3px solid #D8D9DC;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: 1rem ;
    background-position: 1rem;
    color: #676767;

}
@media (max-width: 640px){
    .downBtn{
        width: 4rem;
    height: 2.8rem;

        background-position: 1.4rem;
    }
}
.google{
    background-image: url('../../assets/img/logo/googleIcon.png');
}
    
.apple{
    background-image: url('../../assets/img/logo/appleIcon.png');
}
    
</style>





<template>
    <div class="max-w-4xl mx-auto flex flex-col sm:flex-row w-[896px]:px-0  px-10   ">
        
        <div class="w-full sm:w-1/2 transition-all duration-1000 opacity-0"
             ref="elRef" :class="elClass">
            <TitleBox
                title="채팅"
                content1="개체 정보 카드를"
                content2="구매자에게 전달하기"
            />
            <div class=" mx-auto lg:mt-0 mt-16">
                <img src="@/assets/img/download/sec6_phone2.png" alt="" class="-translate-x-0 sm:-translate-x-12">
            </div>
        </div>

        <div class="w-full sm:w-1/2 h-full flex flex-col justify-between transition-all duration-1000 opacity-0 delay-300" :class="elClass">
            <img src="@/assets/img/download/sec6_phone1.png" alt="">
            <p class="font-medium  leading-relaxed text-lg sm:text-xl sm:leading-relaxed text-[#3E4044]
                text-center sm:text-left lg:self-end self-auto ">등록한 개체 카드를 자랑하고 <br> 전송할 수 있어요.<br>
                거래하고 개체 정보를 편리하게 <br> 공유해보세요.</p>
        </div>
    </div>
</template>


<script lang="ts">
import { defineComponent , ref, watch} from 'vue';
import useIntersectionObserver from '@/utils/v2IntersectionObserver';
import TitleBox from './TitleBox.vue'

export default defineComponent({
    name:'SectionChat',
    components:{
        TitleBox
    },
    setup(){
        const elClass = ref('opacity-0 translate-y-10');
        const observerOptions = {
            root:null,
            rootMargin : '0px',
            threshold : 0.1
        };
        const {elementRef : elRef , isVisible : isVisible} = useIntersectionObserver(observerOptions);

        const updateVisibility = (isVisible , elClass)  =>{
            elClass.value = isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        };

        watch(isVisible, newVal => updateVisibility(newVal, elClass));

        return {
            elRef,
            elClass,
        }
    }

});

</script>


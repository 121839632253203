import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";
import axios from "axios";
import HomeView from "../views/HomeView.vue";
import BoardView from "../views/BoardView.vue";
import SearchView from "../views/SearchView.vue";
import AppDownView from "../views/AppDownView.vue";
import BoardList from "@/components/Board/BoardList.vue";
import BoardWrite from "@/components/Board/BoardWrite.vue";
import ColumnView from "@/views/ColumnView.vue";
import ExpoEvent from "@/components/Column/ExpoEvent.vue";
import PostDetail from "@/components/Board/PostDetail.vue";
import SearchList from "@/components/Serach/SearchList.vue";
import SearchWrite from "@/components/Serach/SearchWrite.vue";
import AdoptDetail from "@/components/Serach/AdoptDetail.vue";
import GalleryList from "@/components/Board/GalleryList.vue";
import ColumnNews from "@/components/Column/ColumnNews.vue";
import AssoChannel from "@/components/Column/AssoChannel.vue";
import ColumnModal from "@/components/Column/ColumnModal.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import { getUserId } from "@/utils/Functions";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ExpoDetail from "@/components/Column/ExpoDetail.vue";
import store from "../store";
import MobileView from "@/views/MobileView.vue";
import NoticeList from "@/components/Mobile/NoticeList.vue";
import NoticeModal from "@/components/Mobile/NoticeModal.vue";
import ExpoPurchase from "@/components/Column/ExpoPurchase.vue";
import ExpoForTicket from "@/components/Mobile/ExpoForTicket.vue";
import PromotionView from "@/views/PromotionView.vue";
import KakaoAppInstall from "@/components/event/KakaoAppInstall.vue";
import KakaoAppView from "@/views/KakaoAppView.vue";

const apiURL = process.env.VUE_APP_API_URL;
// const apiURL = 'http://172.16.128.26:8081';
const routes: RouteRecordRaw[] = [
  {
    path: "/main",
    name: "home",
    component: HomeView,
  },
  {
    path: "/community",
    component: BoardView,
    children: [
      {
        path: "",
        component: BoardList,
      },
      {
        path: "write",
        component: BoardWrite,
      },
      {
        path: ":id",
        component: PostDetail,
      },
    ],
  },
  {
    path: "/qna",
    component: BoardView,
    children: [
      {
        path: "",
        component: BoardList,
      },
    ],
  },
  {
    path: "/gallery",
    component: BoardView,
    children: [
      {
        path: "",
        component: GalleryList,
      },
    ],
  },
  {
    path: "/reptile",
    component: SearchView,
    children: [
      {
        path: "",
        component: SearchList,
      },
      {
        path: "write",
        component: SearchWrite,
      },
      {
        path: ":encodedId",
        component: AdoptDetail,
        beforeEnter: (to, from, next) => {
          const postId = to.params.encodedId;
          isValidPostId(postId).then((isValid) => {
            if (isValid) {
              next(); // 유효한 경우, 라우트 진행
            } else {
              next("/not-found"); // 유효하지 않은 경우, 리다이렉트
            }
          });
        },
      },
      {
        path: ":encodedId/edit",
        component: SearchWrite,
        beforeEnter: (to, from, next) => {
          //토큰으로 post_id & user_id 비교
          if (checkUserId()) {
            next(); // 접근 허용
          } else {
            next("/not-found"); // 유효하지 않은 경우, 리다이렉트
          }
        },
      },
    ],
  },
  {
    path: "/news",
    component: ColumnView,
    beforeEnter: async (to, from, next) => {
      await store.dispatch("fetchChannleData");
      next();
    },
    children: [
      {
        path: "",
        component: AssoChannel,
      },
      {
        path: ":id",
        component: ColumnModal,
      },
      {
        path: "column",
        children: [
          {
            path: "",
            component: ColumnNews,
          },
          {
            path: ":id",
            component: ColumnModal,
          },
        ],
      },
      {
        path: "event",
        children: [
          {
            path: "",
            component: ExpoEvent,
          },
          {
            path: ":id",
            children: [
              {
                path: "",
                component: ExpoDetail,
              },
              {
                path: "purchase",
                component: ExpoPurchase,
              },
            ],
            beforeEnter: async (to, from, next) => {
              const evnetId = to.params.id;
              // console.log(evnetId)
              await store.dispatch("viewEvent", evnetId);
              next();
            },
          },
        ],
      },
    ],
  },
  {
    path: "/mobile",
    component: MobileView,
    children: [
      {
        path: "event",
        beforeEnter: async (to, from, next) => {
          await store.dispatch("fetchChannleData");
          next();
        },
        children: [
          {
            path: "",
            component: ExpoEvent,
          },
          {
            path: ":id",
            component: ExpoDetail,
            beforeEnter: async (to, from, next) => {
              const evnetId = to.params.id;
              await store.dispatch("viewEvent", evnetId);
              next();
            },
          },
        ],
      },
      {
        path: "/ticket",
        component: MobileView,
        children: [
          {
            path: ":id",
            component: ExpoForTicket,
            beforeEnter: async (to, from, next) => {
              const evnetId = to.params.id;
              await store.dispatch("viewEvent", evnetId);
              next();
            },
          },
        ],
      },
      {
        path: "/promotion",
        component: PromotionView,
        children: [
          {
            path: ":id",
            component: {
              render: (h) => h("router-view"),
            },
          },
        ],
      },
      {
        path: "/kakao",
        component: KakaoAppView, // 동적 import
        children: [
          {
            path: ":id",
            component: KakaoAppInstall,
          },
        ],
      },
      {
        path: "column",
        beforeEnter: async (to, from, next) => {
          await store.dispatch("fetchChannleData");
          next();
        },
        children: [
          {
            path: "",
            component: ColumnNews,
          },
          {
            path: ":id",
            component: ColumnModal,
          },
        ],
      },
      {
        path: "news",
        beforeEnter: async (to, from, next) => {
          await store.dispatch("fetchChannleData");
          next();
        },
        children: [
          {
            path: "",
            component: AssoChannel,
          },
          {
            path: ":id",
            component: ColumnModal,
          },
        ],
      },
      {
        path: "notice",
        children: [
          {
            path: "",
            component: NoticeList,
          },
          {
            path: ":id",
            component: NoticeModal,
          },
        ],
      },
    ],
  },
  {
    path: "/messe-ticket-auth",
    component: () => import("@/views/TicketAuth.vue"),
  },
  {
    path: "/messe-ticket-view",
    component: () => import("@/views/TicketPurchaseView.vue"),
    beforeEnter: (_to, _from, next) => {
      const ticketToken = localStorage.getItem("ticketToken");
      if (ticketToken) {
        next();
      } else {
        alert("잘못된 접근입니다.");
        next("/messe-ticket-auth");
      }
    },
  },

  // {
  //     path : "/oauth",
  //     component : LoginAuth
  // },
  {
    path: "/:catchAll(.*)",
    redirect: "/not-found",
  },
  {
    path: "/not-found",
    component: NotFoundView,
  },

  // {
  //   path: "/list/:id",
  //   name : 'PostDetailView',
  //   component: PostDetailView,
  //   props : true,
  // },

  {
    path: "/",
    component: AppDownView,
  },
];

async function isValidPostId(postId) {
  const store = useStore();
  store.commit("SET_LOADING", true);
  // await new Promise(resolve => setTimeout(resolve, 10500));

  try {
    const response = await axios.get(`${apiURL}/zpw_bunyang/view/${postId}`);
    //   console.log(response)
    if (response.data !== "") {
      await store.dispatch("viewAdopt", postId);
      // console.log(response.data)
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("isValidPostId error:", error);
    return false;
  } finally {
    store.commit("SET_LOADING", false);
  }
}
// const callColumnData =  async () => {
//     const store = useStore();
//     await store.dispatch('fetchChannleData')
// }

const checkUserId = () => {
  const store = useStore();
  const router = useRouter();
  const postItem = store.state.clickedAdopt;

  try {
    const userId = getUserId();
    const postUser = postItem.user_id;

    if (userId === postUser) {
      return true;
    }
  } catch (e) {
    console.error(e);
    router.push("/");
  }

  return false; // 기본적으로 false 반환
};

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;

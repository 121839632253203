import axios from "axios";
import { addWriteType } from "../types/type";
import { decodeBase62 } from "@/utils/idConverter";
import { accessToken } from "@/utils/Functions";
/// router index zpw_bunyang도 바꾸기!!!!
const apiURL = process.env.VUE_APP_API_URL;
const ticketURL = process.env.VUE_APP_TICKET_URL;
const apiURL2 = "http://172.16.128.26:8081";
// const apiURL = 'hi';

const setAuthorizationHeader = () => {
  const ticketToken = window.localStorage.getItem("ticketToken");
  axios.defaults.headers.common["Authorization"] = `Bearer ${ticketToken}`;
  axios.defaults.headers.common["Content-Type"] =
    "application/x-www-form-urlencoded";
};

interface ApiResponse {
  data: any;
  [key: string]: any;
}

interface SearchParams {
  cursor?: string;
  jong?: string;
  categoryName?: string;
  detailkeyword?: string;
  rsex?: string | "";
  size?: string;
  location?: string;
  price?: number;
  title?: string;
}
interface editTextParams {
  post_id: string | string[];
  jong: string;
  detail: string;
  content: string;
  price: number;
  rsex: number | string;
  size: string;
  location: string;
  title: string;
  user_id: string;
  ztype: string;
}

const BoardApi = {
  //전체 리스트 조회
  BoardList: async (
    category: number,
    id: number
  ): Promise<ApiResponse | undefined> => {
    try {
      const rsp = await axios.get(
        `${apiURL}/zpw_bbs/loadby/${category}/${id}`,
        {}
      );
      return rsp;
    } catch (e) {
      console.error("error : ", e);
      return undefined;
    }
  },
  viewPost: async (id: number): Promise<ApiResponse | undefined> => {
    try {
      const viewRsp = await axios.get(
        `${apiURL}/zpw_bbs/view/${id}`
        // const viewRsp = await axios.get(`${apiURL}/web_bunyang/view/${id}`, {
        // const viewRsp = await axios.get(`${testURL}/web_bunyang/view/${id}`, {
      );
      return viewRsp;
    } catch (e) {
      console.error("error : ", e);
      return undefined;
    }
  },
  callChannelData: async (): Promise<ApiResponse | undefined> => {
    try {
      const rsp = await axios.get(`${apiURL}/zpw_paper/getpapers`);
      return rsp;
    } catch (e) {
      console.error("error : ", e);
      return undefined;
    }
  },
  fetchEventData: async (id: number): Promise<ApiResponse | undefined> => {
    try {
      // const rsp = await axios.get(`http://172.16.128.26:8081/zpw_paper/viewpaper`, {
      const rsp = await axios.get(`${apiURL}/zpw_paper/viewpaper`, {
        params: {
          paperid: id,
        },
      });
      return rsp;
    } catch (error) {
      console.error(error);
    }
  },

  // MESSE 티켓 보기 인증
  ticketPurchaseAuth: async (code: string) => {
    try {
      const params = new URLSearchParams();
      params.append("password", code);

      const res = await axios.post(`${ticketURL}/master/messeLogin`, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      if (res) {
        localStorage.setItem("ticketToken", res.data.token);
      }

      return res.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  ticketList: async () => {
    try {
      setAuthorizationHeader();
      const res = await axios.post(`${ticketURL}/ticket/messeTickets`);

      return res.data;
    } catch (error) {
      alert("토큰이 만료되었습니다. 다시 인증해 주세요.");
      window.location.href = "/messe-ticket-auth";
      console.error(error);
    }
  },

  // /// ========================= [커뮤니티글]이미지 가티 업로드 하기=====================
  // addPostImages : async (form: FormData): Promise<any> => {
  //     const accessToken =  window.localStorage.getItem('accessToken')
  //     for (const [key, value] of form.entries()) {
  //         console.log(`${key}: ${value}`);
  //     }
  //     try {
  //         const headers = {
  //             'Authorization': `Bearer ${accessToken}`,
  //         };
  //         console.log(headers)

  //         return await axios.post(`${apiURL}/bbs/insertwithphotos`, form, { headers });
  //     } catch (error) {
  //         console.error(error);
  //         throw error;
  //     }
  // },

  // /// ========================= [커뮤니티글]텍스트만 업로드하기 =====================
  // addPostText : async (user_id : string, title :string, content :string, category :number ): Promise<any> => {
  //     console.log('api.ts formData', user_id, title, content, category )
  //     const accessToken =  window.localStorage.getItem('accessToken')

  //     try {
  //         const params = new URLSearchParams();
  //         params.append('user_id', user_id);
  //         params.append('title', title);
  //         params.append('content', content);
  //         params.append('category', category.toString());
  //         console.log('api.ts formData', params)
  //         return await axios.post(`${apiURL}/bbs/insert`, params, {
  //             headers : {
  //                 'Authorization': `Bearer ${accessToken}`,
  //                 'Content-Type': 'application/x-www-form-urlencoded'
  //             }
  //         });
  //     } catch( error) {
  //         console.log(error)
  //     }
  // },
};

const SearchApi = {
  //전체 리스트 조회
  adoptLatest: async (id: string): Promise<ApiResponse | undefined> => {
    try {
      const adoptLatestRsp = await axios.get(
        `${apiURL}/zpw_bunyang/loadby/${id}`,
        {
          // const adoptLatestRsp = await axios.get(`${apiURL}/web_bunyang/loadby/${id}`, {
          // const adoptLatestRsp = await axios.get(`${testURL}/web_bunyang/loadby/${id}`, {
        }
      );
      return adoptLatestRsp;
    } catch (e) {
      console.error("error : ", e);
      return undefined;
    }
  },
  viewAdopt: async (id: string): Promise<ApiResponse | undefined> => {
    try {
      const viewRsp = await axios.get(`${apiURL}/zpw_bunyang/view/${id}`, {
        // const viewRsp = await axios.get(`${apiURL}/web_bunyang/view/${id}`, {
        // const viewRsp = await axios.get(`${testURL}/web_bunyang/view/${id}`, {
        // params : {
        //     id : id
        // }
      });
      // console.log(viewRsp)
      return viewRsp;
    } catch (e) {
      console.error("error : ", e);
    }
    return undefined;
  },

  updateDelFlag: async (postId: number, flag: string, user_id: string) => {
    try {
      const params = new URLSearchParams();
      params.append("id", postId.toString());
      params.append("del_flag", flag.toString());
      params.append("user_id", user_id);
      await axios.post(`${apiURL}/zpw_bunyang/delflag`, params, {
        headers: {
          Authorization: `Bearer ${accessToken.value}`,
        },
      });
    } catch (error) {
      console.error(error);
    }
  },

  async searchKeyword(params: SearchParams): Promise<ApiResponse | undefined> {
    try {
      //   const rsp = await axios.get(`${testURL}/web_bunyang/combined-search/${params.cursor}`, {
      const rsp = await axios.get(
        `${apiURL}/zpw_bunyang/combined-search/${params.cursor}`,
        {
          //   const rsp = await axios.get(`${apiURL}/web_bunyang/combined-search/${params.cursor}`, {
          params: {
            jong: params.categoryName,
            rsex: "",
          },
        }
      );
      return rsp;
    } catch (error) {
      console.log(error);
    }
  },
  async searchHamburger(
    params: SearchParams
  ): Promise<ApiResponse | undefined> {
    try {
      //   const rsp = await axios.get(`${testURL}/web_bunyang/combined-search/${params.cursor}`, {
      const rsp = await axios.get(
        `${apiURL}/zpw_bunyang/hamburger-search/${params.cursor}`,
        {
          //   const rsp = await axios.get(`${apiURL}/web_bunyang/combined-search/${params.cursor}`, {
          params: {
            jong: params.categoryName,
            rsex: "",
          },
        }
      );
      return rsp;
    } catch (error) {
      console.log(error);
    }
  },

  async searchFilter(params: SearchParams): Promise<ApiResponse | undefined> {
    const cursor = params.cursor;
    try {
      const rsp = await axios.get(
        `${apiURL}/zpw_bunyang/combined-search/${cursor}`,
        {
          //   const rsp = await axios.get(`http://padaalb-1382498623.ap-northeast-2.elb.amazonaws.com:8080/bunyang/combined-search/${cursor}`, {
          params: {
            jong: params.jong,
            detailkeyword: params.detailkeyword,
            rsex: params.rsex,
            size: params.size,
            location: params.location,
            price: params.price,
            title: params.title,
          },
        }
      );
      return rsp;
    } catch (error) {
      console.log(error);
    }
  },
  /// ========================= [분양글] 처음 글 작성하기=====================
  addAdoptImages: async (form: FormData): Promise<any> => {
    const accessToken = window.localStorage.getItem("accessToken");
    // axios.defaults.withCredentials = true;

    // for (const [key, value] of form.entries()) {
    //     console.log(`${key}: ${value}`);
    // }
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      return await axios.post(`${apiURL}/zpw_bunyang/insertwithphotos`, form, {
        headers,
      });
      // return await axios.post(`${apiURL}/web_bunyang/insertwithphotos`, form, { headers });
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /// ========================= [분양글] 이미지 변경 수정하기 =====================
  editAdoptImages: async (form: FormData): Promise<any> => {
    const accessToken = window.localStorage.getItem("accessToken");

    // for (const [key, value] of form.entries()) {
    //     console.log(`${key}: ${value}`);
    // }
    try {
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      return await axios.post(`${apiURL}/zpw_bunyang/editwithphotos`, form, {
        headers,
      });
      // return await axios.post(`${apiURL}/web_bunyang/editwithphotos`, form, { headers });
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /// ========================= [분양글]텍스트만 업로드하기 =====================
  editAdoptText: async (editTextParams: editTextParams): Promise<any> => {
    // console.log(editTextParams);
    const accessToken = window.localStorage.getItem("accessToken");
    // console.log('post:', decodeBase62(postId))
    try {
      const params = new URLSearchParams();
      params.append("post_id", decodeBase62(editTextParams.post_id).toString());
      params.append("jong", editTextParams.jong);
      params.append("detail", editTextParams.detail);
      params.append("ztype", editTextParams.ztype);
      params.append("rsex", editTextParams.rsex.toString());
      params.append("size", editTextParams.size);
      params.append("location", editTextParams.location);
      params.append("price", editTextParams.price.toString());
      params.append("title", editTextParams.title);
      params.append("content", editTextParams.content);
      params.append("user_id", editTextParams.user_id);

      // console.log(params.toString());
      return await axios.post(
        `${apiURL}/zpw_bunyang/editwithoutPhoto`,
        params,
        {
          // return await axios.post(`${apiURL}/web_bunyang/editwithoutPhoto`, params, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  },
};

export { BoardApi, SearchApi };

<template>
  <div>
    <router-view>
      <component :is="getComponent()" />
    </router-view>
  </div>
</template>

<script lang="ts" setup>
import IrePromo from '@/components/event/IrePromo.vue';
import IreInvite from '@/components/event/IreInvite.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import { useRoute } from 'vue-router';
const route = useRoute();

const id = route.params.id

const getComponent = () => {

  if (route.params.id === '1') {
    return IrePromo;
  }
  if (route.params.id === 'ire-invitation') {
    return IreInvite;
  }

  return NotFoundView;
}
</script>

<template>
    <ShowMorph class="sm:px-0 px-4"/>


    <div class="flex flex-col min-h-screen relative ">
        <div v-if="noResult">
            <NoResult/>
        </div>
        <!-- <ul class="grid grid-cols-4 sm:grid-cols-3 xl:grid-cols-4 gap-x-3 lg:gap-x-5 xl:gap-x-7 gap-y-3 xl:gap-y-5 2xl:gap-y-8 search-results sm:px-0 px-4"> -->
        <ul class="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-x-3 lg:gap-x-5 xl:gap-x-7 gap-y-3 xl:gap-y-5 2xl:gap-y-8 search-results sm:px-0 px-4">
            <li v-for="item in currentList" :key="item.id"
            v-memo="[item.id]"
            @click="postDetail(item)"
            class="group box-border overflow-hidden flex rounded-md cursor-pointer pe-0 pb-2 lg:pb-3 flex-col items-start transition duration-200 ease-in-out 
            transform shadow-product bg-white liWrap relative
            ">
                <div class="img-container mb-3 md:mb-3.5 ">
                    <img :src="`${(item.imgURL1)}`" :alt="item.title" class="bg-gray-300 object-cover w-full transition duration-200 ease-in rounded-md" >
                            <div v-if="item.del_flag === 9 || item.del_flag === 7" class="absolute top-0  w-full h-full rounded-md flex justify-center items-center text-white">
                                <div class="w-full h-full bg-black opacity-40 rounded-md"></div>
                                <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  font-medium"> {{ item.del_flag === 9 ? '판매완료' : '예약중'  }} </div>
                            </div>
                </div>
                <div class="w-full h-full overflow-hidden px-0 sm:px-0">
                        <div class=" text-xs text-sub">
                            {{ item.name }} &middot; {{ timeForToday(item.create_at) }}</div>
                        <div class="h-12 pr-2 pt-2 line-clamp-2 text-sm  text-heading text-def">
                            {{ item.title }}
                        </div>
                        <div class="font-semibold space-s-2 mt-0.5 text-heading lg:text-lg lg:mt-1.5 text-def tracking-wide">
                            {{ formattedPrice(item.price) }}원
                        </div>
                        <div class=" text-xs text-sub flex space-x-2 pt-2">
                          <img src="@/assets/img/logo/like.svg" alt="" class="mr-1" >  {{ item.like_cnt }}
                          <img src="@/assets/img/logo/location.svg" alt="" class="mr-1"> {{ splitLocation(item.location) }}
                        </div>
                </div>

            </li>
        </ul>
        <div v-if="isLoading">
            <div class="w-full pt-20 justify-center items-start flex">
                <DimmedLoading/>
            </div>
        </div>

        <div v-else-if="aLoading">
            <div class="w-full justify-center pt-10 items-center flex "><LoadingCircle/></div>
        </div>
        <!-- <div >
            <div class="w-full justify-center pt-10 items-center flex "><LoadingCircle/></div>
        </div> -->
    </div>


    <ScrollTopBtn/>
    
    <div ref="loaderRef" class="w-full h-20 justify-center items-center flex  "></div> 
    <div class="w-full h-40 "> </div> 

</template>
<script lang="ts" setup>
import { ref, watch, onMounted, computed, } from 'vue';
// import { SearchApi } from '@/api/api';
import { formattedPrice, splitLocation } from '@/utils/formattedUnit';
import { timeForToday } from '@/utils/timeForToday';
import useIntersectionObserver from '@/utils/useIntersectionOvserver';
import LoadingCircle from '@/components/UI/LoadingCircle.vue'
import NoResult from '@/components/Serach/NoResult.vue'
import ShowMorph from './ShowMorph.vue';
import ScrollTopBtn from '@/components/UI/ScrollTop.vue'
import DimmedLoading from '../UI/DimmedLoadingV2.vue';
import { encodeBase62 } from '@/utils/idConverter';

import { debounce } from 'lodash';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { isTokenValid } from '@/utils/Functions';
const router = useRouter();
const store = useStore();

interface Item {
    id: number;
    title: string;
    name: string;
    imgURL1: string;
    content: string;
    price: number;
    create_at: string;
    location: string;
    like_cnt: number;
}
const { elementRef: loaderRef, isVisible } = useIntersectionObserver({});

const clickedItem = ref<Item | null>(null); 
const isRendered = ref<Boolean>(false);
const aLoading = ref(false); // 전체리스트 로딩
// const categoryLoading = ref<Boolean>(false);

const currentList = computed(() => store.state.currentList);
const currentStatus = computed(()=> store.state.currentStatus);
// const currentList = ref([]);
const categoryName = computed(()=> store.state.selectedSpecies);
// const categoryName = computed(()=> store.getters.getSelectedSpecies);
// const isCaLoading = computed(() => store.state.categories[categoryName.value].hasMoreData)

const categories = computed(() => store.getters.getCategories);
const filterResult = computed(() => store.state.filterResult);
const isLoading = computed(() => store.state.isLoading);
const noResult = computed(() => store.state.noResult);
// const isCategoryLoading = computed(() => {
//     const categoryData = store.state.categories[categoryName.value];
//     return categoryData ? categoryData.hasMoreData : 'aa';
// });
// const allCursor = computed(() => store.state.allCursor)

// const reptileList = computed(() => store.getters.getReptileList);

// 처음 접속 시, 전체 리스트를 출력함
onMounted(async () => {
    // if(!isRendered.value) return;
    if(currentList.value.length === 0 && !noResult.value){
        await store.dispatch('getReptileList');
        isRendered.value = true

    }

})
// const debouncedLoadReptileList = debounce(async () => {
//     console.log('호출')
//     aLoading.value = true;
//     await store.dispatch('getReptileList');
//     aLoading.value = false;
// }, 500);

const loadReptileList = async () => {
    aLoading.value = true;
    // await store.dispatch('getReptileList');

    await new Promise<void>(resolve => {
        setTimeout(() => {
            store.dispatch('getReptileList');
        aLoading.value = false;

            resolve();
        }, 500);
    });
    // aLoading.value = false;

};


const loadCategoryList = async () => {
    const categoryLoading = (categories.value[categoryName.value].hasMoreData);
    if(categoryLoading){
        aLoading.value = true;

        await new Promise<void>(resolve => {
            setTimeout(() => {
                store.dispatch('fetchCategoryData', categoryName.value);
                resolve();
            }, 500);
        });
        aLoading.value = false;
    }
};
const loadHamburgerList = async () => {
    const categoryLoading = (categories.value[categoryName.value].hasMoreData);
    if(categoryLoading){
        aLoading.value = true;

        await new Promise<void>(resolve => {
            setTimeout(() => {
                store.dispatch('fetchHamburgerData', categoryName.value);
                resolve();
            }, 500);
        });
        aLoading.value = false;
    }
};
const loadFilterList = async () => {
    const FilterLoading = (filterResult.value.hasMoreData)

    if(FilterLoading){
        aLoading.value = true;

        await new Promise<void>(resolve => {
            setTimeout(() => {
                store.dispatch('fetchFilterData');
                resolve();
            }, 500);
        });
        aLoading.value = false;

    }

};


watch(isVisible, debounce((newVal) => {
    // if(!isLoading.value){
        // console.log('loading')
        if (currentStatus.value === 0 && newVal ) {
            loadReptileList();
        // await loadReptileList();
        }
        if (currentStatus.value === 1 && newVal) {
             loadCategoryList();
        }
        if (currentStatus.value === 2 && newVal) {
             loadFilterList();
        }
        if (currentStatus.value === 3 && newVal) {
             loadHamburgerList();
        }
    // } else {
    //     return;
    // }
    }, 200));
// watch(isVisible, async (newVal) => {
//     // if(!isLoading.value){
//         // console.log('loading')
//         if (currentStatus.value === 0 && newVal ) {
//         await loadReptileList();
//         }
//         if (currentStatus.value === 1 && newVal) {
//             await loadCategoryList();
//         }
//         if (currentStatus.value === 2 && newVal) {
//             await loadFilterList();
//         }
//         if (currentStatus.value === 3 && newVal) {
//             await loadHamburgerList();
//         }
//     // } else {
//     //     return;
//     // }
// });

// 상세보기 
const postDetail = (item :Item) => {
    clickedItem.value = item;
    router.push({ path: `reptile/${encodeBase62(item.id)}` });
};



</script>
<style scoped>

.img-container {
  overflow: hidden; /* 확대될 때 이미지가 컨테이너 밖으로 나가지 않도록 설정 */
  position: relative;
  width: 100%;
  padding-top: 100%; /* 비율 유지 */
}

/* 실제 이미지 */
.img-container img {
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0px;
  transition: transform 200ms ease-in-out; /* 부드러운 확대 효과 */
}
/* .list-gredient {
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 4rem;
    background: linear-gradient(to top, white, transparent)
} */

/* 마우스 호버 시 이미지 확대 */
/* .liWrap:hover .img-container img {
  transform: scale(1.1); 
} */

</style>

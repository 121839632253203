import { createApp } from 'vue'
import App from './App.vue'
import './assets/styles.css'
import router from './router'
import store from './store'
import { createMetaManager } from 'vue-meta'
import { analytics } from '@/utils/fbase';
// import { worker } from "./mocks/browser";
// if (process.env.NODE_ENV === "development") {
//     worker.start();
// }


createApp(App).use(router).use(store).use(createMetaManager()).mount('#app') 

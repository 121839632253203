<template>
    <div class="flex flex-col w-full px-4 sm:px-0 ">
        <div v-if="!isFlutter()" class="mb-4 font-bold text-lg justify-between md:mt-0 mt-4">
            <h1 class="pl-2">양서파충류 공식 채널</h1>    
        </div>
        <div v-else class="sm:mt-0 mt-4"></div>
        <div class="grid sm:grid-cols-2 grid-cols-2 sm:gap-10 gap-2 sm:w-[768px] w-full">
            <ul v-for="(post) in association" :key="post.id" class="">
                    <li class="cursor-pointer" @click="handlePost(post)">
                    <!-- <li @click="callData(index)" class="cursor-pointer"> -->
                        <div class="sm:h-48 h-32 ">
                            <img :src="post.imgURL1" :alt="post.title1" class="w-full h-full object-cover rounded-lg">
                        </div>


                        <div class=" px-1 sm:py-4 py-2 space-y-2 flex flex-col">
                        <div class="text-[#A4A4A4] font-medium text-base flex ">
                                <div class="w-full">

                                    <div class="text-[#17181A] sm:font-bold sm:text-xl flex-grow leading-[1.7] sm:leading-[1.8] ">
                                        {{ post.title1 }}
                                    </div>
                                </div>
                         </div>

                        <p class="text-[#676767] font-normal text-sm">{{ post.title2 }}</p>


                    </div>




                    </li>
            </ul>

        </div>
        <div class="w-full h-40"></div>

    </div>
</template>
<script setup>
import data from '@/assets/data/association.json'
import { ref , onMounted, computed} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import axios from 'axios';
// const association = ref([]);
const router = useRouter();
const route = useRoute();
const store = useStore();
const association = computed(() => {
    const allData = store.getters.allChannelData;
    return allData.filter(data => data.category === 2);
});
const handlePost = (post) => {
    if(route.path.includes('/mobile')){
        router.push({ path: `/mobile/news/${post.id}` });
    } else {
        router.push({ path: `/news/${post.id}` });
    }
}

const isFlutter = () => {
    if(route.path.includes('/mobile')){
        return true;
    } else {
        return false;
    }
}
</script>

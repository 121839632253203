<template>
    <div class=" w-10">
        <svg class="loading-svg" viewBox="0 0 30 30">
            <circle cx="50%" cy="50%" r="10" class="loading-circle"></circle>
        </svg>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
    name:'LoadingCircle',

});
</script>

<style scoped>
@keyframes loading {
  0% {
    stroke-dashoffset: 62.83;
  }
  70% {
    stroke-dashoffset: -35; 
  }
  100% {
    stroke-dashoffset: -62.83;
  }
}
.loading-svg {
    width: 100%;
    height: 100%;
}
.loading-svg circle {
  animation: loading 2s infinite;
}

.loading-circle {
  fill: transparent;
  stroke: #00D191;
  stroke-width: 3;
  stroke-dasharray: 62;
  stroke-dashoffset: 0;
}

</style>
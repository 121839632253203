<template>
    <div>
      <!-- 네비게이터 높이 만큼 줌. -->
      <TopNavigator/>
      <NaviHeight/>
      <!-- <TopBanner
        bgColor="bg-[#FEE7E0]"
        title="사육 일상을 공유하는 커뮤니티"
        content1="나의 파충류 사육 경험을 나누고"
        content2="다른 유저들과 소통해보세요."
        :imgSrc="require('@/assets/img/logo/community.png')"
      /> -->
      <div class="w-full h-10 ">
        <div class="w-full h-full flex justify-center items-center mx-auto bg-green-0 overflow-y-hidden md:hidden">
          <BoardCategory/>
        </div>
        
      </div>
      <div class="w-full h-2 bg-zinc-100 mt-2 blcok md:hidden"></div>

      <main class="relative flex-grow  flex ">
      <div class="flex pt-0  mx-auto max-w-[1280px] px-4 md:px-8 2xl:px-16 ">
        <div class="flex-shrink-0 hidden md:pe-24 pe-0 md:block w-96  bg-green-0 ">
          <BoardCategory/>

        </div>
        <!-- <div class="flex flex-col  bg-blue-50"> -->
        <router-view ></router-view>

      <!-- </div> -->

      </div>
    </main>
    <Section9Footer/>

    </div>
  </template>
  
  <script lang="ts" >
  // import BoardList from '@/components/Board/BoardList.vue';
  import NaviHeight from '@/components/Navigator/NaviHeight.vue';
  import BoardCategory from '@/components/Board/BoardCategory.vue';
  import TopNavigator from '@/components/Navigator/TopNavigator.vue'
import Section9Footer from '@/components/Landing/Section9Footer.vue'

  export default {
    components: {
        // BoardList,
        // SearchList,
        BoardCategory,
        NaviHeight,
        Section9Footer,
        TopNavigator,
        // TopBoardMenu,
    }
  }
  </script>

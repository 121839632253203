<template>
  <a id="kakaotalk-link-btn" @click="shareMessage">
    <!-- <div class="w-16 h-16 bg-[#FEE500] rounded-full flex justify-center items-center"> -->
          <img src="@/assets/img/logo/kakao.png" alt="" class="w-full object-cover">
      <!-- </div> -->
    <!-- <img src="https://developers.kakao.com/assets/img/about/logos/kakaotalksharing/kakaotalk_sharing_btn_medium.png"
      alt="카카오톡 공유 보내기 버튼" /> -->
  </a>
</template>

<script setup lang="ts">
import { onMounted, defineProps, ref } from 'vue';
const kakaoApi = process.env.VUE_APP_KAKAO_API
const templateId = process.env.VUE_APP_KAKAO_SHARE_ID
declare global {
  interface Window {
    Kakao: any;
  }
}
const props = defineProps({
  title : {type : String, required : true},
  date : {type : String, required : true},
  loca : {type : String, required : true},
  img : {type : String, required : true},
  link : {type : String, required : true},
});
const desc = ref(`${props.date} \n${props.loca}`);


const loadKakaoSDK = () => {
  return new Promise<void>((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.7.0/kakao.min.js';
    script.integrity = 'sha384-l+xbElFSnPZ2rOaPrU//2FF5B4LB8FiX5q4fXYTlfcG4PGpMkE1vcL7kNXI6Cci0';
    script.crossOrigin = 'anonymous';
    script.onload = () => resolve();
    document.head.appendChild(script);
  });
};
const title = ref(props.title)
const shareMessage = () => {
    window.Kakao.Share.sendCustom({
        // objectType: 'feed',
        templateId: 105773,
        templateArgs: {
            THU: props.img,
            PATH : props.link,
            DESC : desc.value,
            TITLE : props.title,

        },
    // content: {
    //   title: '딸기 치즈 케익',
    //   description: '#케익 #딸기 #삼평동 #카페 #분위기 #소개팅',
    //   imageUrl:
    //     'http://k.kakaocdn.net/dn/Q2iNx/btqgeRgV54P/VLdBs9cvyn8BJXB3o7N8UK/kakaolink40_original.png',
    //   link: {
    //     mobileWebUrl: 'https://padaapp.com',
    //     webUrl:'https://padaapp.com',
    //   },
    // },
    // buttons: [
    //   {
    //     title: '웹으로 보기',
    //     link: {
    //       mobileWebUrl:'https://padaapp.com/news/event/59',
    //       webUrl: 'https://padaapp.com/news/event/59',
    //     },
    //   },

    // ],
  });

};
const initKakao = () => {
  if (!window.Kakao.isInitialized()) {
    window.Kakao.init(kakaoApi);
  }
};


onMounted(async () => {
  await loadKakaoSDK();
  initKakao();
});

</script>
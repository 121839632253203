<template>
    <div class="flex flex-col min-h-screen bg-red-5 relative">
        <div class="mb-4 font-bold text-lg justify-between sm:mt-0 mt-4 bg-red-5">
            <h1 class="pl-2">갤러리</h1>    
        </div>
        <ul class="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 gap-x-3 lg:gap-x-5 xl:gap-x-7 gap-y-3 xl:gap-y-5 2xl:gap-y-8 sm:w-[768px] w-full">
            <li v-for="item in rst" :key="item.id"
            class="group box-border overflow-hidden flex rounded-md pe-0 pb-2 lg:pb-3 flex-col items-start transition duration-200 ease-in-out 
            transform shadow-product bg-white liWrap cursor-pointer"
            @click="galleryDetail(item)">
                <div class="img-container mb-3 md:mb-3.5 ">
                    <img :src="item.imgURL1" alt="" class="bg-gray-300 object-cover w-full transition duration-200 ease-in rounded-md" loading="lazy" decoding="async"
                            >
                </div>
                <div class="w-full h-full overflow-hidden ">
                        <div class=" text-xs text-sub">
                            {{ item.name }} &middot; {{ timeForToday(item.create_at) }}</div>
                            <div class="h-12 pr-2 pt-2 line-clamp-2 text-xs md:text-sm text-heading text-def">
                            {{ item.content }}
                        </div>

                        <div class=" text-xs text-sub flex space-x-2  ">
                          <img src="@/assets/img/logo/like.svg" alt="" class="mr-1" >  {{ item.like_cnt }}<img src="@/assets/img/logo/location.svg" alt="" class="mr-1"> {{ item.reply_cnt }}
                        </div>
                </div>
            </li>
        </ul>
        <div v-if="isBoardLoading" class="w-full justify-center items-center flex my-10"><LoadingCircle/></div>
        <div v-else class="w-full h-32 flex justify-center items-center">
            <button @click="loadMore" class="w-40 h-10 rounded-full border-zinc-500 border border-solid">더보기</button>
        </div>
        <div class="w-full h-40"></div>

    </div>
</template>
<script lang="ts" setup>
import { ref, watch, onMounted, computed, } from 'vue';
// import { SearchApi } from '@/api/api';
import { timeForToday } from '@/utils/timeForToday';
import LoadingCircle from '@/components/UI/LoadingCircle.vue'

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
const router = useRouter();
const store = useStore();

interface Item {
    id: number;
    title: string;
    name: string;
    imgURL1: string;
    content: string;
    price: number;
    create_at: string;
    location: string;
    like_cnt: number;
}

const clickedItem = ref<Item | null>(null); 


// 갤러리 변수
const communityList = computed(()=> store.state.community)
const isBoardLoading = ref(false);
const rst = computed(()=> store.state.currentCommunityList)

// 처음 접속 시, 전체 리스트를 출력함
onMounted(async () => {
    if(communityList.value[3].data.length === 0){
        await store.dispatch('fetchCommData', 3);
    }
    store.commit('SET_CURRENT_COMMUNITY', store.state.community[3].data)

})


const loadMore = async () => {
    isBoardLoading.value = true;
    await new Promise<void>(resolve => {
        setTimeout(() => {
            store.dispatch('fetchCommData', 3);
            resolve();
        }, 2100);
    });
    isBoardLoading.value =false;
};

// 상세보기 
const galleryDetail = (item :Item) => {
    // console.log(item.id)
    // clickedItem.value = item;
    router.push({ path: `community/${item.id}` });
};

// 상세보기 
// const postDetail = (item :Item) => {
//     clickedItem.value = item;
//     console.log(clickedItem.value)
//     router.push({ path: `reptile/detail/${item.id}` });
// };


</script>
<style scoped>

.img-container {
  overflow: hidden; /* 확대될 때 이미지가 컨테이너 밖으로 나가지 않도록 설정 */
  position: relative;
  width: 100%;
  padding-top: 100%; /* 비율 유지 */
}

/* 실제 이미지 */
.img-container img {
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0px;
  transition: transform 200ms ease-in-out; /* 부드러운 확대 효과 */
}
/* .list-gredient {
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 4rem;
    background: linear-gradient(to top, white, transparent)
} */

/* 마우스 호버 시 이미지 확대 */
/* .liWrap:hover .img-container img {
  transform: scale(1.1); 
} */

</style>

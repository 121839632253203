
<template>
    <div class="max-w-4xl  h-full mx-auto  flex-col sm:flex-row flex w-[896px]:px-0 px-10">
        <div class=" sm:w-1/2 flex flex-col sm:h-180 h-full justify-between transition-all duration-1000  opacity-0 w-full  py-10" 
        ref="elRef" :class="elClass">
            <TitleBox
                title="관리"
                content1="내 개체를 등록하면"
                content2="정보를 한 눈에"

            />
                <div class="flex flex-col justify-between  sm:mt-0 mt-10">
                    <div class="flex items-center mb-6 ">
                        <div class="w-8 h-8 lg:w-8 lg:h-10 bg-zinc-50 flex justify-center items-center rounded-lg mr-4">
                            <svg width="20" height="20" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 11.5H21" stroke="#51B6FF" stroke-width="4" stroke-linecap="round"/>
                                <path d="M11.5 21L11.5 2" stroke="#51B6FF" stroke-width="4" stroke-linecap="round"/>
                            </svg>
                        </div>
                        <p class="lg:text-2xl font-medium text-lg">부모 사진 추가</p>
                    </div>
                    
                    <div class="flex items-center mb-6">
                        <div class="w-8 h-8 lg:w-8 lg:h-10 bg-zinc-50 flex justify-center items-center rounded-lg mr-4">
                            <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 14.5L6 7L11.3793 10.1966L17.5 2" stroke="#FF5D71" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                        </div>
                        <p class="lg:text-2xl font-medium text-lg">몸무게 추이</p>
                    </div>

                    <div class="flex items-center mb-6">
                        <div class="w-8 h-8 lg:w-8 lg:h-10 bg-zinc-50 flex justify-center items-center rounded-lg mr-4">
                            <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.4692 6.42105L8.04224 11L18.4498 0.578949C18.8353 0.192984 19.3259 0 19.9216 0C20.5173 0 21.0079 0.192984 21.3934 0.578949C21.7789 0.964914 21.9807 1.44702 21.9989 2.02526C22.0158 2.60491 21.8314 3.08772 21.446 3.47369L9.51402 15.4211C9.12856 15.807 8.63796 16 8.04224 16C7.44652 16 6.95592 15.807 6.57046 15.4211L0.578201 9.42105C0.192734 9.03509 0 8.54386 0 7.94737C0 7.35088 0.192734 6.85965 0.578201 6.47368C0.963668 6.08772 1.44585 5.88632 2.02475 5.86948C2.60225 5.85123 3.08373 6.03509 3.4692 6.42105Z" fill="#FFEA30"/>
                            </svg>
                        </div>
                        <p class="lg:text-2xl font-medium text-lg">먹이 반응 체크</p>
                    </div>
                    <p class="text-lg sm:text-xl sm:leading-relaxed text-[#3E4044]
                        text-center sm:text-left leading-relaxed font-medium mt-10 lg:mt-20 ">반려 파충류의 정보를 카드로 <br>
                            기록하고 체계적으로 관리해요.
                    </p>
                </div>
                
                

            </div>
            <div class="group1 sm:w-1/2  w-full transition-all duration-1000 delay-300" :class="elClass">
                <img src="@/assets/img/download/sec5_phone.png " alt="" class="">
            </div>
        </div>
</template>

<script lang="ts">
import { defineComponent , ref, watch} from 'vue';
import useIntersectionObserver from '@/utils/v2IntersectionObserver';
import TitleBox from './TitleBox.vue'

export default defineComponent({
    name:'Section5Manage',
    components:{
        TitleBox
    },
    setup(){
        const elClass = ref('opacity-0 translate-y-10');
        const observerOptions = {
            root:null,
            rootMargin : '0px',
            threshold : 0.1
        };
        const {elementRef : elRef , isVisible : isVisible} = useIntersectionObserver(observerOptions);

        const updateVisibility = (isVisible , elClass)  =>{
            elClass.value = isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        };

        watch(isVisible, newVal => updateVisibility(newVal, elClass));

        return {
            elRef,
            elClass,
        }
    }

});

</script>


<template>
   <div v-if="writeLoading">
        <DimmedLoading/>
    </div> 
    <div class="w-[768px] h-full min-h-screen bg-red-5 ">
        <div class="my-4 font-bold text-lg justify-between flex">
            <h1 class="">{{ isEditMode ? '분양글 수정' : '분양글 쓰기'}}</h1>
            <!-- <select class="text-xs font-normal p-1 border rounded-md" >
                        <option value="latest">최신순</option>
                    </select> -->
        </div>
        <div class="w-full flex-wrap flex">
            <div class="flex flex-col">
                <label for="file">
                    <div class="btn-upload mr-4 mb-4">
                    <img src="@/assets/img/logo/camera.svg" alt="" class="w-6 h-6" />
                    <p class="text-xs mt-1 text-[#898989]">({{ thumbnail.length }}/10)</p>
                    </div>
                </label>
                <input type="file" name="file" id="file" @change="upload" multiple accept="image/*"/>
                <div v-if="thumbnail.length ===0" class="text-xs text-red-500 flex items-center">
                    <img src="@/assets/img/logo/warning.svg" alt="" class="w-3 h-3 mr-1"> <span>개체 사진을 등록해주세요!</span>
                </div>
            </div>

            <div
                v-for="(item, index) in thumbnail"
                :key="item.id"
                class="previewImg relative mr-4 mb-4"
                draggable="true"
                @dragstart="dragStart(index)"
                @dragover.prevent
                @drop="drop(index)"
            >
                <img :src="item" alt="" class="w-full h-full object-cover rounded-xl" />
            <button
            class="bg-[#17181a] w-6 h-6 rounded-full absolute -top-2 -right-2 flex justify-center items-center font-light cursor-pointer text-white"
            @click="deletePhoto(index)"
            >
                <span class="mt-[2px]">&times;</span>
            </button>
        </div>
    </div>

    <div class="w-full border-solid border-b border-zinc-100 my-8"></div>

    <WriteCategory 
        @update:jong="value => setFilter('jong', value)"
        @update:detail="value => setFilter('detail', value)"
    />
    <div v-if="nullMsg.jong" class="pl-24 text-xs text-red-500 flex items-center mb-4">
        <img src="@/assets/img/logo/warning.svg" alt="" class="w-3 h-3 mr-1"> <span> {{ nullMsg.jong }}</span>
    </div>

    <WriteAttribute
        :selectedSpecies="formData.jong" 
        :selectedGender="formData.gender"
        :selectedSize="formData.size"
        :selectedLocation="formData.location"
        :selectedLocationDetail="formData.locationDetail"
        @update:gender="value => setFilter('gender', value)"
        @update:size="value => setFilter('size', value)"
        @update:location="value => setFilter('location', value)"
        @update:locationDetail="value => setFilter('locationDetail', value)"
    />
    <div v-if="nullMsg.size || nullMsg.gender || nullMsg.location" class="pl-20 text-xs text-red-500 flex items-center my-4">
        <img src="@/assets/img/logo/warning.svg" alt="" class="w-3 h-3 mr-1"> <span>  {{ nullMsg.size }} {{nullMsg.gender}} {{nullMsg.location}}을(를) 선택해주세요. </span>
    </div>

    <div class="w-full border-solid border-b border-zinc-100 my-8"></div>

    <div class="flex flex-col space-y-4">
        <div class="flex items-center font-medium">
            <div class="w-20 text-lg">가격<span class="text-pada">*</span></div>
            <input
            class="w-1/2 h-10 pl-4 border border-solid text-sm font-normal border-[#D6D6D6] rounded-md placeholder:text-sm placeholder:text-zinc-300 placeholder:font-light            focus:border-[#1B1B1D]"
            placeholder="ex) 100000"
            type="number"
            v-model="formData.price"
            />
        </div>
        <div v-if="nullMsg.price" class="pl-20 text-xs text-red-500 flex items-center my-4">
            <img src="@/assets/img/logo/warning.svg" alt="" class="w-3 h-3 mr-1"> <span>  {{ nullMsg.price}} </span>
        </div>
        <div class="flex items-center font-medium">
            <div class="w-20  text-lg">제목<span class="text-pada ">*</span></div>
            <input
            class="w-11/12 h-10 pl-4 border border-solid border-[#D6D6D6] rounded-md text-sm font-normal placeholder:text-sm placeholder:text-zinc-300 placeholder:font-light
            focus:border-[#1B1B1D]"
            placeholder="제목을 입력해주세요"
            v-model="formData.title"
            />
        </div>
        <div v-if="nullMsg.title" class="pl-20 text-xs text-red-500 flex items-center my-4">
            <img src="@/assets/img/logo/warning.svg" alt="" class="w-3 h-3 mr-1"> <span>  {{ nullMsg.title}} </span>
        </div>
        <div class="flex items-start font-medium">
            <div class="w-20  mt-2 text-lg">내용<span class="text-pada">*</span></div>
            <textarea
            class="w-11/12 p-4 border border-solid border-[#D6D6D6] rounded-md resize-none text-sm font-normal placeholder:text-sm placeholder:text-zinc-300 placeholder:font-light            focus:border-[#1B1B1D]"
            cols="30"
            rows="10"
            placeholder="상세 내용을 입력해주세요"
            v-model="formData.content"
            ></textarea>
        </div>
        <div v-if="nullMsg.content" class="pl-20 text-xs text-red-500 flex items-center my-4">
            <img src="@/assets/img/logo/warning.svg" alt="" class="w-3 h-3 mr-1"> <span>  {{ nullMsg.content}} </span>
        </div>
    </div>


    <div class="w-full flex justify-end mt-8">
        <button class="w-28 h-10 bg-pada text-white text-sm rounded-lg" @click="submitData">{{ isEditMode ? '수정완료' : '등록'}}  </button>
    </div>
    <div class="w-full h-40"></div>
</div>
<div v-if="apiError">
<ConfirmModal 
content1=
"오류가 발생하였습니다.
잠시 후 다시 이용해주세요" 
     closeButton="닫기" @updateClose="handleApiError"/>
</div>
</template>
<script lang="ts" setup>
import { ref, reactive, computed, onMounted, isVNode } from 'vue';
import imageCompression from "browser-image-compression";
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { genderToText, formattedGender } from '@/utils/formattedUnit';
import { getUserId } from '@/utils/Functions';
import {  SearchApi } from '@/api/api';
import WriteCategory from './WriteCategory.vue';
import WriteAttribute from './WriteAttribute.vue';
import DimmedLoading from '../UI/DimmedLoading.vue';
import ConfirmModal from '../UI/ConfirmModal.vue';
const router = useRouter();
const route = useRoute();
const store = useStore();
const editData = computed(() => store.state.clickedAdopt)
const isEditMode = computed(() => route.path.includes('/edit'))
const postId = route.params.encodedId;
const changedImgStatus = ref(false);
const userId = getUserId();
const testMsg = ref('');
const apiError = ref(false);
const writeLoading =ref(false);
const thumbnail = ref([ ]);
const draggedIndex = ref(null);
const initImg = ref([ ]);
const formData = reactive({
    user_id : null,
    jong: null,
    detail: [],
    gender: '',
    size: null,
    location: null,
    locationDetail: null,
    title: null,
    content: null,
    price: null,
    images :[],
    ztype :'',
});
const nullMsg = reactive({
    jong: '',
    gender: '',
    size: '',
    location: '',
    title: '',
    content: '',
    price: '',
    images : '',
})

const dragStart= (index) => {
    draggedIndex.value = index;
}
const drop = (index) => {
    const dragIndex = draggedIndex.value
    const draggedElement = thumbnail.value[dragIndex];
    thumbnail.value.splice(dragIndex, 1);
    thumbnail.value.splice(index, 0, draggedElement);

    // formData.images 배열의 요소도 동일하게 재정렬
    const draggedImage = formData.images[dragIndex];
    formData.images.splice(dragIndex, 1);
    formData.images.splice(index, 0, draggedImage);
}

// 이미지 압축하기 
const compressImage = async (file) => {
    const options = {
        maxSizeMB: 0.7,
        maxWidthOrHeight: 1200,
        useWebWorker: true,
    };
    return await imageCompression(file, options);
};
// 이미지 업로드 
const upload = async (e: Event) => {
    const input = e.target as HTMLInputElement;
    if (input.files) {
        const files = Array.from(input.files).slice(0, 10); // 최대 10개의 파일만 선택
        for (let file of files) {
            const url = URL.createObjectURL(file);
            thumbnail.value.push(url); // 미리보기용 URL 추가
            formData.images.push(file); // 실제 파일을 formData에 추가
        }
    }
};

const deletePhoto = (index) => {
    thumbnail.value = thumbnail.value.filter((_, idx) => idx !== index);
    formData.images = formData.images.filter((_, idx) => idx !== index);
};

// 등록 버튼
const submitData = async () => {
    if(!isEmpty()) {
        return;
    } 
    writeLoading.value = true;
    formData.location = formData.locationDetail ?  `${formData.location}/${formData.locationDetail}` : formData.location

    try {
        if(!isEditMode.value) {
            await submitIncludeImage(); // 처음 글 등록
            testMsg.value = '처음 올리는 게시글'
            // console.log('처음 올리는 게시글');
        } else if (checkChangeImg()) {
            await editChangedImg(); // 이미지와 같이 바꾸기
            testMsg.value = '이미지와 같이 바꾸기'
            // console.log('이미지와 같이 바꾸기')
        } else {
            await editOnlyText(); // 텍스트만 바꾸기
            testMsg.value = '텍스트만 바꾸기'
            // console.log('텍스트만 바꾸기')
        }

        await store.dispatch('resetReptileList');
        // console.log('success, reptile 이동')
        router.push('/reptile')

    } catch (error) {
        console.error('게시물 등록 중 오류 발생:', error);
        apiError.value = true;
    } finally {
        // 오류가 발생하든 말든, 항상 실행되는 부분
        writeLoading.value = false;
    }
};
const handleApiError = () =>{
    apiError.value = false;
    router.push('/reptile')
}

// 게시글 처음 등록 할 때!!!
const submitIncludeImage = async () => {
    const form = new FormData();
    // 기본 필드 추가
    form.append('jong', formData.jong);
    form.append('detail', formData.detail.join());
    form.append('ztype', formData.ztype);
    form.append('rsex', genderToText(formData.gender));
    form.append('size', formData.size);
    form.append('location',  formData.location);
    form.append('price', formData.price);
    form.append('title', formData.title);
    form.append('content', formData.content);
    form.append('user_id', userId);


    // 이미지 파일 추가
    for (const [index, image] of formData.images.entries()) {
        // 0번 인덱스 이미지 파일, 압축하고 썸네일로 복사하기
        const compressedFile = await compressImage(image);
        if(index === 0){
            try {
                // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);
                form.append('uploadfiles', compressedFile, `${image.name}_thumbnail`);
            } catch (error) {
                console.log(error);
            }
        }
        form.append('uploadfiles', compressedFile, image.name);
    }

    // 서버에 FormData 전송
    try {
        const response = await SearchApi.addAdoptImages(form);
    } catch (error) {
        console.error(error);
    }
};

// // 게시글 only 텍스트
const editOnlyText = async () => {
    //서버에 FormData 전송
    try {
        const response = await SearchApi.editAdoptText(
            {
                post_id: postId,
                user_id: userId, 
                jong: formData.jong,
                detail: formData.detail.join(),
                rsex: genderToText(formData.gender), // 미구분 => 0으로 바뀜
                size: formData.size,
                location: formData.location,
                title: formData.title,
                content: formData.content,
                price: formData.price,
                ztype: formData.ztype,
            }
        );
    } catch (error) {
        console.log(error)
    }
};


const checkChangeImg =  () => {
    if(editData.value){
        const originCnt = editData.value.image_cnt - 1
        const changeCnt = formData.images.length // 2
        if(originCnt !== changeCnt) return changedImgStatus.value = true;
        
    }
    let originArr = [...initImg.value]
    let compareArr = [...formData.images];
    // for(let i = 1; i <= changeCnt ; i++){
    //     compareArr.push(formData.images[`imgURL${i}`])
    // }
    // console.log(originArr.toString())
    // console.log(compareArr.toString())
    if(originArr.toString() !== compareArr.toString()){
        return changedImgStatus.value = true;

    } else{
        return changedImgStatus.value = false;

    }
}

// url을 blob으로 바꾸기
async function urlToBlob(url) {
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error('Network response was not ok.');
    }
    return await response.blob();
}

const editChangedImg = async () => {
    const form = new FormData();
    // 기본 필드 추가
    form.append('post_id', postId.toString())
    form.append('jong', formData.jong);
    form.append('detail', formData.detail.join());
    form.append('ztype', formData.ztype);
    form.append('rsex', genderToText(formData.gender));
    form.append('size', formData.size);
    form.append('location', formData.location);
    form.append('price', formData.price);
    form.append('title', formData.title);
    form.append('content', formData.content);
    form.append('user_id', userId);

    // 이미지 파일 추가
    for (const [index, image] of formData.images.entries()) {

        if (image instanceof File) {
            try {
                const compressedFile = await compressImage(image);
                // 첫 번째 이미지의 경우, 썸네일 버전 추가
                if (index === 0) {
                    form.append('uploadfiles', compressedFile, `${image.name}_thumbnail.jpg`);
                }
                // 모든 이미지 파일 추가
                form.append('uploadfiles', compressedFile, image.name);
            } catch (error) {
                console.log(error);
                // 사용자에게 오류 메시지 표시 (예: UI 알림)
            }
        } else if (typeof image === 'string') {
            try {
                const imageBlob = await urlToBlob(image);
                const imageName = image.split('/').pop();

                // 첫 번째 이미지의 경우, 썸네일 버전 추가
                if (index === 0) {
                    form.append('uploadfiles', imageBlob, `${imageName}_thumbnail.jpg`);
                }
                // URL에서 파일 이름 추출
                form.append('uploadfiles', imageBlob, imageName);
            } catch (error) {
                console.log(error);
                // 오류 처리
        }
    }
}

    // 서버에 FormData 전송
    try {
        const response = await SearchApi.editAdoptImages(form);
    } catch (error) {
        console.error(error);
    }

}


const isEmpty = () => {
    nullMsg.title = (!formData.title || formData.title.length < 4) ? '제목은 4글자 이상 입력해주세요.' : '';
    nullMsg.content = (!formData.content || formData.content.length < 4) ? '내용을 4글자 이상 입력해주세요.' : '';
    nullMsg.jong = formData.jong ? '' : '종을 선택해주세요.';
    nullMsg.gender = formData.gender ? '' : '성별';
    nullMsg.location = formData.location ? '' : '지역';
    // formData.jong이 특정 카테고리에 해당하는 경우 size의 empty 값을 무시
    if (formData.jong === '사육장' || formData.jong === '먹이' || formData.jong === '기타 용품' || formData.jong === '도서') {
    // 사이즈 필드 검사를 생략하거나 다른 로직 적용
        nullMsg.size = '';
    } else {
    // 그 외의 경우에는 사이즈가 비어있는지 확인
        nullMsg.size = formData.size ? '' : '사이즈';
    }
    // nullMsg.title = (formData.title ?? '').length > 4 ? '제목은 4글자 이상 입력해주세요.' : '';
    // nullMsg.content = formData.content ? '' : '내용을 입력해주세요.';
    nullMsg.price = formData.price && formData.price < 1000000000 ? '' : '가격을 입력해주세요';
    // formData.images.length !== 0 ?  '' : '이미지 오류';
    nullMsg.images = formData.images.length > 0 ? '' : '이미지를 최소 1개 이상 업로드해주세요.';
    // console.log(!Object.values(nullMsg).some(msg => msg !== ''))

    return !Object.values(nullMsg).some(msg => msg !== ''); // true 이면 모든 칸 다 차있음
}

// setFilter 함수를 사용하여 formData 객체 업데이트
const setFilter = (type: string, newValue: string | string[]) => {
    formData[type] = newValue;
    // console.log('setFilter : ',type, formData[type] )
};
onMounted(() => {
    // store.dispatch('viewAdopt', postId);
    // console.log('check', editData.value)
    if(isEditMode.value ){
    // if(isEditMode.value && checkUserId()){
        setFilter('gender', formattedGender(editData.value.rsex)); // 0 => 미구분, 1 => 수컷 
        setFilter('size', editData.value.size);
        formData.price = editData.value.price;
        formData.title = editData.value.title;
        formData.content = editData.value.content;

        let location;
        let locationDetail;

        if (editData.value.location.includes('/')) {
            const parts = editData.value.location.split('/');
            location = parts[0]; // '서울'
            locationDetail = parts[1]; // '강남'
            setFilter('location', location);
            setFilter('locationDetail', locationDetail)
        } else {
            location= editData.value.location
            setFilter('location', location);
            
        }
        
        
        for (let i = 1; i < editData.value.image_cnt; i++){
            formData.images.push(editData.value[`imgURL${i}`])
            thumbnail.value.push(editData.value[`imgURL${i}`])
            initImg.value.push(editData.value[`imgURL${i}`]);
        }

    }
})


</script>

<style scoped>
.btn-upload {
    width: 8rem;
    height: 8rem;
    background: #fff;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 8px rgba(8, 50, 25, 0.13);
}
.isSelected {
    background-color: #e0f9f1;
    color: #00d191;
}
.previewImg {
width: 8rem;
height: 8rem;
}
#file {
display: none;
}
input:focus,
textarea:focus {
outline: none;
}
</style>

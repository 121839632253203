<template>
    <div class="max-w-4xl  h-auto mx-auto  w-[896px]:px-0 px-10" >
        <div
        ref="elRef1"
        class="transition-all duration-1000 w-full opacity-0 "
        :class="elClass1">
            <TitleBox
                    title="분양"
                    content1="채팅으로 거래하고"
                    content2="안전하게 결제까지"
                />
        </div>

        <div class="container  mt-10  w-full transition-all duration-1000 " :class="elClass2" ref="elRef2" >
            <div class="group1 sm:-translate-x-12 ">
                <img src="@/assets/img/download/sec2_phone1.png" alt="" width="100%" height="100%" class="w-full h-full object-contain">
            </div> 
            <div class="col2  " > 
                <img src="@/assets/img/download/sec2_card.png" alt="" width="100%" height="100%" class="w-full h-full object-contain">
            </div> 
            <div class="group2 ">
                <img src="@/assets/img/download/sec2_phone2.png" alt="" width="100%" height="100%" class="w-full h-full object-contain">
            </div> 
            <div class="col1 font-medium  mb-40 text-lg sm:text-xl sm:leading-relaxed  
                text-center sm:text-left
            ">
                <p class="text-[#3E4044]">게코, 도마뱀, 뱀, 거북을 비롯해 <br>
                양서류, 절지류까지 쉽고 빠르게 거래해요.</p>
            </div> 
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import TitleBox from './TitleBox.vue';
import useIntersectionObserver from '@/utils/v2IntersectionObserver';

export default defineComponent({
    name: 'SectionAdopt',
    components: {
        TitleBox
    },
    setup() {
        const elClass1 = ref('opacity-0 translate-y-10');
        const elClass2 = ref('opacity-0 translate-y-10');
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };

        const { elementRef: elRef1, isVisible: isVisible1 } = useIntersectionObserver(observerOptions);
        const { elementRef: elRef2, isVisible: isVisible2 } = useIntersectionObserver(observerOptions);

        const updateVisibility = (isVisible, elClass) => {
            elClass.value = isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10';
        };

        watch(isVisible1, newVal => updateVisibility(newVal, elClass1));
        watch(isVisible2, newVal => updateVisibility(newVal, elClass2));

        return {
            elRef1,
            elClass1,
            elRef2,
            elClass2
        };
    }
});
</script>
<style scoped>
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2개의 열 */
  grid-template-rows: repeat(5, auto); /* 5개의 행 */
  grid-template-areas: 
    "group1 col2"
    "group1 group2"
    "col1 group2";
  gap: 8rem; /* 셀 사이의 간격 */
  

}
@media (max-width: 640px) {
  .container {
    grid-template-columns: 1fr; /* 모바일 화면에서는 한 컬럼으로 */
    grid-template-rows: auto; /* 필요한 만큼의 행 */
    grid-template-areas: 
      "group1"
      "col2"
      "group2"
      "col1";
      gap: 2rem; /* 셀 사이의 간격 */
  }
}

.group1 {
    grid-area: group1;
}

.group2 {
  grid-area: group2;
}

.col1 {
  grid-area: col1;
}

.col2 {
  grid-area: col2;
}



</style>
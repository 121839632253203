<template>
    <div class="flex flex-col">
        <div class="mx-auto font-bold text-lg mb-4 ">박람회/이벤트 소식</div>
        <Swiper 
            :navigation="{
                enabled:true,
                nextEl: '.swiper-button-next-custom-2',
                prevEl: '.swiper-button-prev-custom-2'
            }"
            :slidesPerView="1"
            :spaceBetween="30"
            :loop="true"
            :breakpoints="{
                786 : {
                    slidesPerView : 3,
                }
            }"
            class="w-full h-auto ">
                <SwiperSlide v-for="event in eventData" :key="event.id"
                    class="box flex flex-col md:h-96 relative"                    
                    style="border: 1px solid #EDEFF1; border-radius: 10px;">
                    <div>
                            <div class="w-20 h-10 absolute top-0 px-4 bg-black text-white flex items-center justify-center text-sm font-bold"> 
                                <div class="py-auto">{{ event.dDayLabel }}</div>
                            </div>
                            <div class="w-full h-60 ">
                                <img :src=" event.poster " alt="" class="w-full h-full object-cover object-center rounded-t-[10px] ">
                            </div>
                            <div class="px-2 relative w-full h-36  ">
                                <div class="mt-3 text-[#4F4F4F] text-sm">{{ event.date }}</div>
                                <div class="mt-2 font-medium text-lg text-def">{{ event.title }}</div>
                                <div class="mt-2 text-sm text-[#676767]">{{ event.location }}</div>
                            </div>
                    </div>
                </SwiperSlide>
        </Swiper>
        <div class="swiper-button-next-custom-2">
            <img src="@/assets/img/logo/arrow.svg" alt="" class="rotate-180">
        </div>
        <div class="swiper-button-prev-custom-2">             
            <img src="@/assets/img/logo/arrow.svg" alt="">
        </div>
        




        
    </div>
</template>
<script >
import {defineComponent} from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import data from '@/assets/data/exhibition.json'

import 'swiper/css';
import 'swiper/css/navigation';
import SwiperCore, {Navigation, Autoplay } from "swiper";
  SwiperCore.use([  Navigation, Autoplay,  ]);

export default defineComponent({
    name: 'EventAnnounce',
    data(){
        return{
            eventData : data.slice(0, 6),
        }
    },
    components: {
      Swiper,
      SwiperSlide,

    },
    setup() {
        return{
            modules : [Navigation],
        }
        
    },
    methods: {

        diffDay(targetDay ) {
            // let targetDay = targetDiv.innerHTML;
            
            let startDayStr = targetDay.slice(0, 10).replace(/\./g, '-'); // YYYY-MM-DD format으로 변경
            let startDay = new Date(startDayStr);
            let endDay = startDay;

            // 종료 날짜가 있다면 처리
            if (targetDay.includes('~')) {
                let endDayStr = startDayStr.slice(0, 8) + targetDay.slice(14, 16).replace(/\./g, '-');
                endDay = new Date(endDayStr);
            }
            endDay.setHours(23, 59, 59, 999);
            const today = new Date();

            const diff = startDay.getTime() - today.getTime();
            const days = Math.ceil(diff / (1000 * 60 * 60 * 24));  // 밀리초를 일로 변환
            if (today < startDay) {
                // 행사 시작 전
                return `D-${days}`;
            } else if (today <= endDay ) {
                // 행사 진행 중
                return '진행중';
            } 
            else if(endDay === startDay && days === 0){
                // 1일 행사, d-0인 경우 진행 중 출력
                return '진행중';
            }
            else {
                // 행사 종료 후
                return '종료';
            }
        },
        calcDday() {
            for (let event of this.eventData) {
                event.dDayLabel = this.diffDay(event.date);
            }
        }
    },
    mounted() {
        this.calcDday();

        
    },
    
})
</script>
<style scoped>
/* Swiper 네비게이션 버튼 스타일 */
.swiper-button-next-custom-2,
.swiper-button-prev-custom-2 {
    position: absolute;
    top: calc(50% - 2rem);
    background-color: white;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: #838383;
    box-shadow: 0px 0px 20px rgba(8, 50, 25, 0.12);
    cursor: pointer;
    @media (max-width: 768px) {
        width: 1rem;
        height: 1rem;
    }
}
.swiper-button-next-custom-2{
    right: 3%;
}
.swiper-button-prev-custom-2{
    left: 3%;
}
.box{
box-sizing: border-box;
background: #FFFFFF;
border: 1px solid #EDEFF1;
border-radius: 10px;

}


    
</style>
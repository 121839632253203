export function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function getTokenRemainingTime(token) {
    const decodedToken = parseJwt(token);
    const currentTime = Date.now() / 1000 ; // 현재 시간을 초 단위로
    const remainingTime = Math.floor((decodedToken.exp - currentTime) / 60 ); 
    
    if(remainingTime < 0){
        return '세션이 만료되었습니다'
    } else {
        return `${remainingTime}분 후 세션이 종료됩니다.`;

    }
    


}

// // 사용 예시
// const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoicGFkYW1hc3RlciIsImlhdCI6MTcwNDg1MjU4NiwiZXhwIjoxNzIwNDA0NTg2fQ.t6VBW4Ca0q8W_vcArwi3SyzyGDR2ouSRkwpR5xcsfB8";
// const decoded = parseJwt(token);
// console.log(decoded.user_id); // padamaster

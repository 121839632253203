<template>
    <div class="flex flex-col ">
        <div class="mx-auto font-bold text-lg mb-4 ">양서파충류 공식 채널</div>

        <Swiper :navigation="{
            enabled: true,
            nextEl: '.swiper-button-next-custom',
            prevEl: '.swiper-button-prev-custom'
        }" :slidesPerView="1" :spaceBetween="30" :loop="true" :breakpoints="{
    786: {
        slidesPerView: 2,
    }
}" class="w-full h-auto ">
            <SwiperSlide v-for="post in infoData" :key="post.id" class="box flex flex-col h-full"
                style="border: 1px solid #EDEFF1; border-radius: 10px;">
                <div>
                    <div class="w-full h-60 ">
                        <img :src="post.imgURL1" alt="" class="w-full h-full object-cover object-center rounded-t-[10px]">
                    </div>
                    <div class="px-3 relative w-full pt-2  h-32 ">

                        <div class="mt-2 font-medium text-lg text-def">
                            {{ post.title1 }}
                        </div>
                        <div class="mt-4 text-sm text-[#676767]">{{ post.title2 }}</div>
                        <div class="absolute bottom-4 text-sm text-[#A4A4A4]">{{ post.update_at }}</div>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
        <div class="swiper-button-next-custom">
            <img src="@/assets/img/logo/arrow.svg" alt="" class="rotate-180">
        </div>
        <div class="swiper-button-prev-custom">
            <img src="@/assets/img/logo/arrow.svg" alt="">
        </div>

    </div>
</template>
<script >
import { defineComponent, computed } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import data from '@/assets/data/association.json'
import { useStore, mapActions } from 'vuex';

import 'swiper/css';
import 'swiper/css/navigation';
import SwiperCore, { Navigation, Autoplay } from "swiper";
SwiperCore.use([Navigation, Autoplay,]);

export default defineComponent({
    data() {
        return {
            // infoData : data
        }
    },

    components: {
        Swiper,
        SwiperSlide,

    },
    methods :{
        ...mapActions(['fetchChannleData']),
    },
    setup() {

        const store = useStore();

        const infoData = computed(() => {
            const allData = store.getters.allChannelData;
            // console.log(allData)
            return allData.filter(data => data.category === 2);
        });
        const test = store.getters.allChannelData
        // console.log(test)

        return {
            modules: [Navigation],
            infoData
        }

    },
    async mounted() {
        await this.fetchChannleData()
    }

})
</script>
<style scoped>
/* Swiper 네비게이션 버튼 스타일 */
.swiper-button-next-custom,
.swiper-button-prev-custom {
    position: absolute;
    top: calc(50% - 2rem);
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: #838383;
    box-shadow: 0px 0px 20px rgba(8, 50, 25, 0.12);
    cursor: pointer;
    ;

    @media (max-width: 768px) {
        width: 1rem;
        height: 1rem;
    }

}

.swiper-button-next-custom {
    right: 3%;
}

.swiper-button-prev-custom {
    left: 3%;
}

.box {
    box-sizing: border-box;
    background: #FFFFFF;


}
</style>
<template>
    <metaInfo>
    </metaInfo>
  <div class="relative">
    <router-view></router-view>
    <!-- <div :class="{ 'hidden': isMobile }">
      <TopNavigator/>
      <Section9Footer/>
    </div> -->

  </div>
</template>

<script lang="ts">
import TopNavigator from './components/Navigator/TopNavigator.vue'
import Section9Footer from './components/Landing/Section9Footer.vue'
import {  analytics, app } from './utils/fbase'
import { useRoute } from 'vue-router';

import { defineComponent, isMemoSame, onMounted, ref, watch } from 'vue';
import { useMeta } from 'vue-meta'
export default defineComponent({
  name: 'App',
  components: {
    // TopNavigator,
    // Section9Footer,
},
setup () {
  const route = useRoute();
  const isEventPage = ref(false);
  const isMobile = ref(false);
    useMeta({
      title: '파충류 다 있다 | 세상의 모든 파충류는 여기에!',
      htmlAttrs: { lang: 'ko', amp: true },
      meta: [
        { vmid: 'description', property: 'description', content: '세상의 모든 파충류는 여기에! 파충류 다 ~ 있다는 #분양 #관리 #커뮤니티 #정보 한 번에 가능한 파충류 종합 어플리케이션이에요.' },
        { vmid : 'keywords', property : 'keywords', content : "크레스티드 게코, 가고일 게코, 리키에너스 게코, 차화 게코, 레오파드 게코, 납테일 게코, 펫테일 게코, 토케이 게코, 기타 게코, 비어디 드래곤, 모니터, 테구, 스킨크, 이구아나, 기타 도마뱀, 볼파이톤, 파이톤,보아, 킹스네이크,밀크스네이크, 호그노즈, 콘스네이크, 기타 뱀, 수생/반수생 거북, 습지 거북, 육지 거북, 테라핀, 뉴트, 살라만다, 토드, 프록, 기타 양서류, 등각류, 타란튤라, 센티페드, 전갈, 밀리페드, 기타 절지류"},
        { vmid: 'og:type', property: 'og:type', content: 'article' },
        { vmid: 'og:site_name', property: 'og:site_name', content: '파충류 다 있다' },
        { vmid: 'og:title', property: 'og:title', content: '파충류 다 있다 | 세상의 모든 파충류는 여기에!' },
        { vmid: 'og:description', property: 'og:description', content: '세상의 모든 파충류는 여기에! 파충류 다 ~ 있다는 #분양 #관리 #커뮤니티 #정보 한 번에 가능한 파충류 종합 어플리케이션이에요.' },
        { vmid: 'og:image', property: 'og:image', content: 'https://pada.s3.ap-northeast-2.amazonaws.com/paper/images/ogImg.png' }
      ]
    })

    onMounted(() => {
      console.log(app.name)
      // isMobile.value = window.matchMedia('(max-width: 640px)').matches;
    });
    watch(() => route.path, (newPath) => {
      isMobile.value = newPath.includes('/mobile');
      },
      { immediate: true } 
    );


  return {
      isEventPage,
      isMobile,
    };
  }

});
// <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">

</script>

<style>
/* #app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
</style>

<template >
    <div v-if="isLoading">
        <div class="w-full pt-40 justify-center items-start flex"><LoadingCircle/></div>
    </div>
    <div v-else class="md:w-[768px] min-h-screen w-full  -mt-4 sm:mt-0" >
        <div class="w-full flex justify-center items-center ">
            
            <Swiper
            @slideChange="onSlideChange"
            :loop=false
            :navigation="{
                enabled: true,
                nextEl: '.swiper-button-next-custom',
                prevEl: '.swiper-button-prev-custom'
            }"
            :pagination="{ 
                clickable: true, 
                el: '.swiper-pagination', 
            }"
            class="w-full sm:h-160 h-100">


            <!-- <div class="swiper-button-prev-custom z-50 bg-black ml-2 test" v-show="currentSlide !== 0" :style="imgArr.length > 1 ? '' : 'display: none'">               
                <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="test">
                    <path d="M6.75 1.58317L1.25 7.99984L6.75 14.4165" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div> -->
            <!-- <div class="swiper-button-next-custom  " v-show="!isLastSlide" :style="imgArr.length > 1 ? '' : 'display: none'">
                <div>
                    asdfasdf
                </div>
                <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="rotate-180 test">
                    <path d="M6.75 1.58317L1.25 7.99984L6.75 14.4165" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div> -->
            <div class="swiper-button-prev-custom w-8 h-8 absolute top-1/2 left-3  justify-center flex items-center cursor-pointer" 
            style="z-index: 999;" v-show="currentSlide !== 0" :style="imgArr.length > 1 ? '' : 'display: none'">
                <div class=" w-full h-full bg-black opacity-40 rounded-full absolute top-0"></div>
                <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg" 
                class="rotate-0 ">
                    <path d="M6.75 1.58317L1.25 7.99984L6.75 14.4165" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>

            <div class="swiper-button-next-custom w-8 h-8 absolute top-1/2 right-3  justify-center flex items-center cursor-pointer" 
            style="z-index: 999;"  v-show="!isLastSlide" :style="imgArr.length > 1 ? '' : 'display: none'">
                <div class=" w-full h-full bg-black opacity-40 rounded-full absolute top-0"></div>
                <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg" 
                class="rotate-180 ">
                    <path d="M6.75 1.58317L1.25 7.99984L6.75 14.4165" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>


                <SwiperSlide
                    v-for="(post) in imgArr" :key="post">
                    <div v-if="postItem.del_flag === 9 || postItem.del_flag === 7" class="absolute top-0  w-full h-full rounded-md flex justify-center items-center text-white">
                        <div class="w-full h-full bg-black opacity-40 rounded-md"></div>
                        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  font-medium"> {{ postItem.del_flag === 9 ? '판매완료' : '예약중'  }} </div>
                    </div>
                    <a :href="post" target="_blank">
                        <div class="w-full h-full flex justify-center ">
                            <img :src=" post " alt="" class="w-full object-cover sm:rounded-lg">
                        </div>
                    </a>
                </SwiperSlide> 
                <div class="swiper-pagination  "></div>


            </Swiper>

        </div>




        <div class="flex justify-between w-full mt-4 px-4 sm:px-0">
            <div class="flex items-center mb-4 ">
                <div class="w-10 h-10 mr-2 rounded-full">
                    <img :src="postItem.img !== 'b' ? postItem.img : require('@/assets/img/logo/defaultProfile.svg')" alt="" class="w-full h-full rounded-full object-cover">
                </div>
                {{ postItem.name }}
            </div>
            <div v-if="checkUserId()">
                <div  
                class="border-[#A3A7B0] border border-solid cursor-pointer text-sm flex justify-center items-center sm:w-[7.5rem] sm:h-11 font-bold rounded-md text-[#646669] sm:pe-2
                w-16 h-8" 
                @click="editPost">
                    <img src="@/assets/img/logo/pencil.svg" alt="" class="w-4 mr-2 hidden sm:block">
                    글수정
                </div>

            </div>

        
            <div v-else 
            class="cursor-pointer text-sm sm:hidden flex justify-center items-center sm:w-[7.5rem] sm:h-11 font-bold rounded-md text-[#646669] sm:pe-2
            w-8 h-8" 

            @click="shareContent">
                <img src="@/assets/img/logo/share.svg" alt="" class="">
            </div>
        </div>
        <div class="px-4 sm:px-0">
            <div class="flex w-full justify-between ">
            <h1 class="text-lg mb-[3px] ">{{ postItem.title }}</h1>

                <div  v-if="checkUserId()" class=" flex h-auto text-sm text-[#646669] ">
                    <select v-model="selectedFlag" @change="handleModal" class="border-[#A3A7B0] border border-solid rounded-md pl-2  ">
                        <option value="0">판매중</option>
                        <option value="7">예약중</option>
                        <option value="9">판매완료</option>
                    </select>
                </div>
            </div>
            <h2 class="text-xl font-semibold tracking-wide">{{ formattedPrice(postItem.price) }}원</h2>
        </div>

        <div class="infoBox space-y-2 ">
            <div class="flex">
                <p class="w-12 text-[#A4A4A4]">종</p>
                <p class="text-[#676767]">{{ postItem.jong }}</p>
            </div>
            <div class="flex">
                <p class="w-12 flex-shrink-0 text-[#A4A4A4]">모프</p>
                <div class="flex-row">
                    <span v-for="item in spliteDetail(postItem.detail)" :key="item.id"
                        style="word-break: break-all;"
                        class="text-[#676767]"
                        >
                        {{`${ item }, `}}
                    </span> 
                </div>
            </div>
            <div class="w-full h-1 border-[#E3E6EA] border-b border-solid my-3"></div>
            <div class="flex">
                <p class="w-12 text-[#A4A4A4]">정보</p>
                <p  class="text-[#676767]">{{  postItem.rsex !== 9 ? `${formattedGender(postItem.rsex)} &bull;` : '' }} 
                {{ postItem.size ? `${postItem.size} &bull;` : '' }}  {{ postItem.location }}</p>
            </div>
        </div>


        
        <p class="whitespace-pre-wrap text-[#101010] text-base w-full px-4 sm:px-0 ">{{ postItem.content }}</p>

        <p class="text-end text-sub text-sm mr-4 mt-4 ">{{ timeForToday(postItem.created_at)}}</p>
        <!-- <div v-for="(url, index) in imgArr" :key="index" class="w-full h-160 my-4">
            <img :src="url" :alt="'Image ' + (index + 2)" class="w-full h-full object-cover rounded-lg">
        </div> -->
        <div class="bg-zinc-50 w-full h-1 my-8"></div>
        <div class="border border-solid border-[#d8d9dc] text-sub w-20 h-8 text-xs justify-center flex items-center rounded-md mt-4 cursor-pointer ml-4 sm:ml-0"
        @click="backBtn">
           <span class="mr-1 "> &lt;</span> 목록으로
        </div>
        <GoQRcode/>

        <div v-if="showAppDownloadPopup">
            <SuggestModal :storeUrl="appDownloadLink" @closePopup="handlePopup"/>
        </div>

        <div v-if="isOpen" > 
            <ConfirmModal :content1="`게시글을 ${sellingStatus(selectedFlag)}로 처리할까요?`" 
            okayButton="확인" 
            closeButton="닫기" 
            @updateClose="handleModal" 
            @updateOkay="updateFlag"/>
        </div>

        
        <div class="w-full lg:h-40 h-20"></div>


    </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, ref, watchEffect , watch} from 'vue';
import { useStore } from 'vuex';
import { timeForToday } from '@/utils/timeForToday';
import { formattedPrice, formattedGender, sellingStatus } from '@/utils/formattedUnit';
import { useRouter, useRoute } from 'vue-router';
import { getUserId  } from '@/utils/Functions';
import LoadingCircle from '../UI/LoadingCircle.vue';
import SuggestModal from './SuggestModal.vue';
import ConfirmModal from '../UI/ConfirmModal.vue';
import { encodeBase62 , decodeBase62 } from '@/utils/idConverter'
import { SearchApi } from '@/api/api';
import { Swiper, SwiperSlide } from 'swiper/vue';
import GoQRcode from '../UI/GoQRcode.vue';
import 'swiper/css';
import 'swiper/css/navigation';
import SwiperCore, {Pagination,  Autoplay } from "swiper";
import { useMeta } from 'vue-meta';
  SwiperCore.use([ Pagination,  Autoplay,  ]);

interface PostItem {
  image_cnt: number;
  [key: string]: any; // 다른 동적 속성을 위한 인덱스 시그니처
}

const store = useStore();
const router = useRouter();
const route = useRoute();
// const postId = route.params.encodedId
const postId = decodeBase62(route.params.encodedId)
const showAppDownloadPopup = ref(false);
const appDownloadLink = ref('');
const postItem = computed(() => store.state.clickedAdopt || []) ;
// const postItem = computed(() => store.getters.getClickedPost || []) ;
const isLoading = computed(() => store.state.isLoading);
const selectedFlag = ref<string>(postItem.value.del_flag.toString() || "0")
const isOpen = ref(false)
const handleModal = () => {
    isOpen.value = !isOpen.value;
}   
const backBtn = () => {
  if (window.history.length > 2) {
    router.go(-1);
  } else {
    router.push('/reptile');
  }
}

const isLastSlide = computed(() => {
    return currentSlide.value === imgArr.value.length - 1;
});
const currentSlide = ref(0);
const onSlideChange = (swiper) => {
    currentSlide.value = swiper.realIndex; // 현재 슬라이드 인덱스 업데이트
};
const updateFlag = async () => {
    const userId = getUserId();
    try{
        await SearchApi.updateDelFlag(postId, selectedFlag.value, userId);
    }catch(error) {
        console.log(error)
    }
    finally{
        isOpen.value = false;
        await store.dispatch('viewAdopt', encodeBase62(postId));
    }
};

const imgArr = computed(() => {
    const post = postItem.value as PostItem; 
    const urls = [];
    for (let i = 1; i < post.image_cnt; i++) {
        const url = post[`imgURL${i}`] as string;
        // console.log(url)
        if (url) {
        urls.push(url);
        }
    }
    return urls;
});
watchEffect(()=> {
    useMeta({
        title: '파충류 다 있다 | 세상의 모든 파충류는 여기에!',
        meta: [
            // { vmid: 'description', property: 'description', content: 'test' },
            // { vmid: 'keywords', property: 'keywords', content: 'test' },
            // { vmid: 'og:title', property: 'og:title', content: 'test'},
            // { vmid: 'og:image', property: 'og:image', content: 'test' },
            { vmid: 'description', property: 'description', content: postItem.value.content },
            { vmid: 'keywords', property: 'keywords', content: postItem.value.detail },
            { vmid: 'og:title', property: 'og:title', content: postItem.value.title },
            { vmid: 'og:image', property: 'og:image', content: postItem.value.imgURL1 },
        ] 
    })
})

const shareContent = async () => {
    if (navigator.share) {
        try {
            await navigator.share({
                text: `${postItem.value.title}\n${formattedPrice(postItem.value.price)}원 \n${document.location.href}`,
            });
            // console.log("성공적으로 공유되었습니다.");
        } catch (error) {
            console.error("공유하기 실패:", error);
        }
  }
};


onMounted( async () => {
    const userAgent = navigator.userAgent;

// 안드로이드 사용자 확인
if (/android/i.test(userAgent)) {
    showAppDownloadPopup.value = true;
    appDownloadLink.value = 'https://play.google.com/store/apps/details?id=com.piot.pada2023&pcampaignid=web_share';
}
// 아이폰 사용자 확인
else if (/iPad|iPhone|iPod/.test(userAgent) ) {
    showAppDownloadPopup.value = true;
    appDownloadLink.value = 'https://apps.apple.com/kr/app/파충류-다-있다/id6465895467';
}
});
const handlePopup = () => {
    showAppDownloadPopup.value = false
}


const spliteDetail = (detail) => {
    let caclRst;
    if(!detail){
        return;
    }
    // if(detail.include(',')){
        caclRst = detail.split(',')
    // }
    const result = caclRst
    // console.log(result)
    return result;
}

//토큰으로 post_id & user_id 비교
const checkUserId = () => {
    const userId = getUserId();
    // console.log(userId) // undefined

    const postUser = postItem.value.user_id;
    if(userId === postUser) return true;
}

const editPost = () => {
    router.push(`/reptile/${encodeBase62(postId)}/edit`);
}        



</script>
<style scoped >


/* .swiper-button-next-custom,
.swiper-button-prev-custom {
    position: absolute;
    display: block;
    top: calc(50% - 2rem);
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #838383;
    cursor: pointer;;
    z-index: 999;
    border-radius: 100%;
    opacity: 0.6;
} */
@media (max-width: 640px) {
    .swiper-button-next-custom,
    .swiper-button-prev-custom {
        display: none;
    }
}


.infoBox{
    width :100%;
    background-color: #F5F7F9;
    height: 10rem;
    border-radius: 5px;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem 0;


    @media (max-width: 768px) {
        width: calc(100% - 2rem);
        margin: 1rem auto;
        padding: 0 19px;
    }

}

.swiper-pagination-bullet{
  background-color: #edeff1;
  width: .6rem;
  height: .6rem;
  opacity: 1;
  transition: all 1s;
}
.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .1rem; 


}
.swiper-pagination-bullet-active{
  background-color: #00d191;

  opacity: 1;
}
</style>

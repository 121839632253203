<template>
    <div class="w-full sm:h-[240px] h-auto bg-[#F5F7F9] lg:pt-0 pt-0 ">
        <div class="max-w-4xl mx-auto text-[#838383]  text-xs sm:flex-row flex-col  p-0 flex justify-center ">
            <!-- <div class="sm:w-1/2 w-full font-medium sm:space-y-4 ">
                <p class="font-bold">주식회사 파다(PADA) 사업자 정보</p>
                <p>대표 : 신옥철</p>
                <p>사업자 등록번호 : 280-87-03206</p>
                <p>주소 : 경기도 하남시 감초로21, 107호(감북동)</p>
                <p>E-mail : pada.global.official@gmail.com</p>
                <p>통신판매업신고번호 : 2023-경기하남-2981</p>
            </div> -->
            <div class="sm:w-7/12 w-full lg:space-y-4 space-y-2 mt-4 lg:mt-6  px-4">
                <p class="font-bold sm:text-center text-left  mb-8">파충류 다 있다 서비스 안내</p>
                <p class="sm:text-center text-left mt-4">
                    파충류 다 있다는 모든 생물 거래에 대하여 통신판매중개자로서 거래 당사자가 아닙니다.  <br>
                    거래 회원 간의 생물 거래 정보 및 거래에 관여하지 않고 어떠한 의무와 책임도 부담하지 않습니다.
                </p>
                <div class="mt-4 space-x-5 sm:flex justify-center block mb-4 ">
                    <button @click="openTerm">이용약관</button>
                    <button @click="openPrivacy">개인정보처리방침</button>
                </div>
                <div class="w-full h-1"></div>
                <p class="sm:text-center text-left ">Ⓒ pada All rights reserved.</p>
            </div>
        </div>

    </div>
</template>

<script lang="ts" setup >
const openTerm = () => {
  window.open(
    "https://pada.s3.ap-northeast-2.amazonaws.com/paper/html/modi_agree1.html",
    "_blank",
    "width=800,height=600"
  );
};

const openPrivacy = () => {
  window.open(
    "https://pada.s3.ap-northeast-2.amazonaws.com/paper/html/1219_tradingPolicy.html",
    "_blank",
    "width=800,height=600"
  );
};
</script>

<style scoped>
p{
    margin-top: 0.4rem;
}
</style>

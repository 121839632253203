<template>
    <div class="md:w-[768px] min-h-screen w-full mt-0 text-def relative" >
        <div class="w-full h-120 ">
            <img :src="eventInfo.imgURL2" alt="" class="w-full h-full object-cover">
        </div>
        <div class="px-4 py-4">
            <div class="flex flex-col">
                <div class="text-[15px] mt-2 flex justify-between bg-red-0 relative">
                    <span>{{ eventInfo.title3 }}</span>
                    <div class="md:hidden flex">
                        <div class="w-6">
                            <img src="@/assets/img/logo/share.svg" alt="" class="w-full " @click="handleShareBtn" >
                        </div>
                        <div v-if="shareBtn">
                            <ShareWays 
                            @close="handleShareBtn" 
                            :title="eventInfo.title2" :loca="eventInfo.title4" :date="eventInfo.title3" :img="eventInfo.imgURL2"  :link="`ticket/${route.params.id}`"/>
                        </div>

                    </div>
                    <!-- 나는 웹 -->
                    <div class="hidden md:flex absolute top-0 right-0">
                        <div class="w-10 cursor-pointer ">
                            <KakaoShare :title="eventInfo.title2" :loca="eventInfo.title4" :date="eventInfo.title3" :img="eventInfo.imgURL2"  :link="`ticket/${route.params.id}`"/>
                        </div>
                    </div>

                </div>
                <div class="flex my-2">
                    <div class="px-2 mr-2 text-pada h-8 whitespace-nowrap bg-[#E0F9F1] font-bold rounded-[5px] text-sm flex justify-center items-center">{{ diffDay(eventInfo.title3 )}}</div> 
                    <span class="font-bold text-[20px]">{{ eventInfo.title2 }}</span>
                </div>
            </div>

            <div class="border border-solid border-[#D9DCE0] w-full  h-auto p-4 flex items-center mt-2 rounded-[10px]">
                <div class=" space-y-2">
                    <div class="flex items-center ">
                        <div> 
                            <img src="@/assets/img/logo/pocket.svg" alt="" class="pt-[1px] w-4 mr-2">
                        </div>
                       {{ticketPrice(eventInfo.price)}}</div>


                    <div class="flex items-start "><img src="@/assets/img/logo/location.svg" alt="" class="pt-1 w-4 mr-2">{{ eventInfo.title4}}</div>
                    <div class="flex items-start">
                        <img src="@/assets/img/logo/parking.svg" alt="" class="pt-1 w-4 mr-2">
                        <div class="flex flex-col">{{ park }}
                            <div class="text-sub text-sm font-medium">{{ parkDesc }}</div>
                        </div>
                    </div>
                    <!-- 예매하기 링크가 없을떄?? -->
                    <!-- <div class="flex items-start" v-if="eventInfo.link1 === ''" >
                        
                        <img src="@/assets/img/logo/clock.svg" alt="" class="pt-1 w-4 mr-2">
                        <div class="flex flex-col" >
                            <div v-if="eventInfo.date1" class="whitespace-pre-wrap ">{{ eventInfo.date1 }}</div>
                            <div v-if="eventInfo.date2">{{ eventInfo.date2 }}</div>
                            <div v-if="eventInfo.date3">{{ eventInfo.date3 }}</div>
                        </div>
                        
                    </div> -->
                </div>
            </div>
            <div class="border border-solid border-[#D9DCE0] w-full  h-auto p-4  mt-5 rounded-[10px] flex flex-col ">
                <div class="flex flex-col " >
                    <label >
                        <input type="radio" name="date" value="link1" v-model="selectedBookLink" class="radio-input" checked>
                        <span class="whitespace-pre-wrap ">{{ eventInfo.date1 }}</span>
                    </label>
                    <label v-if="eventInfo.link2" >
                        <input type="radio" name="date" value="link2" v-model="selectedBookLink" class="radio-input">
                        <span>{{ eventInfo.date2 }}</span>
                    </label>
                    <label  v-if="eventInfo.link3" >
                        <input type="radio" name="date" value="link3" v-model="selectedBookLink" class="radio-input">
                        <span>{{ eventInfo.date3 }}</span>
                    </label>
                    <button v-if="diffDay(eventInfo.title3 ) === '종료'" 
                        class="w-full h-10 rounded-[5px] mt-4 text-white font-bold text-[15px] bg-zinc-300" disabled >
                        종료
                    </button>
                    <button v-else class="bg-pada w-full h-10 rounded-[5px] mt-4 text-white font-bold text-[15px]"
                        @click="handleBookBtn()" >예매하기
                    </button>
                </div>
            </div>
        </div>
        <div class="bg-[#F5F7F9] h-2 "></div>

        <div class="px-4 ">
            <div class="my-4">
                <h2 class="font-bold mb-4">👉 소개 </h2>
                <div class="bg-[#F5F7F9] w-full h-auto p-4 whitespace-pre-wrap rounded-[5px] text-[15px] text-def">
                    {{ eventInfo.introduce }}
                </div>
            </div>
            <div class="my-4" v-if="eventInfo.event">
                <h2 class="font-bold mb-4">🎉 이벤트 </h2>
                <div class="bg-[#F5F7F9] w-full h-auto p-4 whitespace-pre-wrap rounded-[5px] text-[15px] text-def">
                    {{ eventInfo.event }}
                </div>
            </div>
        </div>
        
        <div class="bg-[#F5F7F9] h-2 "></div>
        <div class="px-4">
            <h2 class="font-bold my-4">📍 전시 위치</h2>
            <div class="bg-[#F5F7F9] w-full h-auto p-4 whitespace-pre-wrap rounded-[5px] text-[15px] text-def" id="map" >


            </div>
            <p ref="addressText" class="text-[15px] my-2" >{{ eventInfo.title4 }}</p>
            <button class="flex">
                <img src="@/assets/img/logo/copy.svg" alt="" class="w-4 mr-1">
                <span class="underline text-xs text-sub" @click="handlCopy">주소복사</span>
            </button>
        </div>
        
    </div>
    <div class="w-full h-10"></div>


</template>

<script  setup>
// <script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, onUnmounted,  ref } from 'vue';
import { useStore } from 'vuex';
import { diffDay } from '@/utils/timeForToday';
import { ticketPrice } from '@/utils/formattedUnit';
import LoadingCircle from '../UI/LoadingCircle.vue';
import { useRoute } from 'vue-router';
import { useMeta } from 'vue-meta';
import KakaoShare from '../UI/KakaoShare.vue';
import ShareWays from '../UI/ShareWays.vue';
import { app } from '@/utils/fbase';


const kakaoApi = process.env.VUE_APP_KAKAO_API
const store = useStore();
const route = useRoute();
const isLoading = computed(() => store.state.isLoading);
const eventInfo = computed(()=> store.state.clickedEvent);
const selectedBookLink = ref('htmlLink1');
const lati = ref(0);
const long = ref(0);
const park = ref('');
const parkDesc = ref('');
const shareBtn =ref(false);
const handleShareBtn = () => {
    shareBtn.value = !shareBtn.value
};

const showAppDownloadPopup = ref(false);
const appDownloadLink = ref('');
const timerRef = ref(null);
const visibilityChangeHandlerRef = ref(null);

const checkDevice = () => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
        appDownloadLink.value = 'https://play.google.com/store/apps/details?id=com.piot.pada2023&pcampaignid=web_share';
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
        appDownloadLink.value = 'https://apps.apple.com/kr/app/파충류-다-있다/id6465895467';
    }
};
const handleVisibilityChange = () => {
  if (document.hidden) {
    clearTimeout(timerRef.value);
    window.removeEventListener('visibilitychange', handleVisibilityChange);
    
  }
};

const openAppOrRedirect = async (schemeUrl, storeUrl) => {
//     const dynamicLinks = getDynamicLinks(app);
//     const link = await createLink(dynamicLinks, {
//         link : schemeUrl,
//     })
//   window.addEventListener('visibilitychange', handleVisibilityChange);

  // 주어진 딥링크로 앱을 실행
  window.location.href = schemeUrl;

  // 딥 링크가 실패할 경우 일정 시간 후 앱 스토어로 리디렉션
  timerRef.value = setTimeout(() => {
    window.removeEventListener('visibilitychange', handleVisibilityChange);
    // 사용자 제스처가 필요한 앱 스토어로의 리디렉션을 버튼 클릭 이벤트에서 수행
  }, 1500);
};

const handleBookBtn = async () => {
  checkDevice();
  const schemeUrl = 'padaAppBack://open';
  await openAppOrRedirect(schemeUrl, appDownloadLink.value);

  // 딥 링크가 실패했을 때만 앱 스토어로 리디렉션
  setTimeout(() => {
    if (timerRef.value) {
      window.location.href = appDownloadLink.value;
    }
  }, 5);
};

onMounted(() => {
  // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
  onBeforeUnmount(() => {
    window.removeEventListener('visibilitychange', handleVisibilityChange);
    clearTimeout(timerRef.value);
  });
});
/* //////////////////////// 설치 하지 않은 사람은 바로 넘겨버리기  ////////////////////////////////////
const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
        clearTimeout(timerRef.value);
        window.removeEventListener('visibilitychange', handleVisibilityChange);
    }
};
    
const openAppOrRedirect = (schemeUrl, storeUrl) => {     
    window.addEventListener('visibilitychange', handleVisibilityChange);

    const onLaunchFail = () => {         
        window.location.href = storeUrl;     
    };      
    // 짧은 시간 후에 fallbackLocation으로 이동     
    setTimeout(() => {         
        onLaunchFail();     
    }, 10);      
    // 주어진 딥링크로 앱을 실행     
    window.location.href = schemeUrl; 
}; 
    


onBeforeUnmount(() => {
    window.removeEventListener('visibilitychange', handleVisibilityChange);
});

const handleBookBtn = async () => {
    checkDevice();
    const schemeUrl = 'padaAppBack://open';
    const mobileWebUrl = appDownloadLink.value;
    openAppOrRedirect(schemeUrl, mobileWebUrl );
};
*/
/* !!!!!!!!!!! 실행은 되는 코드 - 유효하지 않는 주소라고 나오긴함.  !!!!!!!!!!!!!! 
const openAppOrRedirect = (schemeUrl, storeUrl, mobileWebUrl) => {
    const now = new Date().getTime();

    setTimeout(function() {
        location.href = schemeUrl;
    }, 500);

    setTimeout(function() {
        if (new Date().getTime() - now < 2000) {
            location.href = mobileWebUrl;
        }
    }, 1000);

    setTimeout(function() {
        location.href = storeUrl;
    }, 1500);
};

const handleBookBtn = async () => {
    checkDevice();
    const schemeUrl = 'padaAppBack://open';
    const mobileWebUrl = appDownloadLink.value;
    openAppOrRedirect(schemeUrl, appDownloadLink.value, mobileWebUrl);
};
*/

let map;

useMeta({
    title: eventInfo.value.title1 ,
    meta: [
        { vmid: 'description', property: 'description', content: eventInfo.value.introduce },
        // { vmid: 'keywords', property: 'keywords', content: eventInfo.value.detail },
        { vmid: 'og:title', property: 'og:title', content: eventInfo.value.title1 },
        { vmid: 'og:image', property: 'og:image', content: eventInfo.value.imgURL1 },
    ] 
})
// 플러터 이벤트 리스너 해제를 위한 빈 함수 
const handlePlatformReady = () => {
};
onMounted(async () => {
    lati.value = eventInfo.value.latitude;
    long.value = eventInfo.value.longitude;

    if (eventInfo.value.parking) {
        if (eventInfo.value.parking.includes('/')) {
            const parkArr = eventInfo.value.parking.split('/');
            park.value = parkArr[0];
            parkDesc.value = parkArr.length > 1 ? parkArr[1] : '';
        } else {
            park.value = eventInfo.value.parking;
            parkDesc.value = '';
        }
    } else {
        park.value = '';
        parkDesc.value = '';
    }

    // 카카오맵 스크립트 호출
    await loadScript();

    window.addEventListener("flutterInAppWebViewPlatformReady", handlePlatformReady);


});
onUnmounted(() => {
    window.removeEventListener("flutterInAppWebViewPlatformReady",handlePlatformReady);
});

// 주소 복사하기 버튼
const addressText = ref();
const handlCopy = () => {
    const textToCopy = addressText.value.innerText;
    navigator.clipboard.writeText(textToCopy).then(() => {
        alert('주소가 복사되었습니다.')
    })
};

const loadScript = () => {
  const script = document.createElement("script");
  script.src =
    `https:///dapi.kakao.com/v2/maps/sdk.js?appkey=${kakaoApi}&autoload=false`;
  script.onload = () => window.kakao.maps.load(loadMap);

  document.head.appendChild(script);
}

const loadMap = () => {
  const container = document.getElementById("map");
  const options = {
    center: new window.kakao.maps.LatLng(lati.value, long.value),
    // center: new window.kakao.maps.LatLng(lati.value, long.value),
    level: 7,
  };

  // 여기서 map 변수를 사용
  map = new window.kakao.maps.Map(container, options);
  loadMaker();
}
const loadMaker = () => {
  // 마커가 표시 위치
  const markerPosition = new window.kakao.maps.LatLng(lati.value, long.value);

  // 마커 생성
  const marker = new window.kakao.maps.Marker({
    position: markerPosition,
  })

  // 마커가 지도 위에 표시되도록 설정
  marker.setMap(map)
}
</script>

<style scoped>
.boxShadow{
box-shadow: 0px 0px 8px rgba(8, 50, 25, 0.13);
border-radius: 5px;
}
#map{
    width: 100%;
    height: 380px;
    
}
@media (max-width: 640px){
    #map{
        width: 100%;
        height: 180px;
    }
}
/* 라디오 버튼 숨기기 */
.radio-input {
    visibility: hidden;
    position: absolute;
}

.radio-input + span {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    display: inline-block;
    /* 커스텀 라디오 버튼 스타일 */
}

.radio-input + span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 1rem;
    height: 1rem;
    border: 1px solid #00D191;
    border-radius: 50%;
    background-color: #fff;
}

.radio-input:checked + span:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 8px;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: #00D191;
}
</style>

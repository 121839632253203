<template>
    <div class="fixed top-0 left-0 w-screen h-screen flex justify-center items-center" style="z-index: 990;">
      <!-- 딤드 배경 -->
      <div class="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
      
      <!-- 로딩 아이콘 -->
      <div class="relative" style="z-index: 999;">
        <LoadingCircle/>
      </div>
    </div>
  </template>
  

<script lang="ts" setup>
import LoadingCircle from './LoadingCircle.vue';
import { onMounted, onUnmounted } from 'vue';
onMounted(() => {
      document.body.style.overflow = 'hidden';
    });

onUnmounted(()=> {
    document.body.style.overflow = 'auto';

})
</script>

<style>

</style>
<template>
    <div class="fixed bottom-0 left-0 right-0 w-full h-20  " style="z-index: 990;">
        <!-- 딤드 배경 -->


    <div class="w-full text-white flex items-center mx-auto relative  top-2" 
    style="z-index: 999; width: calc(100% - 1.2rem);">
        <div class="flex items-center ml-6 mr-auto">
        <div class="bg-white w-[20px] h-[20px] flex justify-center items-center rounded-sm mr-[5px]">
            <img src="@/assets/img/logo/squareLogo.svg" alt="" class="w-4 h-4 ">
        </div>
        <p>파충류 다 있다</p>
        </div>

        <a :href="storeUrl">
            <div class="bg-black w-[74px] h-[34px] rounded-full flex justify-center items-center text-[14px] font-bold">앱 열기</div>
        </a>
        <div class=" text-2xl px-3 h-[34px] mr-2 font-light flex items-center"  @click="emitToParent"> <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.05794 6L11.7829 1.2825C11.9242 1.14127 12.0035 0.949728 12.0035 0.750002C12.0035 0.550276 11.9242 0.35873 11.7829 0.217502C11.6417 0.076274 11.4502 -0.00306702 11.2504 -0.00306702C11.0507 -0.00306702 10.8592 0.076274 10.7179 0.217502L6.00044 4.9425L1.28294 0.217502C1.14171 0.076274 0.950169 -0.00306684 0.750443 -0.00306684C0.550716 -0.00306684 0.35917 0.076274 0.217942 0.217502C0.0767147 0.35873 -0.00262612 0.550276 -0.00262612 0.750002C-0.00262612 0.949728 0.0767147 1.14127 0.217942 1.2825L4.94294 6L0.217942 10.7175C0.147646 10.7872 0.0918506 10.8702 0.0537741 10.9616C0.0156976 11.053 -0.00390625 11.151 -0.00390625 11.25C-0.00390625 11.349 0.0156976 11.447 0.0537741 11.5384C0.0918506 11.6298 0.147646 11.7128 0.217942 11.7825C0.287665 11.8528 0.370615 11.9086 0.46201 11.9467C0.553404 11.9847 0.651434 12.0044 0.750443 12.0044C0.849451 12.0044 0.947481 11.9847 1.03888 11.9467C1.13027 11.9086 1.21322 11.8528 1.28294 11.7825L6.00044 7.0575L10.7179 11.7825C10.7877 11.8528 10.8706 11.9086 10.962 11.9467C11.0534 11.9847 11.1514 12.0044 11.2504 12.0044C11.3495 12.0044 11.4475 11.9847 11.5389 11.9467C11.6303 11.9086 11.7132 11.8528 11.7829 11.7825C11.8532 11.7128 11.909 11.6298 11.9471 11.5384C11.9852 11.447 12.0048 11.349 12.0048 11.25C12.0048 11.151 11.9852 11.053 11.9471 10.9616C11.909 10.8702 11.8532 10.7872 11.7829 10.7175L7.05794 6Z" fill="white"/>
</svg>
</div>
    </div>
    <div class=" h-[52px] mx-auto absolute top-0 left-0 right-0  blurCustom "
        style="width: calc(100% - 2.0rem);"
        ></div>

    </div>
</template>
<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue';
const props = defineProps({
    storeUrl : String
})
const emit = defineEmits(['closePopup'])
const emitToParent = () =>{
    emit('closePopup')
}

</script>

<style scoped>
/* Frame 3311 */
.blurCustom{

background: rgba(0, 0, 0, 0.7);
backdrop-filter: blur(2px);
border-radius: 5px;

}



</style>
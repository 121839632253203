import { ref, onMounted, onBeforeUnmount, watchEffect, computed } from 'vue';
import { useStore } from 'vuex';

const useIntersectionObserver = (options) => {
  const store = useStore();
  const isVisible = ref(false);
  const elementRef = ref(null);
  const isLoading = computed(() => store.state.isLoading);
  let observer;

  const updateObserver = () => {
    if (!elementRef.value) return;

    if (isLoading.value) {
      if (observer) observer.unobserve(elementRef.value);
    } else {
      if (observer) observer.observe(elementRef.value);
    }
  };

  onMounted(() => {
    observer = new IntersectionObserver(([entry]) => {
      isVisible.value = entry.isIntersecting;
    }, options);

    updateObserver();
  });

  watchEffect(() => {
    updateObserver();
  });

  onBeforeUnmount(() => {
    if (observer && elementRef.value) {
      observer.unobserve(elementRef.value);
    }
  });

  return { elementRef, isVisible };
};

export default useIntersectionObserver;

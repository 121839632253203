<template>
    <div class="w-full  flex flex-row md:flex-col sm:px-0 px-4 bg-red-0 ">

        <router-link to="/news/event">
            <h3 class="btn " :class=" activeMenu === 'expo' ? 'bg-[#f9f9f9] ' : ''" @click="handleBtn('expo')">📍&nbsp;박람회/이벤트</h3>
        </router-link>

        <router-link to="/news/column">
            <h3 class="btn " :class=" activeMenu === 'column' ? 'bg-[#f9f9f9] ' : ''" @click="handleBtn('column')" >📢&nbsp;칼럼/뉴스</h3>
        </router-link>

        <router-link to="/news">
            <h3 class="btn" :class=" activeMenu === 'news' ? 'bg-[#f9f9f9] ' : ''" @click="handleBtn('news')">🕊&nbsp;양서파충류 공식 채널</h3>
        </router-link>

    </div>
</template>

<script lang="ts" setup>
import { ref, Ref } from 'vue';
const activeMenu :Ref<String> = ref('expo') 
const handleBtn = (menu :string) => {
    // console.log(menu)
    activeMenu.value = menu;

}
</script>

<style scoped>
.btn{
    cursor: pointer;
    @apply  text-sm md:text-base font-medium md:p-3 px-3 py-[9px] md:rounded-xl rounded-full md:mb-4 mb-0 whitespace-nowrap sm:ring-0 ring-1 ring-zinc-200 mr-2 
}

</style>
<!-- hover:translate-x-2 transition duration-200  -->
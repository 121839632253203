<template>
    <div class="-mt-4">
        <TopNavigator/>
        <NaviHeight/>
        <SectionHero/>
        <MarginSpace marginSpace="10rem"/>

        <SectionAdopt/>

        <SectionFilter/>
        <MarginSpace marginSpace="10rem"/>

        <SectionPayment/>
        <MarginSpace marginSpace="10rem"/>

        <SectionManage/>
        <MarginSpace marginSpace="0rem"/>

        <SectionChart/>
        <MarginSpace marginSpace="10rem"/>

        <SectionChat/>
        <MarginSpace marginSpace="10rem"/>

        <SectionCommunity/>
        <MarginSpace marginSpace="10rem"/>

        <SectionInfo/>
        <MarginSpace marginSpace="10rem"/>
        <Section9Footer/>
        
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NaviHeight from '@/components/Navigator/NaviHeight.vue'
import SectionHero from '@/components/Landing/Section1Hero.vue'
import SectionAdopt from '@/components/Landing/Section2Adopt.vue'
import SectionFilter from '@/components/Landing/Section3Filter.vue'
import SectionPayment from '@/components/Landing/Section4Payment.vue'
import SectionManage from '@/components/Landing/Section5Manage.vue'
import SectionChart from '@/components/Landing/Section5-1Chart.vue'
import SectionChat from '@/components/Landing/Section6Chat.vue'
import SectionCommunity from '@/components/Landing/Section7Community.vue'
import SectionInfo from '@/components/Landing/Section8Info.vue'
import MarginSpace from '@/components/Landing/MarginSpace.vue';
import TopNavigator from '@/components/Navigator/TopNavigator.vue'
import Section9Footer from '@/components/Landing/Section9Footer.vue'

export default defineComponent({
    name: 'AppDownView',
    components : { 
        NaviHeight,
        SectionHero, 
        SectionAdopt,
        SectionFilter,
        SectionPayment,
        SectionManage,
        SectionChart,
        SectionChat,
        SectionCommunity,
        SectionInfo,
        MarginSpace,
        TopNavigator,
        Section9Footer
    }

});
</script>

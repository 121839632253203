<template>
    <div class="md:w-[640px] min-h-screen  w-full  ">
        <!-- <div class="mb-4 font-bold text-lg justify-between flex flex-col ">
            <h1 class="pl-2">칼럼/뉴스</h1>    
        </div> -->
        <iframe
            id="imported-content"
            :src="postUrl"
            width="100%"
            height="100%"
            frameborder="0"
            style="height: 100vh"
        ></iframe>
    </div>

</template>
  
<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
const noticeList = [
    {
        id : 1,
        title : '공지 / 파충류 다 있다 이용 수칙 안내',
        link : 'https://pada.s3.ap-northeast-2.amazonaws.com/paper/html/virgin.html',
        created_at : '2023-10-06T09:05:04.000Z'
    },
    {
        id : 2,
        title : '공지 / 커뮤니티 신고 처리 방침',
        link : 'https://pada.s3.ap-northeast-2.amazonaws.com/paper/html/hongkong_1.html',
        created_at : '2023-10-06T09:05:04.000Z'
    },
]

const store = useStore();
const route = useRoute();

const postUrl = computed(() => store.state.columnNewsUrl);


// 선택된 게시물에 대한 computed 속성
const selectedPost = computed(() => {
    const index = Number(route.params.id); // route.params.id를 숫자로 변환
    return noticeList.find(data => data.id === index);
});

// selectedPost의 변경사항을 감시하고, 해당 게시물의 htmlLink1 값을 SET_COLUMN_URL 커밋으로 저장
watch(selectedPost, (newVal) => {
    if (newVal && newVal.link) {
        store.commit('SET_COLUMN_URL', newVal.link);
    }
}, { immediate: true }); // immediate: true 옵션으로 컴포넌트 마운트 시 즉시 실행



</script>
<style>
</style>
<!-- onMounted(async () => {
    if(setHtmlUrl() === 'fail'){
        router.push('/not-found')
        return;
    }
    store.commit('SET_COLUMN_URL', setHtmlUrl()) // /news, /0 , html링크
    const rsp = await axios.get(postUrl.value);
    callResult.value = rsp.data;

    //  HTMLIFrameElement로 타입 캐스팅
    const iframe = document.getElementById('imported-content') as HTMLIFrameElement;
    if (iframe.contentWindow) {
        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(callResult.value);
        iframe.contentWindow.document.close();
    }
});
const setHtmlUrl = () => {
    let url;
    let index = route.params.id as unknown as number

    if (route.path.includes('/column/news')) {
        if (index >= 0 && index < columnData.length) {
            url = columnData[index].html;
        } else {
            return 'fail';
        }
    } 
    else {
        if (index >= 0 && index < associationData.length) {
            url = associationData[index].html;
        } else {
            return 'fail';
        }
    } 
    return url; // 최종 URL 반환
}; -->
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full h-auto font-bold sm:text-2xl text-lg mb-5" }
const _hoisted_2 = { class: "flex flex-row w-full" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: $props.iconSrc,
        alt: "",
        class: "sm:w-7 w-5 mr-2"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, _toDisplayString($props.subtitle), 1)
    ])
  ]))
}
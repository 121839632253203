<template>
    <div class="flex flex-col">
        <div class="mx-auto font-bold text-lg mb-4 ">컬럼/뉴스</div>

        <Swiper 
            :navigation="{
                enabled:true,
                nextEl: '.swiper-button-next-custom-3',
                prevEl: '.swiper-button-prev-custom-3'
            }"
            :slidesPerView="1"
            :spaceBetween="30"
            :loop="true"
            :breakpoints="{
                786 : {
                    slidesPerView : 2,
                }
            }"
            class="w-full h-auto">
                <SwiperSlide 
                    v-for="post in infoData" :key="post.id"
                    class="box flex flex-col h-full"                    
                    style="border: 1px solid #EDEFF1; border-radius: 10px;">
                    <div>
                        <div class="w-full h-60 ">
                            <img :src=" post.imgUrl " alt="" class="w-full h-full object-cover object-center rounded-t-[10px]">
                        </div>
                        <div class="px-3 relative w-full pt-2  h-32 ">
                            
                            <div class="mt-2 font-medium text-lg text-def">
                                <span class="text-pada font-medium text-base flex-shrink-0 mr-1 border border-solid border-pada rounded-md px-2 py-[2px]">{{ post.tag }}</span>
                                {{ post.title }}
                            </div>
                            <div class="mt-4 text-sm text-[#676767]">{{ post.content }}</div>
                            <div class="absolute bottom-4 text-sm text-[#A4A4A4]">{{ post.date }}</div>
                        </div>
                    </div>
                </SwiperSlide>
        </Swiper>
        <div class="swiper-button-next-custom-3">
            <img src="@/assets/img/logo/arrow.svg" alt="" class="rotate-180">
        </div>
        <div class="swiper-button-prev-custom-3">               
            <img src="@/assets/img/logo/arrow.svg" alt="">
        </div>
        
    </div>
</template>
<script >
import {defineComponent, } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import data from '@/assets/data/column.json'
import 'swiper/css';
import 'swiper/css/navigation';
import SwiperCore, {Navigation, Autoplay } from "swiper";
  SwiperCore.use([  Navigation, Autoplay,  ]);

export default defineComponent({
    data(){
        return{
            infoData : data
        }
    },

    components: {
      Swiper,
      SwiperSlide,

    },
    setup() {
        return{
            modules : [Navigation],
        }
        
    }
    
})
</script>
<style scoped>
/* Swiper 네비게이션 버튼 스타일 */
.swiper-button-next-custom-3,
.swiper-button-prev-custom-3 {
    position: absolute;
    top: calc(50% - 2rem);
    background-color: white;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: #838383;
    box-shadow: 0px 0px 20px rgba(8, 50, 25, 0.12);
    cursor: pointer;
    @media (max-width: 768px) {
        width: 1rem;
        height: 1rem;
    }
}
.swiper-button-next-custom-3{
    right: 3%;
}
.swiper-button-prev-custom-3{
    left: 3%;
}
.box{
box-sizing: border-box;
background: #FFFFFF;
border: 1px solid #EDEFF1;
border-radius: 10px;

}

    
</style>
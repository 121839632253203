<template>
    <div class="w-full calcBanner bg-[#e5ffdd] flex relative lg:items-center">
        <div class="flex-col h-auto justify-center hidden lg:flex xl:pl-40 2xl:pl-100 transition-all duration-300">
            <div class="z-50  flex-col hidden lg:flex">
                <div class="font-bold lg:text-5xl mb-8 text-[#171819]
                    md:text-4xl 
                ">파충류 다 있다</div>
                <div class="text-[#676767] lg:text-xl lg:leading-relaxed
                    md:text-lg  font-medium mb-10">
                    세상의 모든 파충류는 여기 <br>
                    분양, 관리, 커뮤니티, 정보를 한번에

                </div>
                <div class="flex space-x-4 cursor-pointer">
                    <a href="https://play.google.com/store/apps/details?id=com.piot.pada2023&pcampaignid=web_share" target="_blank">
                        <div class="w-20 md:w-40"><img src="@/assets/img/logo/GoogleBtn.png" alt="googleBtn" width="100%" height="100%"></div>
                    </a>
                    <a href="https://apps.apple.com/kr/app/파충류-다-있다/id6465895467" target="_blank">
                        <div class="w-20 md:w-40"><img src="@/assets/img/logo/AppleBtn.png" alt="appleBtn" width="100%" height="100%"></div>
                    </a>
                </div>
            </div>



        </div>
        <div class="h-full  absolute top-0  right-0 "  >
            <img src="@/assets/img/download/sec1_heroLow.png" alt="heroImg" width="100%" height="100%" class="w-full h-full object-cover object-center hidden md:block">
            <img src="@/assets/img/download/main.png"  alt="heroImg" width="100%" height="100%" class="w-full h-full object-cover object-center block md:hidden">
        </div>



        <div class="z-50 md:hidden flex flex-col  absolute top-20 left-8">
            <div class="font-bold lg:text-5xl mb-4 text-[#171819]
                md:text-4xl text-2xl 
            ">파충류 다 있다</div>
            <div class="text-[#676767] lg:text-xl lg:leading-relaxed text-lg font-medium mb-4">
                세상의 모든 파충류는 여기 <br>
                 분양, 관리, 커뮤니티, 정보를 한번에

            </div>
            <div class="flex space-x-4 cursor-pointer">
                <a href="https://play.google.com/store/apps/details?id=com.piot.pada2023&pcampaignid=web_share" target="_blank">
                    <div class="w-28 md:w-40"><img src="@/assets/img/logo/GoogleBtn.png" alt="googleBtn" width="100%" height="100%"></div>
                </a>
                <a href="https://apps.apple.com/kr/app/파충류-다-있다/id6465895467" target="_blank">
                    <div class="w-28 md:w-40"><img src="@/assets/img/logo/AppleBtn.png" alt="appleBtn" width="100%" height="100%"></div>
                </a>
            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
    name:'SectionOne',

});
</script>

<style scoped>

.calcBanner {
    height: calc(100vh - 4rem);
}
@media (max-width: 640px) {

}


</style>

<template>
  <div class="w-full relative  justify-center items-center">
    <swiper 
    :options="swiperOptions"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
      :centeredSlides="true"
      :spaceBetween="100"
      :slidesPerView="1"
      :breakpoints="{
        1024: {
          slidesPerView: 1,
          spaceBetween: 100
        }
      }"

      :autoplay="{
        delay: 21210,
        disableOnInteraction: false,
      }"
      :loopAdditionalSlides="1" 
      :loop="false"
      :pagination="{ 
        clickable: true, 
        el: '.swiper-pagination', 
      }"
    >
      <swiper-slide  class=" h-full flex justify-center items-center mb-10">
        <div class="w-full h-full ">
          <img src="@/assets/img/main/mainBanner1.png" alt="" class="hidden md:block w-full">
          <img src="@/assets/img/main/2.png" alt="" class="block md:hidden w-full">
        </div>

        <!-- <img src="@/assets/img/main/mainMobile1.png" alt="" class="block md:hidden"> -->
        
      </swiper-slide>
      <swiper-slide  class=" h-full flex justify-center items-center mb-10">
        <div class="w-full h-full ">
          <img src="@/assets/img/main/mainBanner1.png" alt="" class="hidden md:block w-full">
          <img src="@/assets/img/main/22.png" alt="" class="block md:hidden w-full">
        </div>

        <!-- <img src="@/assets/img/main/mainMobile1.png" alt="" class="block md:hidden"> -->
        
      </swiper-slide>
      <swiper-slide  class=" h-full flex relative justify-center items-center">
        <img src="@/assets/img/main/mainBanner2.png" alt="">
        <a href="https://play.google.com/store/apps/details?id=com.piot.pada2023&pcampaignid=web_share" class="opacity-0 absolute bottom-[21%] left-[29.5%] w-36 h-10"> </a>
      </swiper-slide>
      <!-- <swiper-slide  class=" h-full flex   justify-center items-center">
        <img src="@/assets/img/main/mainBanner1.png" alt="">
      </swiper-slide> -->
      <div class="swiper-pagination  "></div>
    </swiper>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/css';
  import 'swiper/css/pagination'; // 추가: 페이지네이션 관련 스타일
  import SwiperCore, {Pagination,  Autoplay } from "swiper";
  SwiperCore.use([ Pagination,  Autoplay,  ]);
  import {defineComponent} from 'vue';
import TopBanner from '../UI/TopBanner.vue';
export default defineComponent({
    name : 'CarousleSlide',
    components: {
    Swiper,
    SwiperSlide,
},
    data() {
      return {

      };
    },

  });
</script>
<style>
.swiper-pagination-bullet{
  background-color: #edeff1;
  width: .6rem;
  height: .6rem;
  opacity: .6;
  transition: all 1s;
}
.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .1rem; 


}
.swiper-pagination-bullet-active{
  background-color: #00d191;

  opacity: 1;
}

</style>

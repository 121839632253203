<template >
    <div class="w-full bg-red-0 flex justify-center pb-16">
        <div class="w-[1024px] h-[280px]">

            <div class="w-full h-full rounded-[19px] flex justify-center items-center" :class="bgColor">
                <div class="mr-20">
                    <p class="text-4xl font-bold pb-4" :class="titleColor"> {{ title }}</p>
                    <p class="text-[#676767] text-[28px] font-medium leading-relaxed whitespace-pre-wrap" :class="descColor">{{ content1 }} <br></p>
                    <p class="text-[#676767] text-[28px] font-medium leading-relaxed whitespace-pre-wrap" :class="descColor">{{ content2 }} <br></p>
                    <div :class="cont2Style"> {{ content3 }}</div>
                </div>
                <div class="  " :class="photoStyle">
                    <img :src="imgSrc" class="w-full h-full object-cover" alt="">
                </div>

            </div>
        </div>
    </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent ({
    name:'TopBanner',
    props : {
        bgColor :{
            type : String,
            required : true,
        },
        title: {
            type : String,
            required : true
        },
        content1 : {
            type : String,
            required : true,
        },
        content2 : {
            type : String,
            required : true,
        },
        content3 : {
            type : String,
        },
        imgSrc : {
            type:String,
            required : true,
        },
        photoStyle :{
            type:String,

        },
        titleColor : {
            type :String
        },
        descColor : {
            type :String
        },
        cont2Style : {
            type :String
        },
        
    }
    
})
</script>

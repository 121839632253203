
// 가격에 , 추가
export function formattedPrice(value :number) {
    if (value == null) {
        return '0';
      }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// rsex 수정
export function formattedGender (gender : number) :string{
    switch(gender){
        case 0 : return '미구분';
        case 1 : return '암컷';
        case 2 : return '수컷';
        case 3 : return '그룹';
        case 4 : return '한쌍';
    }
}
// rsex 수정
export function genderToText(gender: string | null): string {
    switch(gender) {
      case '미구분': return '0';
      case '암컷': return '1';
      case '수컷': return '2';
      case '그룹': return '3';
      case '한쌍': return '4';
      case '물건': return '9';
      default: return '';
    }
  }
  
// 판매 상태 
export function sellingStatus (type : string){
    switch(type){
        case '0' : return '판매중';
        case '7' : return '예약중';
        case '9' : return '판매완료';
        default : return '0';
    }
}

// 가격 검색
export function formattedPriceRange (price : string){
    if(price === null){
        return;
    }
    switch(price){
        case '~10만원' : return 1;
        case '10~50만원' : return 2;
        case '50~100만원' : return 3;
        case '100~500만원' : return 4;
        case '500~1,000만원': return 5;
        case '1,000만원 이상': return 6;
    }
}

// 지역/도시 
export function splitLocation (locations :string) {
    let city;
    let town = '';
    if (locations.includes('/')) {
        const parts = locations.split('/');
        city = parts[0]; // '서울'
        town = parts[1]; // '강남'
    } else {
        city = locations
    }
    return `${city} ${town}`
}

export function ticketPrice(ticket : string){
    if(ticket.length < 7){
        return ticket === '0' ? '입장료 무료' : `입장료 ${ticket}원`
    } else {
        return ticket;
    }
}


// 날짜를 2023-12-01 12:00 형태로 변환// 없음
export function formatDate(date :Date) {
    if (!date) {
        return '-';
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    const dayOf = date.getDay();

    let dayString;
    switch (dayOf) {
        case 0: dayString = '일'; break;
        case 1: dayString = '월'; break;
        case 2: dayString = '화'; break;
        case 3: dayString = '수'; break;
        case 4: dayString = '목'; break;
        case 5: dayString = '금'; break;
        case 6: dayString = '토'; break;
        default: dayString = '';
    }
    
    return `${year}-${month}-${day}(${dayString}) `;
}
<template>
    <div class="flex flex-col w-full h-auto font-bold sm:text-2xl text-lg mb-5">
        <!-- <div class=" font-medium text-sub mb-2"> {{ title }} </div> -->
        <div class="flex flex-row w-full">
            <img :src="iconSrc" alt="" class="sm:w-7 w-5 mr-2 ">
            <div class="flex justify-center items-center"> {{ subtitle  }}</div>
        </div>
    </div>
</template>
<script lang="ts">
export default {
    name: 'BoardTitle',
    props : {
        title :{
            type : String,
            required : true,
        },
        iconSrc :{
            type : String,
            required : true,
        },
        subtitle :{
            type : String,
            required : true,
        },
    }
    
}
</script>

<template>
    <div class="max-w-4xl mx-auto flex flex-col-reverse sm:flex-row w-[896px]:px-0 px-10 ">
        <div class="sm:w-1/2 w-full transition-all duration-1000 delay-300" :class="elClass">
            <img src="@/assets/img/download/sec8_phone1.png" alt="" class="sm:mt-0 mt-20 sm:-translate-x-12">
            <div class="lg:mt-40 mt-4">
                <p class="font-medium lg:my-20 my-0 leading-relaxed text-lg sm:text-xl sm:leading-relaxed
                text-center sm:text-left text-[#3E4044]">파충류 관련 유익한 정보와<br>
                사육&케어 및 다양한 소식지 콘텐츠를 <br> 제공해요.</p>
            </div>
        </div>
        <div class="sm:w-1/2 w-full  flex flex-col relative transition-all duration-1000  opacity-0"
        ref="elRef" :class="elClass">

            <TitleBox
                title="정보"
                content1="흩어진 정보는 여기에서,"
                content2="가장 빠른 파충류 소식지"
                align="items-end"
            />
            <img src="@/assets/img/download/sec8_phone2.png" alt="" class=" lg:mt-40 mt-16">
        </div>
        

    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import useIntersectionObserver from '@/utils/v2IntersectionObserver';
import TitleBox from './TitleBox.vue'
export default defineComponent({
    name:'SectionInfo',
    components:{
        TitleBox
    },
    setup(){
        const elClass = ref('opacity-0 translate-y-10');
        const observerOptions = {
            root:null,
            rootMargin : '0px',
            threshold : 0.1
        };
        const {elementRef : elRef , isVisible : isVisible} = useIntersectionObserver(observerOptions);

        const updateVisibility = (isVisible , elClass)  =>{
            elClass.value = isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        };

        watch(isVisible, newVal => updateVisibility(newVal, elClass));

        return {
            elRef,
            elClass,
        }
    }

});
</script>

<style>

</style>
<!-- 
<div class="flex  items-center text-2xl font-bold  text-white "> 
    <div>
        <svg width="30" height="47" viewBox="0 0 44 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 21.45V25.29C0 26.35 0.323822 27.255 0.971466 28.005C1.61911 28.755 2.46105 29.13 3.49728 29.13H24.5408L15.7826 37.95C15.0254 38.67 14.6467 39.57 14.6467 40.65C14.6467 41.73 15.0254 42.63 15.7826 43.35L18.0245 45.63C18.7618 46.37 19.6585 46.74 20.7147 46.74C21.7509 46.74 22.6576 46.37 23.4348 45.63L42.894 26.07C43.6313 25.33 44 24.43 44 23.37C44 22.33 43.6313 21.42 42.894 20.64L23.4348 1.14C22.6775 0.38 21.7708 0 20.7147 0C19.6784 0 18.7817 0.38 18.0245 1.14L15.7826 3.36C15.0254 4.12 14.6467 5.03 14.6467 6.09C14.6467 7.15 15.0254 8.06 15.7826 8.82L24.5408 17.61H3.49728C2.46105 17.61 1.61911 17.985 0.971466 18.735C0.323822 19.485 0 20.39 0 21.45Z" fill="#09B681"/>
        </svg>
    </div>
    <p class="ps-4">협회 소식</p>
</div>                    
<div class="flex  items-center text-2xl font-bold  text-white "> 
    <div>
        <svg width="30" height="47" viewBox="0 0 44 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 21.45V25.29C0 26.35 0.323822 27.255 0.971466 28.005C1.61911 28.755 2.46105 29.13 3.49728 29.13H24.5408L15.7826 37.95C15.0254 38.67 14.6467 39.57 14.6467 40.65C14.6467 41.73 15.0254 42.63 15.7826 43.35L18.0245 45.63C18.7618 46.37 19.6585 46.74 20.7147 46.74C21.7509 46.74 22.6576 46.37 23.4348 45.63L42.894 26.07C43.6313 25.33 44 24.43 44 23.37C44 22.33 43.6313 21.42 42.894 20.64L23.4348 1.14C22.6775 0.38 21.7708 0 20.7147 0C19.6784 0 18.7817 0.38 18.0245 1.14L15.7826 3.36C15.0254 4.12 14.6467 5.03 14.6467 6.09C14.6467 7.15 15.0254 8.06 15.7826 8.82L24.5408 17.61H3.49728C2.46105 17.61 1.61911 17.985 0.971466 18.735C0.323822 19.485 0 20.39 0 21.45Z" fill="#09B681"/>
        </svg>
    </div>
    <p class="ps-4">파충류 칼럼</p>
</div>                    
<div class="flex  items-center text-2xl font-bold  text-white "> 
    <div>
        <svg width="30" height="47" viewBox="0 0 44 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 21.45V25.29C0 26.35 0.323822 27.255 0.971466 28.005C1.61911 28.755 2.46105 29.13 3.49728 29.13H24.5408L15.7826 37.95C15.0254 38.67 14.6467 39.57 14.6467 40.65C14.6467 41.73 15.0254 42.63 15.7826 43.35L18.0245 45.63C18.7618 46.37 19.6585 46.74 20.7147 46.74C21.7509 46.74 22.6576 46.37 23.4348 45.63L42.894 26.07C43.6313 25.33 44 24.43 44 23.37C44 22.33 43.6313 21.42 42.894 20.64L23.4348 1.14C22.6775 0.38 21.7708 0 20.7147 0C19.6784 0 18.7817 0.38 18.0245 1.14L15.7826 3.36C15.0254 4.12 14.6467 5.03 14.6467 6.09C14.6467 7.15 15.0254 8.06 15.7826 8.82L24.5408 17.61H3.49728C2.46105 17.61 1.61911 17.985 0.971466 18.735C0.323822 19.485 0 20.39 0 21.45Z" fill="#09B681"/>
        </svg>
    </div>
    <p class="ps-4">협회 소식</p>
</div>                     -->
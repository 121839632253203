<template>
    <div class="w-full h-100 bg  flex-col justify-center hidden lg:flex">
        <VueMarqueeSlider
            :speed="40000"
            :autoWidth="true"
            :space="40"
            >
            <img src='@/assets/img/download/sec5-1Chart.png'  alt="" class="w-full h-60">
            <img src='@/assets/img/download/sec5-1Chart.png'  alt="" class="w-full h-60">
            <img src='@/assets/img/download/sec5-1Chart.png'  alt="" class="w-full h-60">
        </VueMarqueeSlider>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { VueMarqueeSlider } from 'vue3-marquee-slider';
import '../../../node_modules/vue3-marquee-slider/dist/style.css'
export default defineComponent({
    name:'SectionChart',
    components: {
        VueMarqueeSlider,
    },
});
</script>

<style scoped>
.bg{
    background-image: url('../../assets/img/download/sec5-1chartBG.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


</style>


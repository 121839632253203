<template>
    <div v-if="isLoading">
        <div class="w-full pt-40 justify-center items-start flex"><LoadingCircle/></div>
    </div>
    <div v-else class="md:w-[768px] min-h-screen w-full mt-0 text-def" >
        <div class="w-full h-120 ">
            <img :src="eventInfo.imgURL1" alt="" class="w-full h-full object-cover">
        </div>
        <div class="px-4 py-4">
            <div class="flex flex-col">
                <div class="text-[15px] mt-2 flex justify-between bg-red-0 relative">
                    <span>{{ eventInfo.title3 }} </span>
                    <div class="md:hidden flex">
                        <div class="w-6">
                            <img src="@/assets/img/logo/share.svg" alt="" class="w-full " @click="handleShareBtn" >
                        </div>
                    </div>
                </div>
                <div class="flex my-2">
                    <div class="px-2 mr-2 text-pada h-8 whitespace-nowrap bg-[#E0F9F1] font-bold rounded-[5px] text-sm flex justify-center items-center">{{ diffDay(eventInfo.title3 )}}</div> 
                    <span class="font-bold text-[20px]">{{ eventInfo.title1 }}</span>
                </div>
            </div>
            <div class="bg-[#F5F7F9] h-2 my-4"></div>

            <div>
                <p class="font-semibold">사전 예매 티켓</p>
                <div class="flex justify-between items-center">
                    <p class="my-2">{{ eventInfo.price }}</p>
                    <div class="w-auto h-8 bg-red-0 flex justify-between ">
                        <div class="px-2 flex items-center rounded-l-md ring-1 border-r-0 ring-zinc-200"><svg width="18" height="10" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.75 9H14.25" stroke="#676767" stroke-linecap="round" stroke-linejoin="round"/>
</svg></div>
                        <div class="px-4 flex items-center ring-1 ring-zinc-200 text-def">{{ cnt }}</div>
                        <div class="px-2 flex items-center rounded-r-md ring-1 ring-zinc-200 box-border"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.75 9H14.25M9 3.75V14.25" stroke="#676767" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>


                    </div>
                </div>
            </div>

        <div class="border-[#F5F5F5] border-t border-solid h-1 my-4"></div>
        <div class="font-light">
            <p class="font-semibold">예매자 정보</p>
            <div class="flex flex-col">
                <p class="font-medium">이름</p>
                <input type="text" class="bg-[#F5F7F9] w-80 rounded-md h-9 text-sm pl-4 text-[#D8DADF]" placeholder="실명으로 입력해주세요">
            </div>
            <div class="flex flex-col">
                <p class="font-medium">연락처</p>
                <input type="text" class="bg-[#F5F7F9] w-80 rounded-md h-9 text-sm pl-4 text-[#D8DADF]" placeholder="전화번호를 입력해주세요">
            </div>
        </div>


        </div>
        <div class="border-[#F5F5F5] border-t border-solid h-1 my-4"></div>


        
    </div>
    <div class="w-full h-10"></div>
</template>

<script  setup>
// <script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';
import { diffDay } from '@/utils/timeForToday';
import { ticketPrice } from '@/utils/formattedUnit';
import LoadingCircle from '../UI/LoadingCircle.vue';
import { useRoute } from 'vue-router';
import { useMeta } from 'vue-meta';
import KakaoShare from '../UI/KakaoShare.vue';
import ShareWays from '../UI/ShareWays.vue';


const kakaoApi = process.env.VUE_APP_KAKAO_API

// declare global {
//     interface Window {
//       kakao : any;
//     }
// }
const store = useStore();
const route = useRoute();
const isLoading = computed(() => store.state.isLoading);
const eventInfo = computed(()=> store.state.clickedEvent);
const selectedBookLink = ref('link1');
const cnt = ref(1);
const lati = ref(0);
const long = ref(0);
const park = ref('');
const parkDesc = ref('');
const shareBtn =ref(false);
const handleShareBtn = () => {
    shareBtn.value = !shareBtn.value
};
// const eventInfo = ref({
//     link1 : '',
//     date1 : `5월 18일 토요일~26일 일요일 
// 10:30~20:30 (금,토 10:00~21:00)`,
//     imgURL1 : 'https://pada.s3.ap-northeast-2.amazonaws.com/paper/images/junbro.jpg',
//     title1 : '정브르 팝업스토어',
//     title2 : '서울 용산구 아이파크몰 용산점 리빙파크 3F 이벤트홀 A',
//     title3 : '2024.05.18(토)~26(일)',
//     parking : '주차 가능 / 10분당 1,300원, 무료 (회차) 없음, 최대 5시간 할인',
//     latitude : '23.124215',
//     longitude : '124.12412',
//     price : '3000',
//     event : `hihi
//     너무좋아요 ㅎㅎ`,
//     introduce : `hhh`
// })

</script>

<style scoped>
.boxShadow{
box-shadow: 0px 0px 8px rgba(8, 50, 25, 0.13);
border-radius: 5px;
}
#map{
    width: 100%;
    height: 380px;
    
}
@media (max-width: 640px){
    #map{
        width: 100%;
        height: 180px;
    }
}
/* 라디오 버튼 숨기기 */
.radio-input {
    visibility: hidden;
    position: absolute;
}

.radio-input + span {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    display: inline-block;
    /* 커스텀 라디오 버튼 스타일 */
}

.radio-input + span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    width: 1rem;
    height: 1rem;
    border: 1px solid #00D191;
    border-radius: 50%;
    background-color: #fff;
}

.radio-input:checked + span:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 8px;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: #00D191;
}
</style>

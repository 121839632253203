<template >
    <div class="max-w-[1024px] mx-auto px-2 mt-5 mb-10 lg:px-0 h-auto flex  flex-col items-center">

        <BoardTitle
            title="분양글 >"
            :iconSrc="require('@/assets/img/logo/up.svg')"
            subtitle="판매 새 글"
        />

        <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4  gap-x-3 lg:gap-x-5 xl:gap-x-7 gap-y-3     ">
            <div v-for="item in adoptedData" :key="item.id" class="box-border overflow-hidden flex  cursor-pointer pe-0 pb-2 lg:pb-3 flex-col items-start "
            @click="postDetail(item)">
                <div class="w-full h-40 md:h-60">
                    <img :src=" item.imgURL1 " loading="lazy" decoding="async" alt="" class="w-full h-full object-cover object-center rounded-md ">
                </div>
                <div class=" flex-col flex pt-2">
                    <div class="font-medium text-sm text-stone-500">{{ item.name }}</div>
                    <div class="text-base mt-2 line-clamp-2 whitespace-pre-line b w-56">{{ item.title }}</div>
                    <div class="text-lg font-bold mt-2 tracking-wide">{{ formattedPrice(item.price) }}원</div>
                </div>
            </div>
        </div>

        <button class="w-80 h-14 bg-[#333] rounded-full flex justify-center items-center text-white text-sm mt-20" @click="goToReptile">
            <p>다른 친구들도 구경할까요?</p>
        </button>

    </div>
    
</template>
<script lang="ts" setup>
import { computed, onMounted, ref} from 'vue';
import BoardTitle from './BoardTitle.vue';
import { SearchApi } from '../../api/api'
import { formattedPrice } from '@/utils/formattedUnit';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { initFilter } from '@/utils/findCategory';
import { encodeBase62 } from '@/utils/idConverter';

const router = useRouter();
const store = useStore();
const clickedItem = ref<Item | null>(null); 
interface Item {
    id: number;
    title: string;
    imgURL1: string;
    name: string;
    price : number;
}
const adoptedData = computed(()=> store.getters.getReptileMainList)
onMounted( async () => {
    if(adoptedData.value.length === 0 ){
        await store.dispatch('getReptileList')
    }
})

// 상세보기 
const postDetail = (item :Item) => {
    clickedItem.value = item;
    router.push({ path: `reptile/${encodeBase62(item.id)}` });
};

const goToReptile = () =>{
    store.commit('SET_CURRENT_STATUS', 0)
    store.commit('SET_CURRENT_LIST', store.state.reptileList)
    initFilter(store);
    router.push('/reptile');
}




</script>
<style >
</style>

<template>
    <div>
      <TopNavigator/>

        <NaviHeight/>
        <div class="w-full h-10 ">
        <div class="w-full  h-full flex justify-start items-center mx-auto bg-green-0 overscroll-x-auto overflow-y-hidden md:hidden ">
          <ColumnCategory/>
        </div>
        
      </div>
      <div class="w-full h-2 bg-zinc-100 mt-2 blcok md:hidden"></div>
        <main class="relative flex-grow flex w-full">
            <div class="flex pt-0  mx-auto max-w-[1280px] sm:px-4 md:px-8 2xl:px-16 w-full">
                <div class="flex-shrink-0 hidden pe-24 lg:block w-96   ">
                <ColumnCategory/>

                </div>
                <!-- <div class="flex flex-col  bg-blue-50"> -->
                <div class="flex flex-col  md:w-[768px] mx-auto w-full ">
                    <router-view ></router-view>

                </div>

             </div>
        </main>
        <Section9Footer/>
    </div>
</template>
  
<script lang="ts">
// import BoardList from '@/components/Board/BoardList.vue';
import NaviHeight from '@/components/Navigator/NaviHeight.vue';
import ColumnCategory from '@/components/Column/ColumnCategory.vue';
import TopNavigator from '@/components/Navigator/TopNavigator.vue'
import Section9Footer from '@/components/Landing/Section9Footer.vue'
// import SearchList from '@/components/Serach/reptileList.vue';
import TopBanner from '@/components/UI/TopBanner.vue'

export default {
  components: {
      // BoardList,
      // SearchList,
      TopNavigator,
      ColumnCategory,
      NaviHeight,
      Section9Footer,
      // TopBanner,
  }
}
</script>

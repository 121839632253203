<template>
    <div class="flex flex-col w-full px-0 sm:px-0 ">

        <div class="mt-4"></div>

        <div class=" md:w-[768px] w-full flex flex-col border-t border-zinc-200 border-solid">
            <ul v-for="post in noticeList" :key="post.id" class="">
                <li @click="handlePost(post)" class="cursor-pointer  flex h-20 pt-5 border-b border-zinc-200 border-solid">
                    <div class="w-20 bg-red-00 flex justify-center items-start mr-2"> 
                        <div class="bg-zinc-50 ring-1 ring-zinc-200 rounded-full px-2 py-1 justify-center flex items-center text-xs">{{ post.title.slice(0,2) }}</div>
                    </div>
                    <div class="flex-col flex">
                        <p class="text-sm text-def">{{ post.title.slice(4) }}</p>
                        <p class="text-xs font-medium text-sub">{{ (post.created_at).slice(0, 10) }}</p>

                    </div>
                </li>
            </ul>

        </div>
        <div class="w-full h-40"></div>

    </div>
</template>

<script lang="ts" setup>
import { formatDate } from '@/utils/formattedUnit';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
const router = useRouter();
const store = useStore();
const route = useRoute();

const handlePost = (post) => {
    router.push({ path: `/mobile/notice/${post.id}` });
}
const noticeList = [
    {
        id : 1,
        title : '공지 / 파충류 다 있다 이용 수칙 안내',
        link : 'https:/naver.com',
        created_at : '2023-10-06T09:05:04.000Z'
    },
    {
        id : 2,
        title : '공지 / 커뮤니티 신고 처리 방침',
        link : 'https:/naver.com',
        created_at : '2023-10-06T09:05:04.000Z'
    },
]

</script>

<style>

</style>
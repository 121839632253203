export function timeForToday(dateStr : string) {
    const today = new Date();
    const timeValue = new Date(dateStr);

    const betweenTime = Math.floor((today.getTime() - timeValue.getTime()) / 1000 / 60);
    if(betweenTime < 1) return '방금 전';

    if(betweenTime < 60) {
        return `${betweenTime}분 전`;
    } 
    
    const betweenTimeHour = Math.floor(betweenTime / 60);
    if(betweenTimeHour < 24){
        return `${betweenTimeHour}시간 전`;
    }

    const betweenTimeDay = Math.floor(betweenTime / 60 / 24 );
    if(betweenTimeDay < 30) {
        return `${betweenTimeDay}일 전`;
    }

    const betweenTimeMonth = Math.floor(betweenTime / 60 / 24 / 30);
    if(betweenTimeMonth < 12){
        return `${betweenTimeMonth}달 전`;
    }

    return `${Math.floor(betweenTimeDay / 365)}년 전`;
}
export function diffDay(targetDay: string): string {
    const startDayStr = targetDay.slice(0, 10).replace(/\./g, '-'); // YYYY-MM-DD format으로 변경
    const startDay = new Date(startDayStr);
    startDay.setHours(0, 0, 0, 0); // 시작 날짜의 시작으로 설정
    let endDay = new Date(startDay.getTime()); // endDay를 복사하여 생성

    // 종료 날짜가 있다면 처리
    if (targetDay.includes('~')) {
        const [startPart, endPart] = targetDay.split('~'); // '~' 기준으로 종료일 분리
        const yearMonth = startPart.slice(0, 7).replace(/\./g, '-'); // 시작 날짜에서 연도와 월 정보 추출
        const endDayPart = endPart.trim().slice(0, 2); // " 03(일)" -> "03"
        const endDayStr = `${yearMonth}-${endDayPart}`; // "2024-03-03"
        
        endDay = new Date(endDayStr);
        endDay.setHours(23, 59, 59, 999); // 종료 날짜의 마지막 순간으로 설정
    } else {
        endDay.setHours(23, 59, 59, 999); // 종료 날짜가 명시적으로 주어지지 않았다면, 시작 날짜의 마지막 순간으로 설정
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0); // 오늘 날짜의 시작으로 설정하여 비교 정확도 향상
    const diff = startDay.getTime() - today.getTime(); // 밀리초 단위로 차이 계산
    const days = Math.ceil(diff / (1000 * 60 * 60 * 24));  // 밀리초를 일로 변환

    if (today < startDay) {
        return `D-${days}`;
    } else if (today >= startDay && today <= endDay) { // 여기를 수정했습니다.
        return '진행중';
    } else {
        return '종료';
    }
}

